import { Selection, select } from "d3";
import { EEventId, IEvent } from "../../data/models/Entities";
import { _LoadConfig, _mapConfiguration } from "../../data/services/Configuration";
import { _APP_DATA } from "../../data/AppData";
import { _GetKinderEventsArray } from "../../data/services/Kinder";

var containerList: Selection<HTMLDivElement, any, any, any>;
var eventSelected: EEventId;
var OnSelectedItem: (datum: IEvent) => boolean;

export function CreateListOptionEvents(container: Selection<HTMLDivElement, any, any, any>, _evSelected?: EEventId, _OnSelectedItem?: (datum: IEvent) => boolean) {
    container.classed("list-options-events", true);
    containerList = container;
    eventSelected = _evSelected;
    OnSelectedItem = _OnSelectedItem;

    UpdateListOptionsEvent();

    _LoadConfig(_APP_DATA.userData.IdNivel, (_, result) => {
        if (result.length > 0) UpdateListOptionsEvent();
    });
}

export function ShowHideListOptionsEvents(show: boolean) {
    containerList?.style("display", show ? "flex" : "none");
    _LoadConfig(_APP_DATA.userData.IdNivel, (_, result) => {
        if (!!containerList && result.length > 0) UpdateListOptionsEvent();
    });
}

export function _SetOnClickOptionEvent(_OnSelectedItem?: (datum: IEvent) => boolean) {
    OnSelectedItem = _OnSelectedItem;
}

async function UpdateListOptionsEvent() {
    const listEvtOptios = await _GetKinderEventsArray();
    const config = Array.from(_mapConfiguration.values());

    listEvtOptios.forEach(item => {
        const fndIdx = config.findIndex(o => o.Tipo == item.id);
        item.enabled = fndIdx > -1;
    });

    const optionEvent = containerList.selectAll<HTMLDivElement, any>(".opts-events").data(listEvtOptios.filter(o => o.enabled));

    optionEvent.exit().remove();
    optionEvent.enter().append("div")
        .classed("opts-events", true)
        .each((_, i, divs) => {
            const elemnt = select(divs[i]);
            elemnt.classed("item-n-" + i, true)
            elemnt.append("div").classed("opt-event", true);
        })
        .merge(optionEvent)
        .on('click', function (_, datum) {
            if (!OnSelectedItem) return;

            const successSelected = OnSelectedItem(datum);
            if (successSelected) {
                containerList.selectAll(".opts-events.selected").classed("selected", false);
                this.classList.add("selected");
            }
        })
        .each((datum, i, divs) => {
            const elemnt = select(divs[i]);
            elemnt.classed("event-icon-" + datum.id, true)
            if (eventSelected && datum.id == eventSelected)
                elemnt.classed("selected", true);

            elemnt.select(".opt-event")
                .attr("class", "img-event-icon")
                .html(datum.iconSVGRaw);
        });
}