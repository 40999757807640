import { ICON_STATUS_MESSAGE_CHAT } from "../../data/services/Chats";
import { FormatDateToHour, MOUNTH_NAMES, _IsSameDay } from "../../data/utils/General";
import _L from "../../utils/Labels";
import { _AutoLink, _CreateElementFromHTML } from "../utils/General";
import { iMessageChat } from "./Chat";

export interface IHTMLImageElementMessage extends HTMLImageElement {
    __src: string;
}

function PrepareContainer(container: TSelection<"div">, classRequired: string) {
    for (const content of container.node().children) {
        if (!content.classList.contains(classRequired)) {
            console.info("elemento requiere ", classRequired, " removed ", content.className)
            content.remove();
        }
    }
}

/**
 * @param container
 * @param classRequired
 * @param location classBeforeElement | "afterbegin" | "beforeend"
 * @returns
 */
function PrepareContentWrapper(container: TSelection<"div">, classRequired: string, location: string[] | "afterbegin" | "beforeend") {
    let content = container.select<HTMLDivElement>(":scope>." + classRequired);
    if (!content.node()) {
        content = container.append("div")
            .attr("class", classRequired);
    }
    if (!location) {
        return content;
    }
    else if (typeof location == "string") {
        container.node().insertAdjacentElement(location, content.node());
        return content;
    }
    let foundElem = false;
    for (const clsBeforeOf of location) {
        const elemBeforeOf = container.select<HTMLDivElement>(":scope>." + clsBeforeOf);
        if (elemBeforeOf.node()) {
            foundElem = true;
            elemBeforeOf.node().insertAdjacentElement("beforebegin", content.node());
            break;
        }
    }
    if (!foundElem) {
        container.node().insertAdjacentElement("beforeend", content.node());
    }
    return content;
}

export function BuildHeaderDate(container: TSelection<"div">, datum: iMessageChat) {
    const className = "header-date-group";
    PrepareContainer(container, className);

    const contentWrapper = PrepareContentWrapper(container, className, null);
    const now = new Date();

    now.setHours(0, 0, 0, 0);
    let strDate;

    if (_IsSameDay(datum.Fecha, now)) strDate = _L("general.today");
    else {
        strDate = MOUNTH_NAMES[datum.Fecha.getMonth()] + " " + datum.Fecha.getDate()
        if (now.getFullYear() != datum.Fecha.getFullYear()) strDate += ", " + datum.Fecha.getFullYear();
    }
    return contentWrapper.text(strDate);
}

export function BuildMessageWrapper(container: TSelection<"div">) {
    const className = "message-bubble-container";
    PrepareContainer(container, className);

    return PrepareContentWrapper(container, className, null);
}

export function BuildMessageIncoming(container: TSelection<"div">, nombreEmisor: string) {
    const className = "name-emisor-msg";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);
    if (!nombreEmisor?.trim()) {
        if (wrapperElem.node())
            wrapperElem.remove();
        return null;
    }
    wrapperElem = PrepareContentWrapper(container, className, "afterbegin")
        .text(nombreEmisor);
    return wrapperElem;
}

export function BuildMessageImage(container: TSelection<"div">, hasImage: boolean, src: string) {
    const className = "item-img-container";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);
    if (!hasImage || !src?.trim()) {
        if (wrapperElem.node()) {
            wrapperElem.remove();
        }
        return null;
    }
    wrapperElem = PrepareContentWrapper(container, className, ["message-text-container", "time-and-status"]);
    if (!wrapperElem.select(".image-message").node()) {
        wrapperElem.append("div").attr("class", "image-label").text(_L("general.loading..."));
        wrapperElem.append("img").attr("class", "image-message")
    }
    wrapperElem.select<IHTMLImageElementMessage>(".image-message")
        .node().__src = src;
    return wrapperElem;
}

export function BuildMessageVideo(container: TSelection<"div">, hasVideo: boolean, src: string) {
    const className = "item-video-container";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);
    if (!hasVideo || !src?.trim()) {
        if (wrapperElem.node()) {
            wrapperElem.remove();
        }
        return null;
    }
    wrapperElem = PrepareContentWrapper(container, className, ["message-text-container", "time-and-status"]);
    if (!wrapperElem.select(".video-message").node()) {
        // wrapperElem.append("div").attr("class", "image-label").text(_L("general.loading..."));
        wrapperElem
            .append("div").attr("class", "video-message")
            .append("div");
    }
    // const video = wrapperElem.select<HTMLVideoElement>(".video-message").node();
    // // .call((imgSel) => {
    // //     setTimeout(() => {
    // //         const img = imgSel.node();
    // //         const initHeight = img.offsetHeight;
    // //         img.onload = () => this.OnLoadedImageItemChat(chatRow, initHeight, img.offsetHeight);
    // //     });
    // // })

    // video.src = src;

    return wrapperElem;
}
export function BuildMessageFile(container: TSelection<"div">, hasFile: boolean, simpleExtension: string, src: string) {
    const className = "item-file-container";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);
    if (!hasFile) {
        if (wrapperElem.node()) {
            wrapperElem.remove();
        }
        return null;
    }
    wrapperElem = PrepareContentWrapper(container, className, ["message-text-container", "time-and-status"]);
    if (!wrapperElem.select(".image-message").node()) {
        wrapperElem.append("div").attr("class", "image-message")
            .call((icon_file) => {
                icon_file.append(() => _CreateElementFromHTML(src))
                    .style("height", "100%")
                    .style("width", "100%");
            })
        // wrapperElem.append("div").attr("class", "image-label").text(_L("general.loading..."));
        wrapperElem.append("h4").attr("class", "lbl-type-file");
    }

    wrapperElem.select(".lbl-type-file")
        .text(simpleExtension.toUpperCase());
    wrapperElem.select<IHTMLImageElementMessage>(".image-message")
        .node().__src = src
    // .call((imgSel) => {
    //     setTimeout(() => {
    //         const img = imgSel.node();
    //         const initHeight = img.offsetHeight;
    //         img.onload = () => this.OnLoadedImageItemChat(chatRow, initHeight, img.offsetHeight);
    //     });
    // })

    return wrapperElem;
}

export function BuildMessageText(container: TSelection<"div">, message: string) {
    const className = "message-text-container";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);
    if (!message) {
        if (wrapperElem.node())
            wrapperElem.remove();
        return;
    }
    wrapperElem = PrepareContentWrapper(container, className, ["time-and-status"]);
    wrapperElem.text("").append(() => _AutoLink(message));
}

export function BuildMessageHour(container: TSelection<"div">, datum: iMessageChat, isMine: boolean) {
    const className = "time-and-status";
    let wrapperElem = container.select<HTMLDivElement>(":scope > ." + className);

    wrapperElem = PrepareContentWrapper(container, className, "beforeend");

    if (!wrapperElem.select("*").node()) {
        wrapperElem.append("p").classed("time-message", true)
        wrapperElem.append("div").classed("icon-status", true)
    }
    const time = FormatDateToHour(datum.Fecha);
    wrapperElem.select(".time-message").text(time);

    const icStatus = wrapperElem.select(".icon-status")
        .style("display", isMine ? "flex" : "none")
        .style("align-items", "center")
    // .attr("src", null);

    const infoIconStatus = ICON_STATUS_MESSAGE_CHAT.get(datum.StatusMessage);

    if (icStatus.select(".status-mssg").node()) icStatus.select(".status-mssg").remove()
    icStatus.append(() => _CreateElementFromHTML(infoIconStatus.icon))
        .classed("status-mssg", true)
        .style("height", "100%")
        .style("width", "100%");

}