import "../../../styles/views/Chat.scss";
import ic_take_pic from '/icons/ic_camera_outline.svg?raw'
import ic_add_file from '/icons/ic_attach2.svg?raw'
import ic_send from '/icons/chat/ic_send_chat.svg?raw'
import { Selection, select, timeFormat } from "d3";
import _L from "../../utils/Labels";
import { _AddSvgLoading, _ID_CHAT_GROUP, _IsFileImage, _IsSameDay } from "../../data/utils/General";
import { FILE_TYPE_FILES_CHAT, IFileTypeChat, _AddMessage, _GetChatInDB, _LoadPrevMessage, _MarkAsReadMessage, _ObserveChatChild, _SaveChatsData, _URLFile, _URLImagePreview, _URLImageThumb, _mapChildsMessageUnreaded } from "../../data/services/Chats";
import { EFileType, EStatusMessage, IChild, IInfoTeacher, IMessage, IParent } from "../../data/models/Entities";
import { _LoadTutorData, _mapChilds, _mapParents } from "../../data/services/Kids";
import { _APP_DATA } from "../../data/AppData";
import { _LoadFileManager } from "../components/FileManager";
import { IInfoFile, ImageViewer } from "../components/ImageViewer";
import { LOADING } from "../components/Loading";
import { ViewSendFile } from "../components/SendFile";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { ShowToast } from "../components/Toast";
import { _CreateElementFromHTML, _IntersectionObserver } from "../utils/General";
import { _AutoLink } from "../utils/General";
import { _SaveImage } from "../../utils/Device";
import { _AddSwipeEvent, _RemoveSwipeEvent } from "../utils/Swipe";
import * as ChatComponent from "./ChatComponents";
import { _mapTeachersInfo } from "../../data/services/Group";

export interface iMessageChat extends IMessage {
    isHeaderDate: boolean;
    maestroInfo: IInfoTeacher;
    maestroName: string;
    tutorInfo: IParent;
    tutorName: string;
    fileType: IFileTypeChat;
    imgB64: File | null;
}

export class Chat {
    private parentContainer: Selection<HTMLDivElement, any, any, any>;
    private loadingContainer?: Selection<HTMLDivElement, any, any, any>;
    private chatContainer?: Selection<HTMLDivElement, any, any, any>;
    private inputMsg?: Selection<HTMLInputElement, any, any, any>;

    private childInfo: IChild;
    private listMessages: Array<IMessage> = [];
    private intervalMessages: NodeJS.Timeout;

    private _mapTempMsg: Map<number, IMessage> = new Map();//Guardar mensajes enviados
    private idTeacherChat: number = 0;
    private _idTempMax: number = 0;// ID temporal para envio de mensajes & orden x fecha
    private _ID_TEMP_FILE: number = -710;// ID temporal para la subida de archivo.

    private nTimeScroll = 0;
    private loadingData: boolean = false;
    private hasScroll: boolean = false;
    private isScrollInBottom: boolean = true;

    /**
     * Agrega/remueve la clase "intersecting" a cada elemento de mensaje y lanza comportamientos.
     *
     * Si es `null`, ajustar los comportamientos
     */
    private intersectionObserver: IntersectionObserver | null;

    private callbackUpdateUnread: () => void;

    constructor(container: TSelection<"div">, child: IChild) {
        this.parentContainer = container.classed("ui-chat", true);
        this.loadingContainer = this.parentContainer.append("div").classed("div-progress", true);
        this.childInfo = child;
        this.idTeacherChat = -_APP_DATA.userData.IdMaestra;

        this.CreateChatContainer();
        this.ActiveIntersectionObserver();

        this.UpdateChatChild();
        if (this.childInfo.IdChild !== _ID_CHAT_GROUP)
            this.intervalMessages = setInterval(() => this.LoadData(), 10000);//Monitorear mensajes
    }

    private CreateChatContainer() {
        this.chatContainer = this.parentContainer.append("div").classed("list-message-container", true);

        const divInputContainer = this.parentContainer?.append("div").classed("input-container", true);

        const boxInput = divInputContainer?.append("div").classed("box-input", true);
        this.inputMsg = boxInput?.append("input").classed("input-send-chat", true)
            .attr("keyboard_limit", 5)
            .attr("placeholder", _L("chat.escribe"))
            .on('keyup', (e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    let msg = this.inputMsg?.node()?.value.trim();
                    if (!msg) return;

                    this.SendMessage(msg);
                }
            });

        boxInput.append(() => _CreateElementFromHTML(ic_add_file))
            .attr("class", "btn-input-chat ripple")
            .on("click", () => this.OnArchiveCameraClick(false));

        boxInput.append(() => _CreateElementFromHTML(ic_take_pic))
            .attr("class", "btn-input-chat ripple")
            .on("click", () => this.OnArchiveCameraClick(true));

        divInputContainer.append(() => _CreateElementFromHTML(ic_send))
            .attr("class", "btn-input-chat ripple")
            .on("click", () => {
                let msg = this.inputMsg?.node()?.value.trim();
                if (!msg) {
                    this.inputMsg?.node().focus();
                    return;
                }

                this.SendMessage(msg);
            });

        this.OnDragNotScroll(true);
    }

    private OnArchiveCameraClick(isCamera: boolean) {
        _LoadFileManager({
            capture: isCamera,
            accept: isCamera
                ? "image/*"
                : "image/*,audio/*,video/mp4,video/avi,application/*",
            onChange: (_) => {
                LOADING.Show();
            },
            onLoadFile: (b64, file) => {
                if (_IsFileImage(file.extension)) {
                    new ImageViewer()
                        .SetImage(b64)
                        // .SetImageFile(file, 50)
                        .Extension(file.extension)
                        .AddEditOptions()
                        .AddTextBox()
                        .OnSuccessCompletion((info) => {
                            this.SendFile(info);
                        });
                } else {
                    new ViewSendFile()
                        .SetFile(file)
                        .SetOnConfirm((file) => {
                            this.SendMessage(file.description, file, file.extension);
                        });
                }
            }, onFinishLoadFiles: () => {
                LOADING.Dismiss();
            }
        });
    }

    private SendFile(info: IInfoFile) {
        fetch(info.b64)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], info.name);

                // this.footerElements.inputMessage.fun_focus_out();
                this.SendMessage(info.description, file, info.extension);
            });
    }

    private UpdateChatChild(child?: IChild) {
        if (child) this.childInfo = child;

        this.isScrollInBottom = true;
        _GetChatInDB(this.childInfo.IdChild).then(result => {
            this.listMessages = result;
            this.SetMessages();
            this.LoadData();
        });

        _LoadTutorData((_) => {
            this.SetMessages();
        });
    }

    private LoadData() {
        if (this.childInfo.IdChild === _ID_CHAT_GROUP) return;

        _ObserveChatChild(this.childInfo.IdChild, (result, hasUnreadMessage) => {//Consultar mensajes de servicio
            if (this.childInfo.unreadMessages && this.childInfo.unreadMessages > 0 || hasUnreadMessage)//Marcar mensajes como leidos
                this.MarkMessage();

            if (result.length == 0) return;

            result.forEach(item => {
                if (this._mapTempMsg.has(item.IdMensaje)) {//Eliminar los mensajes que se han enviado.
                    this._mapTempMsg.delete(item.IdMensaje);
                }

                for (let i = this.listMessages.length; i--; i == 0) {
                    if (this.listMessages[i].IdMensaje == item.IdMensaje) {
                        this.listMessages.splice(i, 1);
                        break;
                    }
                }
            })

            this.listMessages = this.listMessages.concat(result);
            this.SetMessages();
        });
    }

    private MarkMessage() {//Marcar mensajes como leidos
        _MarkAsReadMessage(this.childInfo.IdChild, (result: number) => {
            if (result > 0) {
                const objChild = _mapChilds.get(this.childInfo.IdChild);
                if (objChild)
                    objChild.unreadMessages = 0;

                _mapChildsMessageUnreaded.get(_APP_DATA.userData.IdGrupo)?.delete(this.childInfo.IdChild);
                if (this.callbackUpdateUnread) this.callbackUpdateUnread();
            }
        });
    }

    private SetMessages() {
        var msgList: iMessageChat[] = [];
        var headerDate: Date | null = null;

        this.listMessages.forEach((item, _) => {
            let auxDate = new Date(item.Fecha);
            auxDate.setHours(0, 0, 0, 0);
            if (!headerDate || !_IsSameDay(headerDate, auxDate)) {
                let objItem = <iMessageChat>{ isHeaderDate: true, ChildId: item.ChildId, Fecha: auxDate, IdGrupo: item.IdGrupo, IdMensaje: this._idTempMax-- }; // IdMensaje: -item.IdMensaje 
                msgList.push(objItem);

                headerDate = auxDate;
            }

            let objMsg = <iMessageChat>Object.assign({}, item);
            objMsg.isHeaderDate = false;
            const isMine = this.idTeacherChat == objMsg.IdOrigen;
            const isFromOtherTeacher = !isMine && objMsg.IdOrigen < 0;

            if (isFromOtherTeacher) {
                objMsg.maestroInfo = _mapTeachersInfo.get(objMsg.IdOrigen * -1);
                if (objMsg.maestroInfo) objMsg.maestroName = objMsg.maestroInfo.Nombre + " " + objMsg.maestroInfo.ApPaterno + " " + objMsg.maestroInfo.ApMaterno;
            }

            if (!isMine && !isFromOtherTeacher) {
                objMsg.tutorInfo = _mapParents.get(objMsg.IdOrigen) as any;
                if (objMsg.tutorInfo) objMsg.tutorName = objMsg.tutorInfo.Nombre + " " + objMsg.tutorInfo.ApPaterno + " " + objMsg.tutorInfo.ApMaterno
            }

            //Add fileType icon
            if (objMsg.IdArchivo > 0 || objMsg.IdArchivo == this._ID_TEMP_FILE) {
                let objFiletype = FILE_TYPE_FILES_CHAT.get(objMsg.Extension);
                if (!objFiletype) objFiletype = FILE_TYPE_FILES_CHAT.get("unknown");

                if (objFiletype)
                    objMsg.fileType = objFiletype;
            }

            msgList.push(objMsg);
        });

        this.UpdateListMessage(msgList)


        // if (this.isChatInBottom && msgList.length > 0)
        //     setTimeout(() => this.listviewMessage.fun_ir_a(msgList[msgList.length - 1].IdMensaje), 50);
    }

    private UpdateListMessage(listData: iMessageChat[]) {
        const listMessage = this.chatContainer?.selectAll<HTMLDivElement, iMessageChat>(".message-item").data(listData);
        const scrlTop = this.chatContainer?.node()?.scrollTop || 0;
        const lastZseScroll = this.chatContainer?.node()?.scrollHeight || 0;

        // const self = this;
        listMessage?.exit().remove();
        listMessage?.enter().append("div")
            .classed("message-item", true)
            // .each((_, i, divs) => {
            //     const elemnt = select(divs[i]);
            // })
            .merge(listMessage)
            // .on('click', function (_) { self.OnChatSelected(datum); })
            .each((datum, i, divs) => {
                const elementNode = divs[i];
                const elemnt = select(elementNode)
                    .classed("header-date", datum.isHeaderDate);

                const EvalIntersection = () => {
                    if (this.intersectionObserver) {
                        this.intersectionObserver.unobserve(elementNode);
                        this.intersectionObserver.observe(elementNode);
                    }
                    else {
                        this.EvalItemIntersection(elementNode);
                    }
                }

                if (datum.isHeaderDate) {
                    ChatComponent.BuildHeaderDate(elemnt, datum);
                    EvalIntersection();
                    return;
                }
                const itemBbleMsg = ChatComponent.BuildMessageWrapper(elemnt);
                const isMine = this.idTeacherChat === datum.IdOrigen;
                const isFromOtherTeacher = !isMine && datum.IdOrigen < 0;

                itemBbleMsg.classed("outgoing", isMine)
                itemBbleMsg.classed("incoming", !isMine)

                let nombreEmisor: string = null;
                if (!isMine) {
                    if (isFromOtherTeacher) {
                        nombreEmisor = datum.maestroName;
                    } else {
                        nombreEmisor = datum.tutorName;
                    }
                };

                ChatComponent.BuildMessageIncoming(itemBbleMsg, isMine
                    ? null
                    : (nombreEmisor ? nombreEmisor : _L("general.nofound")));

                const hasFile: boolean = datum.IdArchivo > 0 || datum.IdArchivo == this._ID_TEMP_FILE;
                const isImageFile: boolean = hasFile ? (datum.fileType.fileType == EFileType.IMAGE) : false;
                const imageSrc: string = (hasFile ? (datum.IdArchivo == this._ID_TEMP_FILE) : null)
                    ? (datum.imgB64 ? URL.createObjectURL(datum.imgB64) : null)
                    : _URLImageThumb(datum.IdArchivo);

                const simpleExtension = datum.Extension.replace(".", "").toLowerCase().trim();
                const acceptVideo = [/* "mp4" */]; // FIXME IMPLAMENTAR
                const isVideoFile: boolean = (hasFile && !isImageFile) ? (acceptVideo.includes(simpleExtension)) : false;
                const isUnknownFile = hasFile && !isImageFile && !isVideoFile;

                ChatComponent.BuildMessageImage(itemBbleMsg, isImageFile, imageSrc)
                    ?.on("click", () => this.OnClickFileChat(datum))
                    ?.select<HTMLImageElement>("img").call((imgSel) => {
                        setTimeout(() => {
                            const img = imgSel.node();
                            const initHeight = img.offsetHeight;
                            img.onload = () => this.OnLoadedImageItemChat(elemnt, initHeight, img.offsetHeight);
                        });
                    });
                ChatComponent.BuildMessageVideo(itemBbleMsg, isVideoFile, imageSrc)
                    ?.on("click", () => this.OnClickFileChat(datum));
                ChatComponent.BuildMessageFile(itemBbleMsg, isUnknownFile, simpleExtension, datum.fileType?.icon)
                    ?.on("click", () => this.OnClickFileChat(datum));

                ChatComponent.BuildMessageText(itemBbleMsg, datum.Mensaje);

                ChatComponent.BuildMessageHour(itemBbleMsg, datum, isMine);

                EvalIntersection();
            });

        //Prueba sroll to bottom
        if (this.isScrollInBottom) {
            this.ToScrollBottom();
        }
        else {
            const scrollRes = ((this.chatContainer?.node()?.scrollHeight || 0) - lastZseScroll) + scrlTop;
            this.chatContainer?.node()?.scrollTo(0, scrollRes);
        }

        this.chatContainer?.node()?.addEventListener("scroll", () => {
            const scrlPosBottom = (this.chatContainer?.node()?.scrollHeight || 0) - (this.chatContainer?.node()?.clientHeight || 0);
            const _scrTop = this.chatContainer?.node()?.scrollTop || 0;

            if (!this.hasScroll) {
                this.hasScroll = true;
                this.OnDragNotScroll(false);
            }

            if (_scrTop == 0) {
                this.isScrollInBottom = false;
                this.LoadPrevMessage();
            } else if (_scrTop > scrlPosBottom - 2 && _scrTop < scrlPosBottom + 2) {
                this.isScrollInBottom = true;
            }
        });
    }

    private ActiveIntersectionObserver() {
        const chatContainer = this.chatContainer?.node();
        if (!chatContainer) {
            return;
        }
        this.intersectionObserver = _IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                select(entry.target).classed("intersecting", entry.isIntersecting);
                this.EvalItemIntersection(entry.target as any);
            })
        }, {
            root: chatContainer,
            // rootMargin: "15px",
        })
    }

    private EvalItemIntersection(element: HTMLDivElement) {
        const timeout: number = !this.intersectionObserver ? 0 : 1000;

        setTimeout(() => {
            const targetSel = select<HTMLDivElement, iMessageChat>(element);

            if (this.intersectionObserver && !targetSel.classed("intersecting")) {
                return;
            }

            // const imgContainer = targetSel.select(".item-img-container");
            const imgMessage = targetSel.select<ChatComponent.IHTMLImageElementMessage>(".image-message").node();

            if (imgMessage?.__src) {
                imgMessage.src = imgMessage.__src;
                imgMessage.__src = null;
                // console.warn("image require")
            }
        }, timeout);
    }

    // private IsScrollInBottom() {
    //     const failMarginPercent = 5;
    //     const msgsWrapper = this.chatContainer?.node();
    //     if (!msgsWrapper) return false;
    //     const failMargin = msgsWrapper.clientHeight / 100 * failMarginPercent;
    //     // console.warn(msgsWrapper.clientHeight, failMargin);
    //     const scrollBottom = (msgsWrapper.clientHeight + msgsWrapper.scrollTop) + failMargin;
    //     const isBottom = scrollBottom >= msgsWrapper.scrollHeight;
    //     // console.warn(isBottom, msgsWrapper.scrollHeight, " (", msgsWrapper.clientHeight, "+", msgsWrapper.scrollTop, "=", scrollBottom, ")", scrollBottom >= msgsWrapper.scrollHeight)
    //     return isBottom
    // }

    private ToScrollBottom() {
        this.nTimeScroll++;
        setTimeout(() => {
            const { scrollHeight, scrollTop, clientHeight } = this.chatContainer?.node() || { scrollHeight: 0, scrollTop: 0, clientHeight: 0 };

            this.chatContainer?.node()?.scrollTo(0, scrollHeight);
            if (this.nTimeScroll < 4 && Math.abs(scrollHeight - clientHeight - scrollTop) > 1)
                this.ToScrollBottom();
        }, 200);
    }

    private OnLoadedImageItemChat(chatItem: TSelection<"div">, initHeight: number, endHeight: number) {
        const diffHeight = endHeight - initHeight;
        const chatContainer = this.chatContainer?.node();

        if (!chatContainer || diffHeight <= 0 || (this.intersectionObserver && !chatItem.classed("intersecting"))) {
            return;
        }

        const fixedScroll = chatContainer.scrollTop + diffHeight

        chatContainer.scroll(0, fixedScroll);
    }

    private OnDragNotScroll(addDragScroll: boolean) {
        const chatContainer = this.chatContainer.node();

        if (!chatContainer)
            return;

        if (!addDragScroll) {
            _RemoveSwipeEvent(chatContainer);
            return;
        }

        _AddSwipeEvent(chatContainer, "swipeDown", () => {
            if (this.hasScroll)
                return;
            this.LoadPrevMessage();
        })
    }

    private ShowHideLoading(show: boolean) {
        if (show)
            _AddSvgLoading(this.loadingContainer?.node());
        else
            this.loadingContainer?.selectAll("*").remove();
    }

    private OnClickFileChat(datum: iMessageChat) {
        if (datum.StatusMessage == EStatusMessage.ENVIADO || datum.StatusMessage == EStatusMessage.ERROR)
            return;

        if (_IsFileImage(datum.Extension)) {
            new ImageViewer()
                .SetImage(_URLImagePreview(datum.IdArchivo))
                .Extension(datum.Extension)
                .Description(datum.Mensaje)
                .DonwloadFile(_URLFile(datum.IdArchivo))
                .Name(("KIDI_IMG_" + (timeFormat("%Y_%m_%d_%H_%M_%S")(datum.Fecha))));
        } else {
            new ConfirmDialog()
                .SetDescription(_L("chat.confirm_download"))
                .SetOnConfirm(() => {
                    let fileName = "KIDI_File_" + (timeFormat("%Y_%m_%d_%H_%M_%S")(new Date()));
                    fileName += datum.Extension;
                    _SaveImage(_URLFile(datum.IdArchivo), fileName)
                });
        }
    }

    private LoadPrevMessage() {
        if (this.listMessages.length == 0 || this.loadingData || this.childInfo.IdChild === _ID_CHAT_GROUP) return;

        let idMsg = Math.abs(this.listMessages[0].IdMensaje);//el primer msg podria ser la fila FECHA => idMensaje negativo
        this.ShowHideLoading(true);

        this.loadingData = true;
        _LoadPrevMessage(this.childInfo.IdChild, idMsg, (result) => {
            this.ShowHideLoading(false);
            this.loadingData = false;
            if (result.length == 0) return;

            this.listMessages = result.concat(this.listMessages);
            this.SetMessages();
        });
    }

    private SendMessage(message: string, file?: File, ext?: string) {
        var objMessage: iMessageChat = {
            ChildId: this.childInfo.IdChild
            , Mensaje: message
            , Fecha: new Date()
            , FechaLeido: null
            , IdDestino: 0 // <--
            , IdOrigen: this.idTeacherChat
            , IdGrupo: _APP_DATA.userData.IdGrupo
            , IdMensaje: this._idTempMax-- // <--
            , Tipo: 1
            , IdArchivo: file ? this._ID_TEMP_FILE : 0 // <-- Mientras sube el archivo.
            , Extension: ext ? ext : ""
            , StatusMessage: EStatusMessage.ENVIADO
            , MaxUpdated: null
            , imgB64: file
        } as any;

        this._mapTempMsg.set(this._idTempMax, objMessage);
        this.listMessages.push(objMessage);
        this.inputMsg?.property("value", "");

        this.SetMessages();

        if (this.childInfo.Sexo !== _ID_CHAT_GROUP) {
            this.CreateAndSendMessage([this.childInfo.IdChild], message, objMessage, file);
        } else {// Mensaje de grupo
            let idResultMsg = 0;
            const tempChilds = Array.from(_mapChilds.values());
            this.CreateAndSendMessage(tempChilds.map(d => d.IdChild), message, objMessage, file, (result) => {
                idResultMsg = result;
                objMessage.IdMensaje = idResultMsg;
                _SaveChatsData([objMessage]);
                if (result < 1)
                    ShowToast(_L("chat.send_c"), _L("chat.fail_sendg_msg"), "error", 'BOTTOM-RIGHT');
            })
        }

    }

    private CreateAndSendMessage(idChildr: number[], message: string, objMessage: iMessageChat, file?: File, completion?: (status: number) => void) {
        _AddMessage(idChildr, message, file, (result, fileId) => {
            let tempId = objMessage.IdMensaje;
            if (result > 0) {
                objMessage.IdArchivo = fileId;
                objMessage.IdMensaje = result;
                objMessage.StatusMessage = EStatusMessage.ENTREGADO;
                objMessage.imgB64 = null;
                this._mapTempMsg.delete(tempId);

                let objTemp = this.listMessages.find(o => o.IdMensaje == tempId);
                if (objTemp) {
                    objTemp.IdArchivo = fileId;
                    objTemp.IdMensaje = result;
                    objTemp.StatusMessage = EStatusMessage.ENTREGADO;
                }
            } else {
                objMessage.StatusMessage = EStatusMessage.ERROR;

                let objTemp = this.listMessages.find(o => o.IdMensaje == tempId);
                if (objTemp)
                    objTemp.StatusMessage = EStatusMessage.ERROR;
            }

            this._mapTempMsg.set(objMessage.IdMensaje, objMessage);

            this.SetMessages();

            if (completion) completion(result);
        });
    }

    public OnUpdateUnreaded(completion: () => void) {
        this.callbackUpdateUnread = completion;
    }


    public Destroy() {
        this.parentContainer.selectAll("*").remove();
        clearInterval(this.intervalMessages);
        this.intervalMessages = null;
    }
}