import { BotSendError } from "../../utils/AlertBot";
import { _FmtElapsedTime, _GetElapsedTimeMinutes } from "../../utils/Time";

enum EMethodRequest {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
}
const AbortC = typeof AbortController !== "undefined" ? AbortController : class {
    signal: undefined = undefined
    abort() { }
};
export class EMAbortController extends AbortC {
    public identifier: number;
    public tag: string;

    static mapRequests: Map<string, Map<number, EMAbortController>> = new Map();//Guardar instancias Request para ser cancelador.

    constructor(_tag: string = 'NONE') {
        super()

        this.identifier = Date.now();
        this.tag = _tag;

        if (!EMAbortController.mapRequests.has(this.tag))
            EMAbortController.mapRequests.set(this.tag, new Map());
        EMAbortController.mapRequests.get(this.tag).set(this.identifier, this);
    }

    abort(reason?: unknown): void {
        super.abort(reason);
        EMAbortController.mapRequests.get(this.tag).delete(this.identifier);
    }

    public SuccessCompleted() {
        EMAbortController.mapRequests.get(this.tag).delete(this.identifier);
    }

    public static AbortByTag(_tag: string) {
        this.mapRequests.get(_tag).forEach(item => {
            item.abort();
        });
    }

}

export class RequestData<T> {

    private params: any;
    private url: string;
    private methodReq: EMethodRequest = EMethodRequest.GET;
    private _completion?: (resData: T | null, error: Error | null) => any;
    private hasContentType: boolean = false;
    private timeout: number = (1000 * 100);//timeout default de 100 seg
    private isAsync: boolean = true;//Para determinar si es asyncrono o no. Aplica solo con la función => BuildRequest();
    private identifier: string;
    private forceErrorBotMessage: boolean = false;
    private extraInfoInErrorBotMessage: string
    private tags: string[]

    public abortController: EMAbortController;

    constructor(url: string = "") {
        this.url = url;
    }

    public Params(params: any): RequestData<T> {
        this.params = params || {};
        return this;
    }

    public Url(url: string): RequestData<T> {
        this.url = url;
        return this;
    }

    public HasContentType(hasContentType: boolean): RequestData<T> {
        this.hasContentType = hasContentType;
        return this;
    }

    public Identifier(val: string): RequestData<T> {
        this.identifier = val;
        return this;
    }

    public Completion(completion: (resData: T | null, error: Error | null) => any): RequestData<T> {
        this._completion = completion;
        return this;
    }

    public Timeout(timeout: number): RequestData<T> {
        this.timeout = timeout;
        return this;
    }

    public IsAsync(isAsync: boolean): RequestData<T> {
        this.isAsync = isAsync;
        return this;
    }

    /* public ForceErrorBotMessage(force: boolean): this {
        this.forceErrorBotMessage = force
        return this;
    } */

    public ExtraInfoInErrorBotMessage(info: string): RequestData<T> {
        this.extraInfoInErrorBotMessage = info
        return this
    }

    public Get() { return this.BuildRequest(EMethodRequest.GET); }
    public Post() { return this.BuildRequest(EMethodRequest.POST); }
    public Put() { return this.BuildRequest(EMethodRequest.PUT); }
    public Delete() { return this.BuildRequest(EMethodRequest.DELETE); }

    public SetTags(...tags: string[]) {
        this.tags = tags
        return this
    }

    private async BuildRequest(methdReq: EMethodRequest) {
        this.methodReq = methdReq;

        if (this.isAsync) {
            this.Request();
            return null;
        } else {
            return await this.RequestSYNC();
        }
    }

    private Request(): void {
        if (!this.url) {
            console.error("No se configuro la URL del servicio");
            return;
        }

        let request = this.Header();
        this.abortController = new EMAbortController(this.identifier);

        let timer: NodeJS.Timeout | null = null;
        const dtInit = new Date()

        fetch(request, {
            signal: this.abortController.signal
        }).then((response: Response) => {

            if (timer)
                clearTimeout(timer);

            if (!response.ok) {
                if (this._completion) {
                    let error = { name: "consulta", message: "(" + response.status + ") " + response.statusText };
                    this.OnCompletion(null, error, dtInit);
                }
            } else {
                return response.json();
            }
        }).then(result => {
            if (this._completion) {
                this.OnCompletion(result, null, dtInit);
            }
        }).catch(e => {
            if (timer)
                clearTimeout(timer);

            if (this._completion)
                this.OnCompletion(null, e, dtInit);

            let stack: string = e.stack;
            //@ts-ignore
            if (typeof window !== 'undefined' && window.webkit) { // para dispositivos iOS imprimir siempre el error
                console.error({ stack: e.stack, message: e.message, name: e.name, requestURL: this.url });
            } else if (stack && !stack.includes("TypeError: Failed to fetch")) {
                console.error(e.stack);
            }
        });

        timer = setTimeout(() => {
            console.log("Abortando petición: ", this.url)
            this.abortController.abort();
        }, this.timeout);
    }

    private async RequestSYNC(): Promise<T | null> {
        let request = this.Header();

        this.abortController = new EMAbortController(this.identifier);
        let timer = setTimeout(() => {
            console.log("Abortando petición: ", this.url)
            this.abortController.abort();
        }, this.timeout);

        const dtInit = new Date()
        let response = await fetch(request, {
            signal: this.abortController.signal
        }).catch((e) => {
            console.warn("Error fetch: ", this.url, "\n", e);
            return null;
        });

        if (timer) clearTimeout(timer);

        if (!response || !response.ok) {
            console.warn("Resquest error. Response:", response);
            return null;
        }

        let resultData = await response.json();
        if (this._completion) {
            let result = this.OnCompletion(resultData, null, dtInit);
            resultData = result === undefined ? resultData : result;
        }


        return resultData;
    }

    public FORM(): void {
        var formData = new FormData();
        this.methodReq == EMethodRequest.POST;
        this.forceErrorBotMessage = true

        for (var p in this.params) {
            formData.append(p, this.params[p]);
        }

        this.abortController = new EMAbortController(this.identifier);
        let timer: NodeJS.Timeout | null = null;
        const dtInit = new Date()

        fetch(this.url, {
            method: 'POST',
            body: formData,
            signal: this.abortController.signal
        }).then((response: Response) => {
            if (timer)
                clearTimeout(timer);

            return response.json();
        }).then(result => {
            if (this._completion) this.OnCompletion(result, null, dtInit);
        }).catch(error => {
            if (timer)
                clearTimeout(timer);

            console.error('Error:', error)
            if (this._completion) this.OnCompletion(null, error, dtInit);
        });

        timer = setTimeout(() => {
            console.log("Abortando petición: ", this.url)
            this.abortController.abort();
        }, this.timeout);
    }

    public Blob(): void {
        if (this.params && this.methodReq == EMethodRequest.GET) this.url += this.ParamsGET();
        const dtInit = new Date()
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                if (this._completion)
                    this.OnCompletion(xhr.response, null, dtInit);
            }
        });
        xhr.addEventListener("error", (event) => {
            console.log('Oops! Something went wrong.');
            console.log(event)
            if (this._completion)
                this.OnCompletion(null, xhr.response, dtInit);
        });

        xhr.open(this.methodReq, this.url);
        if (this.hasContentType)
            xhr.setRequestHeader("Content-Type", "application/json");
        xhr.responseType = "blob";
        xhr.timeout = this.timeout;

        xhr.send(JSON.stringify(this.params));
    }

    private Header(): Request {
        if (this.methodReq == EMethodRequest.GET && this.params)
            this.url += this.ParamsGET();

        let headers = {} as any;
        if (this.hasContentType)
            headers['Content-type'] = "application/json; charset=UTF-8";

        return new Request(this.url, {
            method: this.methodReq,
            body: this.methodReq == EMethodRequest.POST ? JSON.stringify(this.params) : null,
            cache: "no-cache",
            headers: headers
        });
    }

    private ParamsGET(): string {
        var concatStr = "?";
        var i = 0;
        for (var k in this.params) {
            if (i > 0) concatStr += "&";
            concatStr += k + "=" + this.params[k];
            i++;
        }

        return encodeURI(concatStr);
    }

    private OnCompletion(resData: T | null, error: Error | null, dtInit: Date) {
        const reqDurationFmt = _FmtElapsedTime(this.CalcRequestDuration(dtInit))
        if (error) {
            const abortError = error.name == "AbortError";
            const failedToFetchGET = error.message == "Failed to fetch" && this.methodReq == EMethodRequest.GET;
            const loadFailedGET = error.message == "Load failed" && this.methodReq == EMethodRequest.GET;

            if (this.forceErrorBotMessage || (!abortError && !failedToFetchGET && !loadFailedGET)) {
                let msg = `\nurl: ${this.url?.replace("https://rst1.kidi.mx/", "")} `
                if (resData)
                    msg += `\nresult: ${JSON.stringify(resData)}`
                if (this.extraInfoInErrorBotMessage)
                    msg += "\nextra: " + this.extraInfoInErrorBotMessage
                msg += "\nreqDuration: " + reqDurationFmt
                if (this.tags)
                    BotSendError(error, msg, this.tags);
            }
        }
        this._completion(resData, error);
        if (this.abortController.SuccessCompleted)
            this.abortController.SuccessCompleted();
    }

    /** @returns miliseconds */
    public CalcRequestDuration(dtInit: Date): number {
        const dtFinish = new Date()
        return dtFinish.getTime() - dtInit.getTime()
    }

    // public RequestBlobResource(/* callback: (resource: Blob, error: Error) => void */) {
    //     fetch(this.url)
    //         .then(async (response) => {
    //             let blob = await response.blob();
    //             blob = blob.size > 0 ? blob : null;
    //             // console.debug(response, " >> Blob:", blob);
    //             // callback(blob, null);
    //             this.completion(blob as T, null);
    //         })
    //         .catch((error: Error) => {
    //             // callback(null, error);
    //             this.completion(null, error);
    //             if (error.message != "Failed to fetch")
    //                 console.warn("Blob from resource error:", error);
    //         })
    //     // return await response.blob();
    // }

    // /** Hace uso de CacheStorage */
    // public RequestCacheBlobResource() {
    //     fn_GetData(this.url)
    //         .then(async (response) => {
    //             if (response) {
    //                 let blob = await response.blob();
    //                 blob = blob.size > 0 ? blob : null;
    //                 // console.debug(response, " >> Blob:", blob);
    //                 // callback(blob, null);
    //                 this.completion(blob as T, null);
    //             } else {
    //                 this.RequestBlobResource();
    //             }
    //         })
    //         .catch((error: Error) => {
    //             // callback(null, error);
    //             this.completion(null, error);
    //             if (error.message != "Failed to fetch")
    //                 console.warn("Cache resource error:", error);
    //             // fn_RequestBlobFromUrlResource(url, callback);
    //         })
    // }
}