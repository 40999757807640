import { EEventId, IEvent, EColors, EFoodPortion } from "../../data/models/Entities";
import _Label from "../../utils/Labels";
import { _ExtractLinks } from "../../utils/Strings";
import { _IsMobileDevice } from "../../utils/Device";
import ic_act from "/icons/event/use-icon-actividad.svg?raw";
import ic_hygiene from "/icons/event/use-icon-banio.svg?raw";
import ic_comment from "/icons/event/use-icon-comentario.svg?raw";
import ic_photo from "/icons/event/use-icon-foto.svg?raw";
import ic_humor from "/icons/event/use-icon-humor.svg?raw";
import ic_milestone from "/icons/event/use-icon-logro.svg?raw";
import ic_food from "/icons/event/use-icon-manzana.svg?raw";
import ic_sleep from "/icons/event/use-icon-siesta.svg?raw";
import ic_departure from "/icons/ic_departure.svg?raw";
import ic_enter from "/icons/ic_enter.svg?raw";
import ic_foodportion_1 from "/icons/ic-manzana-1.svg?raw";
import ic_foodportion_2 from "/icons/ic-manzana-2.svg?raw";
import ic_foodportion_3 from "/icons/ic-manzana-3.svg?raw";
import ic_foodportion_150 from "/icons/ic-manzana-150.svg?raw";
import ic_foodportion_200 from "/icons/ic-manzana-200.svg?raw";
import ic_foodportion_300 from "/icons/ic-manzana-300.svg?raw";

import { BotSendInfo } from "../../utils/AlertBot";

export const _EVENTS_CHILD_ORDER: [EEventId, IEvent][] = [
    [EEventId.SNOOZE, { id: EEventId.SNOOZE, name: _Label("c_evt.1"), iconSVGRaw: ic_sleep, color: EColors.COLOR_SNOOZE, hash: "eventsnooze" }],
    [EEventId.FOOD, { id: EEventId.FOOD, name: _Label("c_evt.2"), iconSVGRaw: ic_food, color: EColors.COLOR_FOOD, hash: "eventsfood" }],
    [EEventId.HYGIENE, { id: EEventId.HYGIENE, name: _Label("c_evt.3"), iconSVGRaw: ic_hygiene, color: EColors.COLOR_HYGIENE, hash: "eventshygiene" }],
    [EEventId.ACTIVITIES, { id: EEventId.ACTIVITIES, name: _Label("c_evt.4"), iconSVGRaw: ic_act, color: EColors.COLOR_ACTIVITIES, hash: "eventsactivities" }],
    [EEventId.MILLESTONE, { id: EEventId.MILLESTONE, name: _Label("c_evt.5"), iconSVGRaw: ic_milestone, color: EColors.COLOR_WIN, hash: "eventswin" }],
    [EEventId.EVALUATION, { id: EEventId.EVALUATION, name: _Label("c_evt.9"), iconSVGRaw: ic_milestone, color: EColors.COLOR_WIN, hash: "eventsevaluation" }],
    [EEventId.PHOTOS, { id: EEventId.PHOTOS, name: _Label("c_evt.6"), iconSVGRaw: ic_photo, color: EColors.COLOR_PHOTOS, hash: "eventsphotos" }],
    [EEventId.COMMENTS, { id: EEventId.COMMENTS, name: _Label("c_evt.7"), iconSVGRaw: ic_comment, color: EColors.COLOR_COMMENTS, hash: "eventscomments" }],
    [EEventId.HUMOR, { id: EEventId.HUMOR, name: _Label("c_evt.8"), iconSVGRaw: ic_humor, color: EColors.COLOR_HUMOR, hash: "eventshumor" }],
];

export const _EVENTS_CHILD: Partial<{
    [k in EEventId]: IEvent;
}> = _EVENTS_CHILD_ORDER
    .reduce((res, [key, info]) => {
        res[key] = info;
        return res;
    }, {});
type TEventItem = Pick<IEvent, "id" | "name" | "color" | "iconSVGRaw">;

/** ConfigEvents Entrada / Salida */
export const _EVENTS_ES_INFO: Partial<{ [k in EEventId]: TEventItem; }> = {
    [EEventId.ENTRADA_SALIDA_ESCUELA]: { id: EEventId.ENTRADA_SALIDA_ESCUELA, name: "EntradaSalidaEscuela", iconSVGRaw: ic_enter, color: EColors.COLOR_ENTER },
    [EEventId.ENTRADA_SALIDA_GRUPO]: { id: EEventId.ENTRADA_SALIDA_GRUPO, name: "EntradaSalidaGrupo", iconSVGRaw: ic_departure, color: EColors.COLOR_ENTER },
};

export interface IPortionFood {
    portion: EFoodPortion;
    name: string;
    iconSVGRaw: string;
    enabled?: boolean;
};
export const _mapFoodPortions = new Map<number, IPortionFood>([
    [1, { name: "", portion: EFoodPortion._25_P, iconSVGRaw: ic_foodportion_1 }], // FIXME SRCs
    [2, { name: "", portion: EFoodPortion._50_P, iconSVGRaw: ic_foodportion_2 }],
    [3, { name: "", portion: EFoodPortion._100_P, iconSVGRaw: ic_foodportion_3 }],
    [4, { name: "x1", portion: EFoodPortion._150_P, iconSVGRaw: ic_foodportion_150 }],
    [5, { name: "x2", portion: EFoodPortion._200_P, iconSVGRaw: ic_foodportion_200 }],
    [6, { name: "x3", portion: EFoodPortion._300_P, iconSVGRaw: ic_foodportion_300 }],
]);

export function _CreateElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstElementChild as HTMLElement | SVGElement;
}

export function _AutoLink(str: string): HTMLSpanElement {
    const span = document.createElement('span');
    const links = _ExtractLinks(str).concat(null);
    let strFinal = "";
    let linkStart = 0;

    if (!str) return span;

    links.forEach((link) => {
        const startSlice = linkStart;

        if (link === null) {
            const finStr = str.slice(startSlice, str.length);
            strFinal += finStr;
            return;
        }

        const linkIndex = str.indexOf(link, linkStart);
        if (linkIndex == -1) {
            return;
        }

        linkStart = linkIndex;
        const normalStr = str.slice(startSlice, linkStart);
        const linkStr = str.slice(linkStart, linkStart + link.length);

        if (!_IsMobileDevice())
            strFinal += (normalStr + `<a target="_blank" href="${linkStr}">${linkStr}</a>`);

        else
            strFinal += normalStr + `<a class="link">${linkStr}</a>`;
        linkStart += linkStr.length;
    });

    span.innerHTML = strFinal; // FIXME no usar innerHTML

    [...span.children].forEach(elem => {
        if (!elem.classList.contains("link")) return;
        // if (!URL_REGEX.test(elem.textContent)) return;
        elem.addEventListener("click", () => Mobile._OpenLink(elem.textContent));
    });

    return span;
}

let botInfoIntersectionObserver = false;
export function _IntersectionObserver(callback: IntersectionObserverCallback, options?: IntersectionObserverInit): IntersectionObserver {
    if (!window["IntersectionObserver"]) {
        if (!botInfoIntersectionObserver) {
            botInfoIntersectionObserver = true;
            BotSendInfo("'IntersectionObserver' no support");
        }
        return null;
    }
    return new IntersectionObserver(callback, options);
}
