import "../../../styles/Base-ui.scss";
import ic_report from '/icons/ic_report.svg?raw'
import ic_chat from '/icons/ic_chat.svg?raw'
import ic_classroom from '/icons/ic_classroom.svg?raw'
import { Selection } from "d3";
import { CreateLogoOptions } from "../components/LogoOptions";
import { EEventId } from "../../data/models/Entities";
import { Base } from "./Base";
import { _HashAssign } from "../../routes/UIManager";
import { _APP_DATA } from "../../data/AppData";
import { _LoadUnreadedMessage, _mapUnreadedMsgByGroup } from "../../data/services/Chats";
import { _LoadConfig, _mapConfiguration } from "../../data/services/Configuration";
import { _CreateElementFromHTML } from "../utils/General";

export class BaseUI extends Base {


    protected headerContainer: Selection<HTMLDivElement, any, any, any>;
    protected bodyContainer: Selection<HTMLDivElement, any, any, any>;
    protected footerContainer: Selection<HTMLDivElement, any, any, any>;

    protected timmerLoadUnreadedChat: number = 1000 * 30;
    private idTimmerLoadUnreaded: NodeJS.Timeout;
    private hasChatConfig: boolean = false;

    constructor(
        config?: { addOptionsInHeader: boolean, addMainLogoOptions: boolean, addFooter?: boolean, className?: string }
    ) {
        config = config || { addMainLogoOptions: true, addOptionsInHeader: true };
        const cls = "main-container " + (config.className || "");

        super(cls, true);

        config.addFooter = config.addFooter || false;

        this.headerContainer = this.mainContainer.append("div").classed("header", true);

        this.CreateHeader(config.addOptionsInHeader);
        if (config.addMainLogoOptions)
            CreateLogoOptions(this.mainContainer);

        this.bodyContainer = this.mainContainer.append("div").classed("body-container", true);
        if (!config.addMainLogoOptions)
            this.bodyContainer.classed("empty", true);

        this.footerContainer = this.mainContainer.append("div")
        if (config.addFooter) {
            this.footerContainer.classed("footer-container", true);
            this.bodyContainer.classed("add-footer", true);
        }

        if (config.addOptionsInHeader) {//Cuando se muestra el chat
            this.ValidateChat()
            this.LoadConfigAndunreadedMsg();
        }
    }

    protected LoadConfigAndunreadedMsg() {
        if (_APP_DATA.userData.IdNivel === 0) return;

        const hasChatConfigInit = this.hasChatConfig;
        if (this.hasChatConfig)
            _LoadUnreadedMessage((_) => this.OnChangeOnreadedMessage());

        _LoadConfig(_APP_DATA.userData.IdNivel, (_, result) => {
            if (result.length > 0) {
                this.ValidateChat();
                if (!hasChatConfigInit && this.hasChatConfig)
                    _LoadUnreadedMessage((_) => this.OnChangeOnreadedMessage());
            }
        });

        this.idTimmerLoadUnreaded = setTimeout(() => {
            this.LoadConfigAndunreadedMsg();
        }, this.timmerLoadUnreadedChat);
    }

    private ValidateChat() {
        let itemChat = Array.from(_mapConfiguration.values()).filter(o => o.Tipo == EEventId.CHAT);
        this.hasChatConfig = itemChat.length > 0;

        const btnChat = this.headerContainer.select(".div-options").select(".chat-icon-container");
        btnChat.classed("hide", !this.hasChatConfig);
    }

    protected CreateHeader(addOptions: boolean) {
        let title = _APP_DATA.userData.NombreGrupo;
        const teacherName = _APP_DATA.userData.NombreMaestra + " " + _APP_DATA.userData.ApPaternoMaestra + " " + _APP_DATA.userData.ApMaternoMaestra;
        if (title && teacherName.trim()) title += " - "
        title += teacherName.trim() != "" ? teacherName : "";

        this.headerContainer.append("h2").classed("title", true).text(title);

        if (!addOptions) return;

        const divIcons = this.headerContainer.append("div").classed("div-options", true);

        divIcons.append(() => _CreateElementFromHTML(ic_classroom))
            .classed("img-options-header", true)
            .classed("btn-class-room", true)
            .on("click", () => {
                _HashAssign("classroom");
            });

        divIcons.append(() => _CreateElementFromHTML(ic_report))
            .classed("img-options-header", true)
            .on("click", () => {
                _HashAssign("report");
            });

        divIcons.append("div").classed("chat-icon-container", true)
            .html(ic_chat)
            .call((scope) => {
                const countMsg = _mapUnreadedMsgByGroup.get(_APP_DATA.userData.IdGrupo)?.countMsg || 0;
                scope.append("span")
                    .classed("num-messages", true)
                    .classed("active", countMsg > 0)
                    .text(countMsg);
            })
            .on("click", () => {
                _HashAssign("chat");
            });
    }

    protected OnChangeOnreadedMessage() {
        const countMsg = _mapUnreadedMsgByGroup.get(_APP_DATA.userData.IdGrupo)?.countMsg || 0;
        this.headerContainer.select(".chat-icon-container").select(".num-messages")
            .classed("active", countMsg > 0)
            .text(countMsg);
    }

    public OnDestroy(): void {
        super.OnDestroy();

        clearInterval(this.idTimmerLoadUnreaded);
        this.idTimmerLoadUnreaded = null;
    }
}