import '../../../styles/components/Toast.scss'
import { Selection, create, select } from "d3";
import ic_done from '/icons/ic_done.svg?raw'
import ic_error from '/icons/ic_error.svg?raw'
import ic_info from '/icons/ic_info.svg?raw'
import { _AddSwipeEvent } from '../utils/Swipe';
import { _CURRENT_MOBILEKEYBOARD } from '../../utils/Device';

const DEFALUT_VISIBLE_DURATION = 3000;

const toastTypes = new Map([
    ['success', { iconSVGRaw: ic_done }],
    ['error', { iconSVGRaw: ic_error }],
    ['warn', { iconSVGRaw: ic_info }],
    ['info', { iconSVGRaw: ic_info }],
]);

export function ShowToast(
    title: string, subtitle: string, type?: 'success' | 'error' | 'warn' | 'info', position?: 'TOP-LEFT' | 'TOP-RIGHT' | 'BOTTOM-LEFT' | 'BOTTOM-RIGHT'
) {
    CreateToast({ id: type, title: title, subtitle: subtitle, position: position });
}

export function CreateToast(
    opts: { id?: 'success' | 'error' | 'warn' | 'info', title: string, subtitle: string, position?: 'TOP-LEFT' | 'TOP-RIGHT' | 'BOTTOM-LEFT' | 'BOTTOM-RIGHT' }
) {
    opts.id = opts.id || 'success'
    opts.position = opts.position || 'TOP-RIGHT'

    var notifications = select<HTMLUListElement, unknown>(".notifications." + opts.position);
    if (notifications.size() == 0) {
        notifications = select("body").append("ul").attr("class", "notifications " + opts.position);
        if (_CURRENT_MOBILEKEYBOARD.BodyTranslateY) {
            notifications.style("top", `calc(var(--padding2) + ${_CURRENT_MOBILEKEYBOARD.BodyTranslateY}px)`);
        }
        document.addEventListener("keyboardsizechange", (ev) => {
            const screenTop = (ev as KeyboardSizeChangeEvent).detail.BodyTranslateY;
            if (screenTop == 0) {
                notifications.style("top", null);
            } else {
                notifications.style("top", `calc(var(--padding2) + ${screenTop}px)`);
            }
        })
    }

    const { iconSVGRaw } = toastTypes.get(opts.id) || { iconSVGRaw: '', title: '', message: "" };

    const toast = create("li");
    toast.classed('toast ' + opts.id, true);
    toast.html(`<div class="column">
                    <div class="icon-toast">...</div>
                    <div class="row">
                        <span>${opts.title}</span>
                        <span class="subtitle">${opts.subtitle}</span>
                    </div>
                </div>`);
    toast.select(".icon-toast").html(iconSVGRaw);
    _AddSwipeEvent(toast.node(), "swipeRight", () => RemoveToast(toast));
    toast.property("timeoutId", setTimeout(() => RemoveToast(toast), DEFALUT_VISIBLE_DURATION));

    notifications.node().insertAdjacentElement("afterbegin", toast.node());
}

export function RemoveToast(toast: Selection<HTMLLIElement, any, any, any>) {
    toast.classed("hides", true);
    if (toast.property("timeoutId")) clearTimeout(toast.property("timeoutId"))
    setTimeout(() => toast.remove(), 200);
}