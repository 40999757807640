import "../../../styles/pages/Event-comment.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { EEventId, ETipoComentario } from "../../data/models/Entities";
import ic_com_medicina from '/icons/ic_comentario-medicina.svg?raw'
import ic_com_normal from '/icons/ic_comentario-normal.svg?raw'
import ic_com_importante from '/icons/ic_comentario-importante.svg?raw'
import { ShowToast } from "../components/Toast";
import { RadioGroup } from "../components/RadioButton";
import { _mapCommentByEvent } from "../../data/services/Event";
import { Input } from "../components/Input";
import _L from "../../utils/Labels";

export class EventComment extends BaseUIEvent {

    private txtComment?: Input;

    private groupRadComment: RadioGroup;

    constructor() {
        super('ui-event-comment', EEventId.COMMENTS);

        this.CreateCheckAndComment();
        this.AddSaveBtn();
    }

    private CreateCheckAndComment() {

        this.groupRadComment = new RadioGroup(this.bodyContainer, "type-comment");
        this.groupRadComment.AddRadioButton()
            .SetIdentifier('normal')
            .SetLabel(_L("comment.tnormal"))
            .AddIconSvg(ic_com_normal)
            .SetValue(ETipoComentario.NORMAL)
            .Checked(true);
        this.groupRadComment.AddRadioButton()
            .SetIdentifier('important')
            .SetLabel(_L("comment.timportant"))
            .AddIconSvg(ic_com_importante)
            .SetValue(ETipoComentario.IMPORTANTE);
        this.groupRadComment.AddRadioButton()
            .SetIdentifier('medicine')
            .SetLabel(_L("comment.tmedicina"))
            .AddIconSvg(ic_com_medicina)
            .SetValue(ETipoComentario.MEDICINA);

        let comments = _mapCommentByEvent.get(EEventId.COMMENTS) || [];
        this.txtComment = new Input(this.bodyContainer, { inputType: 'textarea', concatValueSelected: true, required: true })
            .SetPlaceholder(_L("general.comentario"))
            .SetDefaultOptions(comments.map(o => o.Mensaje))
    }

    protected OnSaveClick(): void {
        if (this.childList.length === 0) {
            ShowToast(_L("comment.title"), _L("comment.alumnos_required"), "info");
            return;
        }

        const radSelected = this.groupRadComment.GetRadioSelectedInfo();
        if (radSelected === undefined || radSelected === null) {
            ShowToast(_L("comment.title"), _L("comment.options_required"), 'info');
            return;
        }

        if (!this.txtComment.Validate()) {
            this.txtComment.FocusIn();
            ShowToast(_L("comment.title"), _L("comment.comments_required"), 'info');
            return;
        }
        const comment = this.txtComment?.value.trim();

        this.AddCommonGralEvent({
            titleToast: _L("comment.title"),
            evtID: EEventId.COMMENTS,
            comment: comment,
            eventName: null,
            category: radSelected,
        });
    }

}