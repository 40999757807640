import "../../../styles/pages/Event-activities.scss";
import { timeDay } from "d3";
import _L from "../../utils/Labels";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { ShowToast } from "../components/Toast";
import { Input } from "../components/Input";
import { LOADING } from "../components/Loading";
import { _mapCommentByEvent } from "../../data/services/Event";
import { _LoadActivitiesInfo } from "../../data/services/Agendas";
import { EEventId } from "../../data/models/Entities";

export class EventActivities extends BaseUIEvent {

    private txtActivity?: Input;
    private txtComment?: Input;

    constructor() {
        super('ui-event-activities', EEventId.ACTIVITIES);

        LOADING.Show();

        this.AddInputTexts();
        this.AddSaveBtn();

        this.LoadData();
    }

    private LoadData() {
        const [dtStart, dtEnd] = this.GetDate();

        _LoadActivitiesInfo(dtStart, dtEnd, null, result => {
            LOADING.Dismiss();

            if (result.length == 0) return;

            this.txtActivity?.SetDefaultOptions(result.map(o => o.Titulo));
        });
    }

    private GetDate(): Array<Date> {
        let start = timeDay.floor(new Date());
        let end = new Date(new Date(start).setDate(start.getDate() + 1));
        end.setMilliseconds(end.getMilliseconds() - 1);

        return [start, end];
    }

    private AddInputTexts() {
        this.txtActivity = new Input(this.bodyContainer, { placeholder: _L("activity.act"), required: true });

        let actComments = _mapCommentByEvent.get(EEventId.ACTIVITIES) || [];
        // this.txtComment = new TextArea(this.bodyContainer)
        this.txtComment = new Input(this.bodyContainer, { inputType: 'textarea', concatValueSelected: true })
            .SetPlaceholder(`${_L("general.comentario")} (${_L("general.optional").toLowerCase()})`)
            .SetDefaultOptions(actComments.map(o => o.Mensaje));
    }


    protected OnSaveClick(): void {
        if (!this.txtActivity.Validate()) {
            this.txtActivity.FocusIn();
            ShowToast(_L("activity.title"), _L("activity.act_required"), "info");
            return;
        }
        if (!this.txtComment.Validate()) return;

        const actividad = this.txtActivity?.value;
        const comment = this.txtComment?.value.trim() || "";

        this.AddCommonGralEvent({
            titleToast: _L("activity.title"),
            evtID: EEventId.ACTIVITIES,
            comment: comment,
            eventName: actividad,
            category: 0
        });
    }

}