import { apiLogin, apiLogout, apiRecoverPassword, apiValidateSession } from "../API";
import { _APP_DATA } from "../AppData";
import { getRows, iDBStore, setRows } from "../utils/DB";
import { IApiResult } from "../models/Entities";
import { RequestData } from "../utils/RequestData";

export const _storeUser: iDBStore = {
    name: "user",
    key: "IdUsuario",
    fieldKeys: []
}

export function _InitSession(user: string, pass: string, callback: (userData: IUsuario | null, status: number) => void) {
    let params = {
        Correo: user.toLowerCase(),
        Contrasenia: pass,
        Imei: _APP_DATA.IMEI || "",
        TokenFirebase: _APP_DATA.FCM || "",
        TipoDispositivo: _APP_DATA.mobileType
    }

    new RequestData<IApiResult<IUsuario>>(apiLogin)
        .Params(params)
        .Completion((response, error) => {
            let userData: IUsuario | null = null;
            let status = response ? response.Resultado : -111;
            if (error || !response) {
                if (callback) callback(userData, status);
                return;
            }

            userData = response.Datos;
            if (status == 1) {
                userData.IntToken = userData.IntToken || 1541465220;
                userData.StrToken = userData.StrToken || "q3Sx9baQkwvzzV/SQTofaP5hz1OJwofWpVpTpiQEv40=";

                userData.DtHoraInicio = new Date(new Date(userData.HoraInicio).toLocaleString("en-US", { timeZone: userData.ZonaHoraria }));
                userData.DtHoraFin = new Date(new Date(userData.HoraFin).toLocaleString("en-US", { timeZone: userData.ZonaHoraria }));

                _SetInfoUser(userData);
            }

            if (callback) callback(userData, status);
        }).Post();
}

export function _SetInfoUser(info: IUsuario) {
    return setRows(_storeUser.name, [info]);
}

export async function _GetUserInfo(): Promise<IUsuario | null> {
    const users = await getRows<Array<IUsuario>>(_storeUser.name)
        .catch(() => null);
    let res = users && users.length > 0 ? users[0] : null;
    return res;
}

var TIMES_SESSION_0 = 0;
export function _SignOut(callback: (result: number) => void) {
    let params = {
        Imei: _APP_DATA.IMEI || "",
        IdUsuario: _APP_DATA.userData.IdUsuario
    }

    new RequestData<IApiResult<any>>(apiLogout)
        .Params(params)
        .Completion((response, _) => {
            let status = response ? response.Resultado : -111;

            if (status > 0) {
                status = 1;
            } else {
                console.log("Intentando cerrar sesión(", _APP_DATA.userData.IdSesion, ") con Resultado: ", status, " ::: ", JSON.stringify(response));
            }

            if (status == 0) {
                TIMES_SESSION_0++;
                if (TIMES_SESSION_0 == 3) {
                    console.error("3 intentos de cierre de sesion con Respuesta == 0::: " + JSON.stringify(params));
                    status = 1;
                }
            }

            if (callback) callback(status);
        }).Post();
}

export function _RecoverPassword(email: string, callback: (resStatus: number) => void) {
    let params = {
        Correo: email
    }

    new RequestData<IApiResult<any>>(apiRecoverPassword)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _ValidateSession(callback: (status: number) => void) {
    if (!_APP_DATA.userData || !_APP_DATA.userData.IdSesion || _APP_DATA.userData.IdSesion === 0) {
        if (callback) callback(-111);

        return;
    }

    let params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdSesion: _APP_DATA.userData.IdSesion
    }

    new RequestData<IApiResult<any>>(apiValidateSession)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : 0;

            if (status < 0)
                console.error("Cerrando sesión con validación < ", status, " ::: ", JSON.stringify(params), error?.message);

            if (callback) callback(status);
        }).Post();
}