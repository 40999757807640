import "../../../styles/components/Button.scss";
import { Selection, select } from "d3";
import { ImageToSvgElement } from "../utils/ImageSVG";
import { _CreateElementFromHTML } from "../utils/General";

export class Button {

    public _button: Selection<HTMLAnchorElement, any, any, any>;
    private _title: string;
    private _fillImage: string | null;
    private _img?: string;
    private isSvgRaw?: boolean

    constructor(
        divElement: Selection<HTMLDivElement, any, any, any>
    ) {
        this._button = divElement.append("a").classed("button", true);
        this._title = "";
        this._fillImage = null;
    }

    public set title(val: string) {
        this._title = val;
        this.SetTitleAndImage();
    }

    public get title() {
        return this._title;
    }

    public Title(val: string): Button {
        this._title = val;
        this._button.text(val);
        return this;
    }

    public set width(w: number) {
        this._button.style("width", w)
    }

    public Width(w: number): Button {
        this._button.style("width", w);
        return this;
    }

    public set height(h: number) {
        this._button.style("height", h)
    }

    public Height(h: number): Button {
        this._button.style("height", h)
        return this;
    }

    public set image(val: string) {
        this._img = val;
        this.SetTitleAndImage();
    }

    public Image(img: string, isSVGRaw?: boolean): Button {
        if (isSVGRaw) this.isSvgRaw = isSVGRaw;
        this._img = img;

        this.SetTitleAndImage();
        return this;
    }

    private SetTitleAndImage() {
        const descBtn = this._title || "";
        this._button.text(descBtn);

        if (this._img) {
            if (this.isSvgRaw) {
                this._button.html("");
                this._button.append("span")
                    .classed("button__icon", true)
                    .call((btn) => {
                        btn.append(() => _CreateElementFromHTML(this._img))
                            .classed("button__icon", true)
                        //.style("height", "100%")
                        //.style("width", "100%");
                    })
                this._button.html(this._button.node().innerHTML + " " + descBtn)
            } else {
                ImageToSvgElement(this._img, (res) => {
                    select(res).classed("button__icon", true);
                    this._button.html(`<span class="button__icon" >
                        ${res.outerHTML}
                    </span> `+ descBtn);

                    if (this._fillImage)
                        this._button.select("span").select(".button__icon").style("fill", this._fillImage)
                });
            }

        }
    }

    public Classed(cls: string): Button {
        this._button.classed(cls, true);
        return this;
    }

    public FillImage(color: string): Button {
        this._fillImage = color;
        this._button.select("span").select(".button__icon").style("fill", this._fillImage)
        return this;
    }

    public OnClick(listener: () => void): Button {
        this._button.on("click", listener);

        return this;
    }
}