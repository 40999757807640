import { apiInitialUnreadMessages, apiLoadFile, apiLoadMessages, apiMarkAsRead, apiNewMessages, apiUpdateUnreadMessages } from "../API";
import { _APP_DATA } from "../AppData";
import { getAllIndex, getRows, iDBStore, setRows } from "../utils/DB";
import { EFileType, EStatusMessage, IApiResult, IMessage, IUnreadedMessage } from "../models/Entities";
import { _ID_CHAT_GROUP } from "../utils/General";
import { RequestData } from "../utils/RequestData";

import ic_waiting from '/icons/chat/ic_waiting.svg?raw';
import ic_done from '/icons/chat/ic_done.svg?raw';
import ic_done_all from '/icons/chat/ic_done_all.svg?raw';
import ic_error from '/icons/chat/ic_error.svg?raw'

import ic_image from '/icons/chat/ic_image.svg?raw'
import ic_pdf from '/icons/chat/ic_pdf.svg?raw'
import ic_word from '/icons/chat/ic_word.svg?raw'
import ic_ppt from '/icons/chat/ic_ppt.svg?raw'
import ic_excel from '/icons/chat/ic_excel.svg?raw'
import ic_unknown from '/icons/chat/ic_unknown.svg?raw'

export var _storeChat: iDBStore = {
    name: "Chat",
    key: "IdMensaje",
    fieldKeys: ["ChildId", "MaxUpdated"]
}

export var _storeGRPChat: iDBStore = {
    name: "ChatGRP",
    key: "IdMensaje",
    fieldKeys: ["ChildId", "MaxUpdated"]
}

export var _maxChatMessageUpdated: Map<number, string> = new Map();

export async function _GetChatInDB(childId: number): Promise<IMessage[]> {
    const storeName = childId === _ID_CHAT_GROUP ? _storeGRPChat.name : _storeChat.name;

    return getAllIndex(storeName, "ChildId", childId).then((res: Array<IMessage>) => {
        res.sort((a, b) => a.IdMensaje - b.IdMensaje);
        return res;
    });
}

export async function _ObserveChatChild(childId: number, callback: (result: Array<IMessage>, hasUnreadMessage: boolean) => void) {
    await SetMaxUpdated(childId);

    LoadInfoMessage(childId, 0, _maxChatMessageUpdated.get(childId), (result, updated, hasUnreadMessage) => {
        if (result.length > 0 && updated) _maxChatMessageUpdated.set(childId, updated);
        callback(result, hasUnreadMessage);
    });
}

function LoadInfoMessage(childId: number, minId: number, maxDate: string | undefined, callback: (result: Array<IMessage>, maxUpdate: string | null, hasUnreadMessage: boolean) => void) {
    LoadMessage(childId, minId, maxDate, callback);
}

export function _LoadPrevMessage(childId: number, minId: number, callback: (result: Array<IMessage>) => void) {
    LoadInfoMessage(childId, minId, undefined, callback);
}

function LoadMessage(childId: number, minId: number, maxDate: string | undefined, callback: (result: Array<IMessage>, maxUpdate: string | null, hasUnreadMessage: boolean) => void) {
    let params = {
        IdChild: childId,
        Maximo: minId,
        FechaMaxima: maxDate || null,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdMaestra: _APP_DATA.userData.IdMaestra,
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion
    }

    new RequestData<IApiResult<IMessage[]>>(apiLoadMessages)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || [];

            if (error)
                console.log(error.name, error.message);

            let maxUpdated = response?.Maxima;
            resultData.sort((a, b) => a.IdMensaje - b.IdMensaje);
            let countUnreadMessage = 0;

            resultData.forEach(item => {
                item.Fecha = new Date(item.Fecha);
                item.FechaLeido = item.FechaLeido ? new Date(item.FechaLeido) : null;
                if (item.FechaLeido == null) countUnreadMessage++;

                item.ChildId = childId;
                item.MaxUpdated = maxUpdated;
                item.StatusMessage = item.FechaLeido ? EStatusMessage.LEIDO : EStatusMessage.ENTREGADO;
            });

            if (resultData.length > 0) _SaveChatsData(resultData);

            if (callback) callback(resultData, maxUpdated, countUnreadMessage > 0);
        }).Get();
}

export function _SaveChatsData(listData: Array<IMessage>) {
    const storeName = listData[0].ChildId === _ID_CHAT_GROUP ? _storeGRPChat.name : _storeChat.name;

    return setRows(storeName, listData);
}

export function _GetChatsData(): Promise<Array<IMessage>> {
    return getRows(_storeChat.name);
}

async function SetMaxUpdated(childId: number) {
    if (_maxChatMessageUpdated.get(childId))
        return _maxChatMessageUpdated.get(childId);

    return getAllIndex(_storeChat.name, "ChildId", childId).then((res: Array<IMessage>) => {
        if (res.length > 0) {
            let arr = res.sort((a, b) => {
                return (new Date(a.MaxUpdated) < new Date(b.MaxUpdated)) ? 1 : -1;
            });
            _maxChatMessageUpdated.set(childId, new Date(arr[0].MaxUpdated).toISOString());
        }

        return _maxChatMessageUpdated.get(childId);
    });
}

export function _MarkAsReadMessage(idChild: number, callback?: (status: number) => void) {
    let params = {
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdChild: idChild
    }

    new RequestData<IApiResult<any>>(apiMarkAsRead)
        .Params(params)
        .Completion((response, error) => {
            if (error)
                console.log(error.name, error.message);

            let status = response?.Resultado || -111;
            if (callback) callback(status);
        }).Post();
}

export const _URLImagePreview = (fileId: number) => apiLoadFile + "IdArchivo=" + fileId + "&IdOrganizacion=" + _APP_DATA.userData.IdOrganizacion + "&Tamanio=" + 2;
export const _URLFile = (fileId: number) => apiLoadFile + "IdArchivo=" + fileId + "&IdOrganizacion=" + _APP_DATA.userData.IdOrganizacion + "&Tamanio=" + 1;
export const _URLImageThumb = (fileId: number) => apiLoadFile + "IdArchivo=" + fileId + "&IdOrganizacion=" + _APP_DATA.userData.IdOrganizacion + "&Tamanio=" + 3;

interface IMessageResult {
    IdArchivo: number;
    IdChat: number;
}
export function _AddMessage(childrIds: number[], message: string, file: File | undefined, callback?: (status: number, fileId: number, dataResult: IMessageResult[]) => void): void {
    let params = {
        IdsChild: childrIds,
        Mensaje: message,
        Archivo: file || null,
        Tipo: 1,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdMaestra: _APP_DATA.userData.IdMaestra,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion,
    }

    new RequestData<IApiResult<IMessageResult[]>>(apiNewMessages)
        .Params(params)
        .ExtraInfoInErrorBotMessage(`FileSize(${file?.size} bytes)`)
        .Completion((response, _) => {
            let status = response ? response.Resultado : -111;
            if (status <= 0) {
                console.log("Ocurrio un error al crear el mensaje: ", response);
                if (callback) callback(status, 0, []);
                return;
            }

            let resultData = response?.Datos || [];
            let idFile = 0;
            let idResult = 0;

            if (resultData.length > 0) {
                idFile = resultData[0].IdArchivo;
                idResult = resultData[0].IdChat;
            }

            if (callback) callback(idResult, idFile, resultData);
        }).FORM();
}

export interface IFileTypeChat {
    fileType: EFileType;
    icon: string;
}

export const FILE_TYPE_FILES_CHAT = new Map<string, IFileTypeChat>([
    [".jpg", { fileType: EFileType.IMAGE, icon: ic_image }],
    [".png", { fileType: EFileType.IMAGE, icon: ic_image }],
    [".jpeg", { fileType: EFileType.IMAGE, icon: ic_image }],
    [".pdf", { fileType: EFileType.PDF, icon: ic_pdf }],
    [".doc", { fileType: EFileType.WORD, icon: ic_word }],
    [".docx", { fileType: EFileType.WORD, icon: ic_word }],
    [".ppt", { fileType: EFileType.PPT, icon: ic_ppt }],
    [".pptx", { fileType: EFileType.PPT, icon: ic_ppt }],
    [".xls", { fileType: EFileType.EXCEL, icon: ic_excel }],
    [".xlsx", { fileType: EFileType.EXCEL, icon: ic_excel }],
    ["unknown", { fileType: EFileType.unknown, icon: ic_unknown }]
]);

export const ICON_STATUS_MESSAGE_CHAT = new Map<EStatusMessage, { status: EStatusMessage, icon: string }>([
    [EStatusMessage.ENVIADO, { status: EStatusMessage.ENVIADO, icon: ic_waiting }],
    [EStatusMessage.ENTREGADO, { status: EStatusMessage.ENTREGADO, icon: ic_done }],
    [EStatusMessage.LEIDO, { status: EStatusMessage.LEIDO, icon: ic_done_all }],
    [EStatusMessage.ERROR, { status: EStatusMessage.ERROR, icon: ic_error }]
])

interface IUnreadedMessageGroup {
    updatedAt: string;
    countMsg: number;
}
export const _mapUnreadedMsgByGroup: Map<number, IUnreadedMessageGroup> = new Map();
export var _mapChildsMessageUnreaded: Map<number, Map<number, number>> = new Map();

export function _LoadUnreadedMessage(callback: (msgs: IUnreadedMessage[]) => void, idGroup?: number) {
    idGroup = idGroup || _APP_DATA.userData.IdGrupo;

    if (!_mapUnreadedMsgByGroup.has(idGroup))
        LoadInitialUnreadedMessage(idGroup, callback);
    else
        LoadUpdateUnreadedMessage(idGroup, callback);
}

function LoadInitialUnreadedMessage(idGroup: number, callback: (msgs: IUnreadedMessage[]) => void) {
    let params = {
        IdGrupo: idGroup,
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion
    }

    new RequestData<IApiResult<IUnreadedMessage[]>>(apiInitialUnreadMessages)
        .Params(params)
        .Completion((response, error) => {
            let resultData: Array<IUnreadedMessage> = [];
            let status = response ? response.Resultado : -111;

            if (error)
                console.log(error.name, error.message);

            if (status > 0 && response) {
                resultData = response.Datos || [];
                const maxDate = response.Maxima ? response.Maxima : new Date().toISOString();

                _mapUnreadedMsgByGroup.set(idGroup, { updatedAt: maxDate, countMsg: resultData.length });

                if (_mapChildsMessageUnreaded.has(idGroup))
                    _mapChildsMessageUnreaded.get(idGroup).clear();//Limpiar map antes de descargar
                else
                    _mapChildsMessageUnreaded.set(idGroup, new Map());

                resultData.forEach(item => {
                    _mapChildsMessageUnreaded.get(idGroup).set(item.IdChild, item.Mensajes);
                });

                if (callback) callback(resultData)//Enviar respuesta cuando el servicio devuelva datos
            }
        }).Get();
}

function LoadUpdateUnreadedMessage(idGroup: number, callback: (msgs: IUnreadedMessage[]) => void) {
    const updatedAt = _mapUnreadedMsgByGroup.get(idGroup)?.updatedAt;
    let params = {
        IdGrupo: idGroup,
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion,
        FechaMaxima: updatedAt
    }

    new RequestData<IApiResult<IUnreadedMessage[]>>(apiUpdateUnreadMessages)
        .Params(params)
        .Completion((response, error) => {
            if (error)
                console.log(error.name, error.message);

            const resultData = response?.Datos || [];

            const maxDate = response?.Maxima || null;
            const objUnrd: IUnreadedMessageGroup = _mapUnreadedMsgByGroup.get(idGroup) || { updatedAt: "", countMsg: 0 };

            if (resultData.length > 0 && maxDate != null)
                objUnrd.updatedAt = maxDate;

            resultData.forEach(item => {
                let unreadedLen = 0;
                if (_mapChildsMessageUnreaded.get(idGroup)?.has(item.IdChild))
                    unreadedLen = _mapChildsMessageUnreaded.get(idGroup).get(item.IdChild) || 0;

                unreadedLen = item.FechaLeido ? (unreadedLen -= 1) : (unreadedLen += 1);
                unreadedLen = unreadedLen < 0 ? 0 : unreadedLen;

                objUnrd.countMsg = item.FechaLeido ? (objUnrd.countMsg -= 1) : (objUnrd.countMsg += 1);//Cambiar el total de msg por grupo

                if (unreadedLen > 0) {
                    if (!_mapChildsMessageUnreaded.has(idGroup)) _mapChildsMessageUnreaded.set(idGroup, new Map());
                    _mapChildsMessageUnreaded.get(idGroup).set(item.IdChild, unreadedLen);
                }
                else if (_mapChildsMessageUnreaded.has(idGroup))
                    _mapChildsMessageUnreaded.get(idGroup).delete(item.IdChild);
            });

            if (resultData.length > 0) {
                _mapUnreadedMsgByGroup.set(idGroup, objUnrd);
                callback(resultData)//Enviar respuesta cuando el servicio devuelva datos
            }
        }).Get();
}