import '../../../styles/components/Input.scss';
import { ToImageSvgElement } from '../utils/ImageSVG';
import ic_down from '/icons/ic_down.svg'
import _Label from '../../utils/Labels';
import { select } from 'd3';
import { IS_MOBILE, _MobileSetKeyboardSize } from "../../utils/Device";

type HTMLInputElementX = HTMLInputElement & { _minLength: number; _maxLength: number; };
type IInputProperties = HTMLInputElementX | HTMLTextAreaElement;
type IInputExtendsProps = Pick<IInputProperties, "required" | "minLength" | "maxLength" | "placeholder" | "value" | "disabled" | "autocomplete" | "id">
    & Pick<HTMLInputElementX, "min" | "max" | "step">;
interface IConfig extends Partial<IInputExtendsProps> {
    inputType?: 'text' | 'password' | 'email' | 'number' | 'textarea' | "date";
    className?: string;
    concatValueSelected?: boolean;
    /** Desactiva la validación por defecto de typeMismatch
     * @see https://developer.mozilla.org/en-US/docs/Web/API/ValidityState/typeMismatch
     **/
    noValideTypeMismatch?: boolean;
}

interface IOptionsConfig<T = string> {
    OptionsOnStepItem(call: (div: TSelection<"htmlelement", T>) => void): IOptionsConfig<T>;
    OptionsOnSelect(call: (datum: T, div: TSelection<"htmlelement", T>) => void): IOptionsConfig<T>;
    OptionsOnSetInputText(call: (datum: T) => string): IOptionsConfig<T>;
    OptionsMinWidth(width: string): IOptionsConfig<T>;
    OptionsMaxWidth(width: string): IOptionsConfig<T>;
    OptionsWidth(width: string): IOptionsConfig<T>;
}

export class Input {

    private divWrapper: TSelection<"div">;
    public readonly lblInput: TSelection<"label">
    public readonly input: TSelection<"input" | "textarea">;
    private optionsListWrapper?: TSelection<"div">;
    private optionsList?: any[];
    private config: IConfig;

    constructor(container: HTMLElement | TSelection<any>, opts?: IConfig) {
        this.config = opts || {};
        opts = this.config;
        opts.inputType = opts.inputType || 'text';
        opts.required = Boolean(opts.required);

        this.divWrapper = (container instanceof HTMLElement ? select<any, any>(container) : container)
            .append("div")
            .classed("div-label-input", true);
        this.lblInput = this.divWrapper.append("label")
            .classed("input", true)

        if (opts.inputType != 'textarea') {
            this.input = this.lblInput.append("input")
                .attr("type", opts.inputType) as any

            //por ahora no aplica estilo personalizado para cajas de texto

        } else {
            this.input = this.lblInput.append("textarea")
                // .attr("placeholder", opts.placeholder)
                .attr("rows", "5")
                .attr("cols", "50") as any;
        }
        this.input
            .attr("placeholder", " ")
            .attr("class", "input__field " + (opts.className || ''))

        this.lblInput.append("span")
            .classed("input__label", true)
            .text(opts.placeholder || '');

        const input = this.input.node() as HTMLInputElementX;

        this.lblInput.append("span").classed("input__error", true);

        if (opts.required != null) input.required = opts.required;
        if (opts.minLength != null) input._minLength = opts.minLength;
        if (opts.maxLength != null) input._maxLength = opts.maxLength;
        if (opts.value != null) input.value = opts.value;
        if (opts.disabled != null) input.disabled = opts.disabled;
        if (opts.autocomplete != null) input.autocomplete = opts.autocomplete;
        if (opts.id != null) input.id = opts.id;
        if (opts.min != null) input.min = opts.min;
        if (opts.max != null) input.max = opts.max;
        if (opts.step != null) input.step = opts.step;

        (input as HTMLInputElementX).addEventListener("input", () => this.Validate());
        (input as HTMLInputElementX).addEventListener("focus", () => this.HideOptionsList());
        (input as HTMLInputElementX).addEventListener("blur", () => {
            this.Validate();
            if (!_MobileSetKeyboardSize) return;
            setTimeout(() => {
                const activeIsInput = document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement;
                if (!activeIsInput) {
                    _MobileSetKeyboardSize(0);
                }
            });
        });
    }

    get value(): string {
        return this.input.node()?.value || '';
    }

    set value(v: string) {
        this.input.property("value", v);
    }

    /** Valida y aplica estilo error
     * @returns `boolean`
     */
    public Validate() {
        const input = this.input.node() as HTMLInputElementX;
        if (!input) return false;
        const { validity, value } = input;
        const text = (value || "").trim();
        let valid = true;
        let message = "";

        if (input.required && !text) {
            // this.ShowOptionsList();
            message = _Label("validity.required_def");
            valid = false
        }
        else if (input._minLength != null && text.length < input._minLength) {
            valid = false;
            message = _Label("validity.min_length", "<b>" + input._minLength + "</b>");
        }
        else if (input._maxLength != null && text.length > input._maxLength) {
            valid = false;
            message = _Label("validity.max_length", "<b>" + input._maxLength + "</b>");
        }
        else if (!validity.valid) {
            valid = false;
            if (validity.tooShort)
                message = _Label("validity.min_length", "<b>" + input.minLength + "</b>");
            else if (validity.tooLong)
                message = _Label("validity.max_length", "<b>" + input.maxLength + "</b>");
            else if (validity.rangeOverflow)
                message = _Label("validity.range_overflow", "<b>" + input.max + "</b>");
            else if (validity.rangeUnderflow)
                message = _Label("validity.range_underflow", "<b>" + input.min + "</b>");
            else
                message = _Label("validity.input_invalid");

            if (this.config.noValideTypeMismatch) {
                const nInvalids = (() => {
                    let n = 0;
                    for (let k in validity)
                        if (validity[k]) n++;
                    return n;
                })()
                if (nInvalids == 1 && validity.typeMismatch) {
                    message = "";
                    valid = true;
                }
            }
        }

        this.lblInput.select(".input__error").html(message);
        this.lblInput.classed("error", !valid);
        return valid;
    }

    private OnSelectItemList(text: string) {
        let val = this.value;
        if (val && this.config.concatValueSelected) {
            const space = val[val.length - 1] == "\n" ? "\n" : " ";
            val = val.trimEnd();
            const lastCharEval = [".", ":", "-"];
            const lastCharEval_LowerCase = [",", ";"];
            const lastCharEvalListing = ["*"];
            const valLastChar = val[val.length - 1];

            if (lastCharEval.includes(valLastChar) || lastCharEvalListing.includes(valLastChar))
                text = val + space + text[0].toUpperCase() + text.substring(1);
            else if (lastCharEval_LowerCase.includes(valLastChar))
                text = val + space + text[0].toLowerCase() + text.substring(1);
            else
                text = val + "." + space + text;
        }

        this.input.property("value", text);
        this.HideOptionsList();
        // this.FocusIn();
        this.Validate();
    }

    public SetDefaultOptions<T = string>(data: T[]): Input & IOptionsConfig<T> {
        this.optionsList = data;
        const res = this as any as Input & IOptionsConfig<T>;
        let callStep: (div: TSelection<"htmlelement", T>) => void;
        let onSelect: (datum: T, div: TSelection<"htmlelement", T>) => void;
        let onSetInputText: (datum: T) => string;
        let minWidth: string;
        let maxWidth: string;
        res.OptionsOnStepItem = (call) => {
            callStep = call;
            return res;
        }
        res.OptionsOnSelect = (call) => {
            onSelect = call;
            return res;
        }
        res.OptionsOnSetInputText = (call) => {
            onSetInputText = call;
            return res;
        }
        res.OptionsMinWidth = (minW) => {
            minWidth = minW;
            return res;
        }
        res.OptionsMaxWidth = (maxW) => {
            maxWidth = maxW;
            return res;
        }
        res.OptionsWidth = (w) => {
            this.optionsListWrapper["__width"] = w;
            return res;
        }

        if (!this.optionsList?.length) {
            this.lblInput.select(".btn_more").remove();
            this.optionsListWrapper?.remove();
            this.optionsListWrapper = null;
            this.HideOptionsList();
            return res;
        }

        const btnMore = this.lblInput.append("div").classed("btn_more", true);
        btnMore.node().onclick = (ev) => {
            ev.stopPropagation(); // Evita que llegue al body
            ev.preventDefault(); // Evita foco en input

            if (this.OptionsListIsVisible())
                this.HideOptionsList();
            else {
                document.body.click(); // Remueve otros List
                this.ShowOptionsList();
            }
        };
        ToImageSvgElement(ic_down, btnMore);

        this.optionsListWrapper = this.lblInput.append("div").attr("class", "cx-list hide");

        setTimeout(() => {
            // REFRESH LIST
            this.optionsListWrapper.style("min-width", minWidth);
            this.optionsListWrapper.style("max-width", maxWidth);
            this.optionsListWrapper.selectAll<HTMLSpanElement, T>(":scope>div")
                .data(data, (_, i) => i)
                .join("div")
                .attr("class", "txt-def-comment")
                .each((d, i, items) => {
                    const itemElem = items[i];

                    if (callStep) callStep(select(itemElem));
                    else itemElem.textContent = d + "";

                    items[i].onclick = e => {
                        e.preventDefault();
                        let textSelected = "";
                        if (onSetInputText)
                            textSelected = onSetInputText(d) || "";
                        else
                            textSelected = d + "";
                        this.OnSelectItemList(textSelected);
                        if (onSelect) onSelect(d, select(itemElem));
                    }
                });
        })
        return res;
    }

    private AddListEvents() {
        if (this["__ev"]) return;
        this["__ev"] = () => {
            if (!this.input.node()?.isConnected) {
                this.RemoveListEvents();
            }
            if (this.OptionsListIsVisible())
                this.HideOptionsList();
        }
        // document.body.addEventListener("keydown", this["__ev"]);
        document.body.addEventListener("click", this["__ev"]);
    }

    private RemoveListEvents() {
        if (!this["__ev"]) return;
        // document.body.removeEventListener("keydown", this["__ev"]);
        document.body.removeEventListener("click", this["__ev"]);
        this["__ev"] = null;
    }

    public OnEnterKey(callback: () => void) {
        this.input.on("keyup", (ev) => {
            if (ev.key === 'Enter' || ev.keyCode === 13) {
                callback();
            }
        });
    }

    public FocusIn() {
        this.input.node()?.focus();
        this.HideOptionsList();
        return this;
    }

    public SetPlaceholder(val: string): Input {
        const spanLabel = this.lblInput.select(".input__label");
        if (spanLabel.size() > 0)
            spanLabel.text(val);
        else
            this.input.attr("placeholder", val)

        return this;
    }

    public OptionsListIsVisible() {
        if (!this.optionsListWrapper) return false;
        return !this.optionsListWrapper.classed("hide");
    }

    public ShowOptionsList(): this {
        if (!this.optionsList?.length) return this;
        if (this.input.node() == document.activeElement) {
            this.input.node().blur();
        }
        this.optionsListWrapper?.classed("hide", false);
        this.ResolvePositionList();
        this.AddListEvents();
        if (IS_MOBILE)
            setTimeout(() => this.ResolvePositionList(), 700); // Ajuste retraso Móviles
        return this;
    }

    public HideOptionsList(): this {
        this.optionsListWrapper?.classed("hide", true);
        this.RemoveListEvents();
        return this;
    }

    private ResolvePositionList(): Input {
        if (!this.optionsListWrapper?.node() || !this.input?.node() || !this.OptionsListIsVisible()) return;
        this.optionsListWrapper.style("height", "");

        const { innerHeight: winHeight } = window;
        const { height: initHeightList } = this.optionsListWrapper.node().getBoundingClientRect();
        const input = this.input.node();
        const txtBounds = input.getBoundingClientRect();
        const separation = input.type == "color" ? 10 : 0;
        const txtFinalY = txtBounds.y + txtBounds.height + separation;
        const limitMargin = 10 + separation;
        const listY2 = txtFinalY + initHeightList + limitMargin;
        const minHeight = 120;

        let top = 0;
        let bottom = 0;
        let height = initHeightList;
        let width = this.optionsListWrapper["__width"] ? this.optionsListWrapper["__width"] : txtBounds.width + "px";

        let toUp = winHeight < listY2;
        if (toUp) {
            let heightA = height - (listY2 - winHeight);
            if (heightA >= minHeight) {
                toUp = false;
                height = heightA;
            }
        }
        if (toUp) {
            bottom = (winHeight - txtBounds.y + separation);
            const listY1 = bottom + height + limitMargin;
            if (listY1 > winHeight) {
                height -= listY1 - winHeight;
            }
        } else {
            top = txtFinalY;
        }

        this.optionsListWrapper
            .style("top", top ? top + "px" : "")
            .style("bottom", bottom ? bottom + "px" : "")
            .style("width", width)
            .style("height", height ? height + "px" : "");

        const widthListBigger = this.optionsListWrapper.node().clientWidth > txtBounds.width;
        if (!input.computedStyleMap || input.type == "color" || input.computedStyleMap().get("border-width").toString() == "0px" || widthListBigger)
            this.optionsListWrapper
                .style("border-top-color", null)
                .style("border-bottom-color", null);
        else
            this.optionsListWrapper
                .style("border-top-color", top ? "transparent" : null)
                .style("border-bottom-color", !top ? "transparent" : null);

        return this;
    }
}