import { apiCalificacionAsignarV2, apiCalificacionObtenerPendientes } from "../API";
import { _APP_DATA } from "../AppData";
import { IApiResult, ICalificacionPendiente } from "../models/Entities";
import { RequestData } from "../utils/RequestData";

export function _LoadPendientes() {
    const params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
    }

    return new Promise<ICalificacionPendiente[]>((resolve, reject) => {
        new RequestData<IApiResult<ICalificacionPendiente[]>>(apiCalificacionObtenerPendientes)
            .Params(params)
            .Completion((response, error) => {
                let resultData = response?.Datos || [];

                if (error) {
                    console.log(error.name, error.message);
                    reject(error);
                    return
                }
                resolve(resultData);
            })
            .Get();
    })
}

type IAsignarCalificacionParam = Partial<Pick<ICalificacionPendiente, "Id" | "IdAsignacion">> & {
    Calificacion: string;
    Observacion: string;
    FechaEval: string;
}
type IAsignarCalificacionResponse = {
    Resultado: number
    IndexFechasInasistencia?: number[]
}
export function _AsignarCalificacion(exTemporal: boolean, idAlumno: number, calificaciones: IAsignarCalificacionParam[]): Promise<IAsignarCalificacionResponse> {
    const ids: number[] = [];
    const valorCalif: string[] = [];
    const observacion: string[] = [];
    const tipo: number[] = [];
    const fechaEval: string[] = [];
    calificaciones.forEach(d => {
        observacion.push(d.Observacion);
        valorCalif.push(d.Calificacion);
        fechaEval.push(d.FechaEval);
        if (d.Id == 0) {
            ids.push(d.IdAsignacion);
            tipo.push(9); // ESTILO LIBRE
        } else {
            ids.push(d.Id);
            tipo.push(0);
        }
    })
    const params = {
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdAlumno: idAlumno,
        ExTemporal: exTemporal,
        Ids: ids,
        ValorCalificacion: valorCalif,
        Observacion: observacion,
        Tipo: tipo,
        FechaEval: fechaEval,
    }
    return new Promise<IAsignarCalificacionResponse>((resolve, reject) => {
        new RequestData<IApiResult<undefined>>(apiCalificacionAsignarV2)
            .Params(params)
            .Completion((response, error) => {
                if (error) {
                    reject(error);
                    return;
                }
                resolve({
                    Resultado: (response?.Resultado || -11),
                    IndexFechasInasistencia: (response as any)?.IndexFechasInasistencia,
                });
            })
            .Post();
    })
}