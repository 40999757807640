import { apiLoadPhoto } from "../API";
import { _mapChilds } from "./Kids";

export enum EPhotoSize {
    NORMAL = 1,
    PREVIEW = 2,
    THUMBNAIL = 3
}

export function _URLChildPhoto(idUsuario: number, sizePhoto: EPhotoSize): string {
    const id = idUsuario + "_" + sizePhoto;

    if (_mapChildUrlPhoto.has(id))
        return _mapChildUrlPhoto.get(id);
    else {
        let _url = urlPhoto(idUsuario, sizePhoto);
        _mapChildUrlPhoto.set(id, _url);
        return _url;
    }
}

export const _mapChildUrlPhoto = new Map();

export function _SetURLPhoto(idUsuario: number) {
    const idThumb = idUsuario + "_" + EPhotoSize.THUMBNAIL;
    const _url = urlPhoto(idUsuario, EPhotoSize.THUMBNAIL) + "&TEMP=" + Date.now();

    _mapChildUrlPhoto.set(idThumb, _url);

    const idNorm = idUsuario + "_" + EPhotoSize.NORMAL;
    if (_mapChildUrlPhoto.has(idNorm)) {
        const _urlNorm = urlPhoto(idUsuario, EPhotoSize.NORMAL) + "&TEMP=" + Date.now();
        _mapChildUrlPhoto.set(idNorm, _urlNorm);
    }

    const idPrev = idUsuario + "_" + EPhotoSize.PREVIEW;
    if (_mapChildUrlPhoto.has(idPrev)) {
        const _urlPrev = urlPhoto(idUsuario, EPhotoSize.PREVIEW) + "&TEMP=" + Date.now();
        _mapChildUrlPhoto.set(idPrev, _urlPrev);
    }
}

const urlPhoto = (idUsuario: number, sizePhoto: EPhotoSize) => {
    return apiLoadPhoto + `?IdUsuario=${idUsuario}&Tamanio=${sizePhoto}`;
}

export const _UpdateURLChildPhoto = (idChild: number) => {
    const objChild = _mapChilds.get(idChild);
    if (objChild) {
        _SetURLPhoto(objChild.IdUsuario);
        const id = objChild.IdUsuario + "_" + EPhotoSize.THUMBNAIL;
        objChild.urlPhoto = _mapChildUrlPhoto.get(id);
    }

}