import '../../../styles/components/ConfirmDialog.scss'
import { Selection, select } from "d3"
import { Button } from "./Button";
import { RadioGroup } from "./RadioButton";
import _L from "../../utils/Labels";

export class ConfirmDialog {

    private divDialog: Selection<HTMLDivElement, any, any, any>;
    private dTexts: Selection<HTMLDivElement, any, any, any>;
    private txtTitle: Selection<HTMLHeadingElement, any, any, any>;

    private radOptions: RadioGroup;

    private OnCancel?: () => void;
    private OnConfirm?: (val: any) => void;

    constructor() {
        this.divDialog = select("body")
            .append("div").classed("dlg-confirm", true);
        this.CreateDialog();
    }

    private CreateDialog() {
        const dialogContainer = this.divDialog.append("div").classed("dialog-container", true);
        this.dTexts = dialogContainer.append("div").classed("text-container", true)
        this.txtTitle = this.dTexts.append("h1").classed("title", true).style("display", "none")
        this.dTexts.append("h2").classed("subtitle", true);

        const footer = dialogContainer.append("div").classed("footer", true);

        new Button(footer)
            .Title(_L("general.cancela"))
            .Classed("btn-dialog-footer left")
            .OnClick(() => {
                this.OnDestroy();

                if (this.OnCancel) this.OnCancel();
            });

        new Button(footer)
            .Title(_L("general.acepta"))
            .Classed("btn-dialog-footer right")
            .OnClick(() => {
                let valSelected: any;
                if (this.radOptions) {
                    valSelected = this.radOptions.GetRadioSelectedInfo();
                    if (!valSelected) return;
                }

                this.OnDestroy();

                if (this.OnConfirm) this.OnConfirm(valSelected);
            });
    }

    public Dismiss() {
        this.divDialog.remove();
    }

    public SetTitle(val: string): ConfirmDialog {
        this.txtTitle.style("display", "block")
            .text(val);

        return this;
    }

    public SetDescription(description: string): ConfirmDialog {
        this.divDialog.select(".dialog-container").select(".subtitle")
            .text(description)
        return this;
    }

    public AddRadioOptions(list: any[], propValue?: string): ConfirmDialog {
        this.dTexts.select(".subtitle").style('display', 'none');

        this.radOptions = new RadioGroup(this.dTexts, "type-comment");
        list.forEach((item, i) => {
            const label = propValue ? item[propValue] : item.label;
            this.radOptions.AddRadioButton()
                .SetIdentifier("" + i)
                .SetLabel(label)
                .SetValue(item);
        });

        return this;
    }

    public SetOnCancel(completion: () => void) {
        this.OnCancel = completion;
    }

    public SetOnConfirm(completion: (val: any) => void) {
        this.OnConfirm = completion;
    }

    public OnDestroy() {
        this.divDialog.remove();
    }

}