import { timeParse } from "d3";
import { apiEnterGroup, apiLoadConfig, apiOutGroup, apiOutKinder, apiRefuseEntry, apiScanQR } from "../API";
import { _APP_DATA } from "../AppData";
import { EEventId, IApiResult, IEvent, IKinder, IResultQR } from "../models/Entities";
import { _EVENTS_CHILD_ORDER } from "../../ui/utils/General";
import { RequestData } from "../utils/RequestData";

export function _EnterGroup(childId: number, callback?: (status: number) => void): void {
    let params = {
        IdAlumno: childId,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdUsuario: _APP_DATA.userData.IdUsuario
    }

    new RequestData<IApiResult<any>>(apiEnterGroup)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _OutGroup(idChild: number, group: number, callback?: (status: number) => void): void {
    let params = {
        IdAlumno: idChild,
        IdGrupoSiguiente: group,
        IdUsuario: _APP_DATA.userData.IdUsuario
    }

    new RequestData<IApiResult<any>>(apiOutGroup)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _OutKinder(childId: number, callback?: (status: number) => void): void {
    let params = {
        IdAlumno: childId,
        IdUsuario: _APP_DATA.userData.IdUsuario
    }

    new RequestData<IApiResult<any>>(apiOutKinder)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _RefuseEntry(childId: number, callback?: (status: number) => void): void {
    let params = {
        IdAlumno: childId,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo
    }

    new RequestData<IApiResult<any>>(apiRefuseEntry)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export var _kinder: IKinder | null;
export var _formatHMS = timeParse("%H:%M:%S");

export function _LoadKinder(callback: () => void) {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder
    }

    new RequestData<IApiResult<IKinder>>(apiLoadConfig)
        .Params(params)
        .Completion((response, error) => {
            const resultData = response?.Datos || null;

            if (error)
                console.log(error.name, error.message);

            _kinder = resultData;

            if (_kinder) {
                if (_kinder.HoraEntrada)
                    _kinder.DTHoraEntrada = _formatHMS(_kinder.HoraEntrada);
                if (_kinder.HoraSalida)
                    _kinder.DTHoraSalida = _formatHMS(_kinder.HoraSalida);
            }

            if (callback) callback();
        }).Get();
}


export function _ScanQRChildInfo(qrValue: string, callback?: (result: IResultQR) => void): void {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        TxtQr: qrValue
    }

    new RequestData<IResultQR>(apiScanQR)
        .Params(params)
        .Completion((response, error) => {
            const result = response || { Resultado: -111, IdCategoria: -1, Descripcion: "" };
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(result);
        }).Post();
}

export async function _GetKinderEventsArray(): Promise<IEvent[]> {
    if (!_kinder) {
        await new Promise<void>((resolve) => _LoadKinder(() => resolve()));
        if (!_kinder) return [];
    }

    return _EVENTS_CHILD_ORDER
        .map(([_, info]) => info)
        .filter(d => {
            const evEVALTipes = [EEventId.MILLESTONE, EEventId.EVALUATION];
            if (evEVALTipes.includes(d.id)) {
                if (_kinder.ModuloEval)
                    return d.id == _kinder.ModuloEval;
                else
                    return d.id == EEventId.MILLESTONE;
            }
            return true;
        })
}

export async function _GetKinderEventsMap(): Promise<Partial<{ [k in EEventId]: IEvent }>> {
    return _GetKinderEventsArray()
        .then(evts => evts.reduce((res, d) => {
            res[d.id] = d;
            return res;
        }, {}))
}

export function _ClearKinderData() {
    _kinder = null;
}