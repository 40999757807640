import { THashPath } from "../../routes/Routes";

export enum EEventId {
    SNOOZE = 1,
    FOOD = 2,
    HYGIENE = 3,
    ACTIVITIES = 4,
    MILLESTONE = 5,
    PHOTOS = 6,
    COMMENTS = 7,
    HUMOR = 8,
    EVALUATION = 11,

    ENTRADA_SALIDA_ESCUELA = 9,
    ENTRADA_SALIDA_GRUPO = 10,
    CHECK_IN = 1000,
    CHECK_OUT = 2000,
    CHAT = 20,
    COVID = 21,

    _ENTRADA_ESCUELA = -999,
    _SALIDA_ESCUELA = -1000,
}

/** @deprecated */
export enum EColors {
    COLOR_ENTER = '#00D75A',
    COLOR_DEPARTURE = '#FF7F77',
    COLOR_SNOOZE = '#44CCFF',
    COLOR_FOOD = '#E82C2C',
    COLOR_HYGIENE = '#3066BE',
    COLOR_ACTIVITIES = '#FFD23F',
    COLOR_WIN = '#D30C7B',
    COLOR_PHOTOS = '#EE6C4D',
    COLOR_COMMENTS = '#3BCEAC',
    COLOR_HUMOR = '#7D3D99',
}

export interface IEvent {
    id: EEventId;
    name: string;
    /** `<svg>...</svg>` */
    iconSVGRaw: string;
    color: string;
    hash: THashPath;
    enabled?: boolean;
}

export interface IEventStateHistory {
    isExtemp: boolean;
    dataChilds: IChild[];
}

export interface IApiResult<T> {
    Resultado: number;
    Datos: T;
    Maxima: any;
    Estado?: number;
    Modificacion?: string;
}

export interface IConfiguration {
    Id: number;
    Tipo: EEventId;
    Modificacion: string;
    Valores: Array<any>;
    Valores2: Array<any>;
    Orden: number;
    EnUso: boolean;
    IdEscolaridad: number;
}

export interface IGroup {
    IdGrupo: number;
    EsPrincipal: boolean;
    HoraFin: string;
    HoraInicio: string;
    IdNivel: number;
    IdEscuela: number;
    NombreGrupo: string;
    NombreNivel: string;

    NMessages: number;
}

export interface IGrupoInfo {
    Id: number;
    Nombre: string;
}

export interface IChild {
    IdChild: number;
    ApMaterno: string;
    ApPaterno: string;
    EnKinder: boolean;
    EnUso: boolean;
    EstadoEvt: number;
    IdFoto: number;
    Modificacion: string;
    Nacimiento: Date;
    Nombre: string;
    Sexo: number;//Femenino = 1, Masculino=2
    GrupoActivo: number;
    IdUsuario: number;
    Entradas: string[];
    Salidas: string[];

    urlPhoto: string;
    unreadMessages?: number;

    get _NombreCompleto(): string;
}

export interface ISchoolHour {
    IdAlumno: number;
    Entrada: Date | null;
    Salida: Date | null;
    Modificacion: string;
    EnUso: boolean;
}

export interface ITodaySchoolHour {
    IdGrupo: number;
    Entrada: Date;
    Grupo: string;
}

export interface IInfoChild {
    Grupos: Array<IInfoGroup>;
    Hermanos: Array<IInfoBrother>;
    IdUsuario: number,
    Matricula: string,
    Nacimiento: string,
    Nombre: string,
    Resultado: number,
    Sexo: number,
    Tutores: Array<IInfoTutor>
}

interface IInfoGroup {
    Grupo: string,
    Id: number,
    Principal: boolean
}

interface IInfoBrother {
    Am: string,
    Ap: string,
    Id: number,
    Nombre: string,
    Sexo: number,
    urlPhoto: string,
    IdUsuario: number
}

interface IInfoTutor {
    Nombre: string,
    Telefono: number,
    IdTutor: number
}

export interface ICalendarEvent {
    Evento: string;
    DeKinder: boolean;
    Inicio: Date;
    Fin: Date;
    Modificacion: string;
    EnUso: boolean;
    IdEvento: number;
}

export enum EStatusSleepChild {
    DESPIERTO = 0
    , DORMIDO = 1
}

export interface ICatalogFood {
    IdComida: number;
    Nombre: string;
    Obligatorio: boolean;
    Orden: number;
    TipoEstado: number;
    Unico: boolean;
    EnUso: boolean;
}

export interface IFoodMenu {
    Categoria: number;
    EnUso: boolean;
    Fin: string;
    /** `ID` */
    IDComida: number;
    /** `IdComida` */
    Identificador: number;
    Inicio: string;
    Nombre: string;
}

// Portions
export enum EFoodPortion {
    _25_P = 0.25
    , _50_P = 0.5
    , _100_P = 1
    , _150_P = 1.5
    , _200_P = 2
    , _300_P = 3
}

export enum FOOD_TYPE_VALUES {
    /** Comida de escuela | calendario */
    COMIDA_CALENDARIO = 1,
    COMIDA_CASA = 2,
    PASTEL = 3
}

// Events
export interface ICommentEvent {
    Id: number;
    IdEvento: number;
    Mensaje: string;
    Categoria: number;
}

export interface IEventChild {
    Categoria: number;
    Comentarios: string;
    Evento: string;
    Fin: Date | null;
    Identificador: number;
    Inicio: Date | null;
    Porcion: string;
    TipoCategoria: number;
    TipoEvento: number;
    IdChild: number;
    IdGrupo: number;

    Nombre: string;//nombre niño
    urlPhoto: string;
    Sexo: number;
}

export interface IChildFoodEvent {
    Categoria: number;
    Identificador: number;
    TipoCategoria: number;
    TipoEvento: number;
    IdChild: number;

    Nombre: string;
    urlPhoto: string;
    Sexo: number;
}

export enum ECategoryHygiene {
    PIPI = 1
    , POPO = 2
    , DIENTES = 3
}

export enum ETypeBath {
    // NINGUNO = 0
    ACCIDENTE = 1
    , INTENTO = 2
    , D_SOLO = 3
    , D_CON_AYUDA = 4
}

export enum EEstadoLogro {
    REGISTRADO = 0
    , INICIADO = 1
    , AVANZADO = 2
    , TERMINADO = 3
}

export interface IAsignacionChildLogro {
    IdAsignacion: number;
    IdLogro: number;
    Estado: number;
    Iniciado: Date | null;
    Avanzado: Date | null;
    Terminado: Date | null;
    EnUso: boolean;

    IdChild: number;
}

export interface ILogro {
    IdLogro: number;
    IdCategoria: number;
    Nombre: string;
    Proceso: number;
    EnUso: boolean;
}

export interface ICategoriaLogro {
    IdCategoria: number;
    Nombre: string;
    EnUso: boolean;
}

export enum ETipoComentario {
    NORMAL = 0
    , IMPORTANTE = 1
    , MEDICINA = 2
}

export enum EHumorID {
    FELIZ = 1,
    SERIO = 2,
    TRISTE = 3,
    TRAVIESO = 4,
    ENOJADO = 5,
    SOMNOLIENTO = 6,
    ENFERMO = 7,
    LLOROSO = 8
}

export interface IResumeEventDetail {
    CategoriaEvento: number;
    Fin: Date;
    IdChild: number;
    Inicio: Date;
    Id: number;
    IdGroup: number;
    TipoEvento: number;
}

export interface IParent {
    ApMaterno: string;
    ApPaterno: string;
    Id: number;
    Nombre: string;
    Telefono: string;
    EnUso: boolean;
    Modificacion: string;
}

export interface IMessage {
    Extension: string;
    Fecha: Date;
    FechaLeido: Date | null;
    IdArchivo: number;
    IdDestino: number;
    IdGrupo: number;
    IdMensaje: number;
    IdOrigen: number;
    Mensaje: string;
    Tipo: number;

    ChildId: number;
    MaxUpdated: string;
    StatusMessage: EStatusMessage;
}

export enum EStatusMessage {
    ENVIADO = 0,
    ENTREGADO = 1,
    LEIDO = 2,
    ERROR = 3
}

export enum EFileType {
    unknown = 0
    , IMAGE = 1
    , PDF = 2
    , WORD = 3
    , PPT = 4
    , EXCEL = 5
}

export interface IKinder {
    Correo: string;
    Direccion: string;
    DTHoraEntrada: Date | null;
    DTHoraSalida: Date | null;
    HoraEntrada: string;
    HoraSalida: string;
    Nombre: string;
    Sabado: boolean;
    Telefono: string;
    TieneSecundario: boolean;
    TipoChat: number;
    BolQr: boolean;
    IdKinder: number;
    ModuloEval: EEventId.MILLESTONE | EEventId.EVALUATION;
}

export interface IUnreadedMessage {
    IdChild: number;
    Mensajes: number;
    UltimaExtension: string;
    UltimoMensaje: string;
    FechaLeido: string;
}

export interface ICommonAddGralEvent {
    titleToast: string,
    // ids?: number[];
    // dtEvnt?: Date;
    evtID: EEventId;
    comment: string;
    eventName?: string;
    category?: number;
    identifier?: number;
}

export interface IResultQR {
    Descripcion: string;
    IdCategoria: number;
    Resultado: number;
}

export enum ECalificacionTipoEval {
    Letras = 1,
    Colores,
    Numeros,
    Cualitativa
}

export interface ICalificacionPendiente {
    Criterio: string;
    /** @example "2023-07-23T17:50:20.22Z" || null*/
    DtCalificarFin: string;
    /** @example "2023-07-23T17:50:20.22Z" || null */
    DtCalificarInicio: string;
    /** @example "2023-07-23T17:50:20.22Z" */
    DtFin: string;
    /** @example "2023-07-23T17:50:20.22Z" */
    DtInicio: string;
    EvalDescripcion: string[];
    EvalNombre: string[];
    EvalTipo: ECalificacionTipoEval;
    /** @example >0 || 0 */
    Id: number;
    IdAsignacion: number;
    IdAlumno: number;
    IdMateria: number;
    Materia: string;
    MinimoAprobar: string;
}

export interface IInfoTeacher {
    IdTeacher: number;
    Nombre: string;
    ApMaterno: string;
    ApPaterno: string;
    Modificacion: string;
} 