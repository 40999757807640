import { apiAddHygiene, apiAlumnoObtenerEventos, apiGetChildsFoodEvents, apiGetEventPhoto, apiLoadEventsSummary, apiLoadFoodToday, apiLoadFoodTypes, apiNewFood, apiRegistrarEventoGeneral, apiRegistrarEventoSiesta, apiUploadPhoto, ref_event_ObtenerComentariosEvento, ref_event_ObtenerEventosCalendario } from "../API";
import { _APP_DATA } from "../AppData";
import { ECategoryHygiene, EEventId, EHumorID, ETypeBath, IApiResult, ICalendarEvent, ICatalogFood, IChildFoodEvent, ICommentEvent, IEventChild, IFoodMenu, IResumeEventDetail } from "../models/Entities";
import { RequestData } from "../utils/RequestData";
import { _mapChilds } from "./Kids";
import { EPhotoSize, _URLChildPhoto } from "./Photo";

import { _BytesToMB } from "../../utils/General";
import _L from "../../utils/Labels";
import ic_enfermo from '/icons/ic_enfermo.svg?raw';
import ic_enojado from '/icons/ic_enojado.svg?raw';
import ic_feliz from '/icons/ic_feliz.svg?raw';
import ic_lloroso from '/icons/ic_lloroso.svg?raw';
import ic_serio from '/icons/ic_serio.svg?raw';
import ic_somnoliento from '/icons/ic_somnoliento.svg?raw';
import ic_travieso from '/icons/ic_travieso.svg?raw';
import ic_triste from '/icons/ic_triste.svg?raw';


export function _LoadEventByChild(childId: number, start: Date, end: Date, callback: (list: Array<IEventChild>, success: boolean) => void): void {
    let params = {
        IdChild: childId
        , Inicio: start.toISOString()
        , Fin: end.toISOString()
    }

    new RequestData<IApiResult<IEventChild[]>>(apiAlumnoObtenerEventos)
        .Params(params)
        .Identifier("_LoadEventByChild")
        .Completion((response, error) => {
            const resultData = response?.Datos || [];
            const success = !error && !!response?.Datos
            if (error)
                console.log(error.name, error.message);

            resultData.forEach((item) => {
                item.IdChild = childId;
                item.Inicio = item.Inicio ? new Date(item.Inicio) : null;
                item.Fin = item.Fin ? new Date(item.Fin) : null;

                const child = _mapChilds.get(childId)
                if (child) {
                    item.Nombre = child.Nombre;
                    item.urlPhoto = _URLChildPhoto(child.IdUsuario, EPhotoSize.THUMBNAIL);
                    item.Sexo = child.Sexo;
                }

            });

            if (callback) callback(resultData, success);
        }).Get();
}

export var _mapCommentByEvent: Map<EEventId, Array<ICommentEvent>> = new Map();
export function _LoadComments() {
    if (_mapCommentByEvent.size > 0 || !_APP_DATA.userData) return;

    let params = {
        IdKinder: _APP_DATA.userData.IdKinder
    }

    new RequestData<IApiResult<any>>(ref_event_ObtenerComentariosEvento)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || {};
            if (error)
                console.log(error.name, error.message);

            for (const k in resultData) {
                _mapCommentByEvent.set(+k, resultData[k]);
            }
        }).Get();
}

/**
 * @param idEvent
 * @param string texto libre
 * @returns idComment || 0
 */
export function _GetIdComment(idEvent: EEventId, comment: string): number {
    if (!comment?.trim())
        return 0;
    return (_mapCommentByEvent.get(idEvent) || [])
        .find(d => d.Mensaje.trim() == comment?.trim())
        ?.Id || 0
}

export function _LoadCalendarEvent(start: Date, end: Date, itemParse: ((item: ICalendarEvent) => void) | null, callback: (list: Array<ICalendarEvent>) => void): void {
    let params = {
        Inicio: start.toISOString()
        , Fin: end.toISOString()
        , Modificacion: null
        , IdGrupo: _APP_DATA.userData.IdGrupo
        , IdKinder: _APP_DATA.userData.IdKinder
    }

    new RequestData<IApiResult<ICalendarEvent[]>>(ref_event_ObtenerEventosCalendario)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Estado : -111;
            if (error)
                console.log(error.name, error.message);

            let resultData: Array<ICalendarEvent> = [];
            if (status == 1 && response) resultData = response.Datos || [];

            resultData.forEach(item => {
                item.Inicio = new Date(item.Inicio);
                item.Fin = new Date(item.Fin);

                if (itemParse) itemParse(item);
            });

            if (callback) callback(resultData);
        }).Get();
}

/**
 * S L E E P   E V E N T
 */

export function _AddEventSleep(childIds: Array<number>, dtStart: Date | null, dtEnd: Date | null, isExtemporal: boolean, callback: (status: number) => void) {
    let params = {
        IdsChild: childIds
        , IdKinder: _APP_DATA.userData.IdKinder
        , IdUsuario: _APP_DATA.userData.IdUsuario
        , IdGrupo: _APP_DATA.userData.IdGrupo
        , Inicio: dtStart ? dtStart.toISOString() : null
        , Fin: dtEnd ? dtEnd.toISOString() : null
        , ExTemporal: isExtemporal
    }

    new RequestData<IApiResult<any>>(apiRegistrarEventoSiesta)
        .Params(params)
        .Completion((response, error) => {
            let status = response && response.Resultado ? response.Resultado : -111;

            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}


/**
 * F O O D    E V E N T
 */

export interface IItemListOptionsFood {
    Id: number;
    Mensaje: string;
}

export var TIPO_BIBERON: Array<IItemListOptionsFood> = [
    { Id: 1, Mensaje: "Agua" }
    , { Id: 2, Mensaje: "Leche" }
    , { Id: 3, Mensaje: "Leche materna" }
];

export var PORCION_BIBERON: Array<IItemListOptionsFood> = [
    { Id: 0, Mensaje: "0 onzas" }
    , { Id: 1, Mensaje: "0.5 onzas" }
    , { Id: 2, Mensaje: "1 onzas" }
    , { Id: 3, Mensaje: "1.5 onzas" }
    , { Id: 4, Mensaje: "2 onzas" }
    , { Id: 5, Mensaje: "2.5 onzas" }
    , { Id: 6, Mensaje: "3 onzas" }
    , { Id: 7, Mensaje: "3.5 onzas" }
    , { Id: 8, Mensaje: "4 onzas" }
    , { Id: 9, Mensaje: "4.5 onzas" }
    , { Id: 10, Mensaje: "5 onzas" }
    , { Id: 11, Mensaje: "5.5 onzas" }
    , { Id: 12, Mensaje: "6 onzas" }
    , { Id: 13, Mensaje: "6.5 onzas" }
    , { Id: 14, Mensaje: "7 onzas" }
    , { Id: 15, Mensaje: "7.5 onzas" }
    , { Id: 16, Mensaje: "8 onzas" }
    , { Id: 17, Mensaje: "8.5 onzas" }
    , { Id: 19, Mensaje: "9 onzas" }
    , { Id: 20, Mensaje: "9.5 onzas" }
    , { Id: 21, Mensaje: "10 onzas" }
];

var maxUpdatedFood: string | null = null;
export var _mapFoodCatalog: Map<number, ICatalogFood> = new Map();

export function _LoadCatalogFoods(callback: (resultData: Array<ICatalogFood>) => void) {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder
        , IdEscolaridad: _APP_DATA.userData.IdNivel
        , Modificacion: maxUpdatedFood
    }

    new RequestData<IApiResult<ICatalogFood[]>>(apiLoadFoodTypes)
        .Params(params)
        .Identifier("_LoadCatalogFoods")
        .Completion((response, error) => {
            let resultData = response ? response.Datos : [];

            if (error)
                console.log(error.name, error.message)

            let maxDate = response?.Maxima;
            if (resultData.length > 0 && maxDate)
                maxUpdatedFood = maxDate

            resultData.sort((a, b) => a.IdComida - b.IdComida);

            resultData.forEach(item => {
                if (!item.EnUso)
                    _mapFoodCatalog.delete(item.IdComida)
                else
                    _mapFoodCatalog.set(item.IdComida, item);
            });

            if (callback) callback(resultData);
        }).Get();
}

// /** `Map<IdComida, ItemComidaCalendario>`
//  * @deprecated use `_mapFoodCalendarMenu` instead
// */
// export var _mapFoodMenu: Map<number, IFoodMenu> = new Map();
type MapFoodCalendarMenuControl = Map<number, Map<number, IFoodMenu>> & {
    getFoodsArray: (idComida: number) => IFoodMenu[]
}
/** `Map<IdComida, Map<IdComidaCal, ItemComidaCalendario>>` */
export var _mapFoodCalendarMenu: MapFoodCalendarMenuControl = (() => {
    const result: MapFoodCalendarMenuControl = new Map() as any;
    result.getFoodsArray = (idComida: number) => {
        const foodsMap = result.get(idComida)
        if (!foodsMap)
            return []
        return Array.from(foodsMap.values())
            .sort((a, b) => new Date(a.Inicio).getTime() - new Date(b.Inicio).getTime())
    }
    return result
})()

var maxUpdatedDayFood: string | null = null;

export function _LoadDayFood(callback: (resultData: Array<IFoodMenu>) => void) {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdEscolaridad: _APP_DATA.userData.IdNivel,
        Modificacion: maxUpdatedDayFood
    }

    new RequestData<IApiResult<IFoodMenu[]>>(apiLoadFoodToday)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || [];
            if (error)
                console.log(error.name, error.message);

            if (resultData.length > 0)
                maxUpdatedDayFood = response?.Maxima || null;

            resultData.forEach(item => {
                let foodsMap = _mapFoodCalendarMenu.get(item.Identificador)
                if (!foodsMap) {
                    foodsMap = new Map()
                    _mapFoodCalendarMenu.set(item.Identificador, foodsMap)
                }
                if (!item.EnUso) {
                    // _mapFoodMenu.delete(item.Identificador);
                    foodsMap.delete(item.IDComida)
                } else {
                    // _mapFoodMenu.set(item.Identificador, item);
                    foodsMap.set(item.IDComida, item)
                }
            });

            if (resultData.length > 0 && callback)
                callback(resultData);
        }).Get();
}

/***
 * FOOD EVENT
 * @param idComentario Solo si es comentario predeterminado (opcional)
 */
export function _AddEventFood(childIds: Array<number>, portion: string, comment: string, idFood: number, typeFood: number | null, dtEvent: Date, food: string, isExtemporal: boolean, idComentario: number, idComidaCalendario: (number | undefined), callback: (result: number) => void) {
    let params = {
        IdAlumnos: childIds,
        Porcion: portion,
        Comentarios: comment || "",
        ComentarioDefault: "",
        IdComida: idFood,
        TipoComida: typeFood,
        FechaEvento: dtEvent ? dtEvent.toISOString() : null,
        Comida: food,
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        ExTemporal: isExtemporal,
        IdComentario: idComentario,
        IdComidaCalendario: idComidaCalendario,
    }

    new RequestData<IApiResult<any>>(apiNewFood)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _LoadChildsFoodEvents(childIds: Array<number>, callback: (resultData: Array<IChildFoodEvent>) => void) {
    let params = {
        IdsChild: childIds,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdOrganizacion: _APP_DATA.userData.IdOrganizacion,
    }

    new RequestData<IApiResult<IChildFoodEvent[]>>(apiGetChildsFoodEvents)
        .Params(params)
        .Identifier("_LoadChildsFoodEvents")
        .Completion((response, err) => {
            const resultData = response?.Datos || [];
            if (err) {
                console.log(err.name, err.message)
            }

            resultData.forEach((item) => {
                const child = _mapChilds.get(item.IdChild)
                if (child) {
                    item.Nombre = child.Nombre;
                    item.urlPhoto = _URLChildPhoto(child.IdUsuario, EPhotoSize.THUMBNAIL);
                    item.Sexo = child.Sexo;
                }
            })

            if (resultData.length > 0 && callback)
                callback(resultData);
        }).Get()
}

/***
 * HYGIENE EVENT
 * @param idComentario Solo si es comentario predeterminado (opcional)
 */
export function _AddHygieneEvent(childIds: Array<number>, comment: string, type: ECategoryHygiene, category: ETypeBath, dtEvent: Date, isExtemporal: boolean, idComentario: number, callback: (result: number) => void) {
    let params = {
        IdsChild: childIds,
        Comentarios: comment,
        TipoHigiene: type,
        CategoriaHigiene: category,
        FechaEvento: dtEvent.toISOString(),
        IdKinder: _APP_DATA.userData.IdKinder,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        ExTemporal: isExtemporal,
        IdComentario: idComentario,
    }

    new RequestData<IApiResult<any>>(apiAddHygiene)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

/***
 * GENERAL EVENT
 * @param idComentario Solo si es comentario predeterminado (opcional)
 */
export function _AddGeneralEvent(childIds: Array<number>, dtStart: Date, dtEnd: Date, evType: EEventId, comment: string, identifier: number | undefined, isExtemporal: boolean, idComentario: number, callback: (resultCode: number) => void, evName?: string | null, category?: number): void {
    let params = {
        IdKinder: _APP_DATA.userData.IdKinder
        , IdUsuario: _APP_DATA.userData.IdUsuario
        , IdGrupo: _APP_DATA.userData.IdGrupo
        , IdsChild: childIds
        , Inicio: dtStart.toISOString()
        , Fin: dtEnd.toISOString()
        , TipoEvento: evType
        , Categoria: category
        , Comentarios: comment
        , NombreEvento: evName
        , Identificador: identifier
        , ExTemporal: isExtemporal
        , IdComentario: idComentario
    }

    new RequestData<IApiResult<any>>(apiRegistrarEventoGeneral)
        .Params(params)
        .Completion((response, error) => {
            let status = response && response.Resultado ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _ResumeEvents(ids: Array<number>, dateInit: Date, dateEnd: Date, callback: (result: Map<EEventId, Array<IResumeEventDetail>>) => void) {
    let params = {
        IdsChild: ids,
        Inicio: dateInit.toISOString(),
        Fin: dateEnd.toISOString(),
        IdGrupo: _APP_DATA.userData.IdGrupo
    }

    new RequestData<IApiResult<any>>(apiLoadEventsSummary)
        .Params(params)
        .Completion((response, error) => {
            let status = response && response.Resultado ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            let resultData: Array<IResumeEventDetail> = [];
            if (status == 1) resultData = response?.Datos || [];

            let mapResumeDetail: Map<EEventId, Array<IResumeEventDetail>> = new Map();
            resultData.forEach(item => {
                var events: Array<IResumeEventDetail> | undefined = mapResumeDetail.get(item.TipoEvento);
                if (!events) mapResumeDetail.set(item.TipoEvento, events = []);

                item.Inicio = new Date(item.Inicio);
                item.Fin = new Date(item.Fin);

                events.push(item);
                mapResumeDetail.set(item.TipoEvento, events);
            });

            if (callback) callback(mapResumeDetail);
        }).Get();
}


/**
 *   P H O T O S   E V E N T 
 */
export function _Uploadphoto(childs: Array<number>, file: File, dtEvent: Date, isExtemporal: boolean, callback: (STATUS: number) => void): void {
    let params = {
        IdsAlumno: childs,
        Foto: file,
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        FechaEvento: dtEvent.toISOString(),
        ExTemporal: isExtemporal
    }

    new RequestData<IApiResult<any>>(apiUploadPhoto)
        .Params(params)
        .SetTags("registrar_foto")
        .ExtraInfoInErrorBotMessage(`${file?.name}, ${file?.type}, ${_BytesToMB(file?.size || 0)} MB`)
        .Completion((response, _) => {
            let status = response ? response.Resultado : -111;

            if (status <= 0)
                console.log("Ocurrio un error al subir foto: ", response);

            if (callback) callback(status);
        }).FORM();
}

export function _GetEventPhotoURL(idFoto: number, size: number, fechaFoto: string): string {
    return apiGetEventPhoto + `?IdFoto=${idFoto}&Tamanio=${size}&FechaFoto=${fechaFoto}`
}

export function _GetEventPhoto(idFoto: number, size: number, fechaFoto: string): Promise<Blob> {
    const params = {
        "IdFoto": idFoto,
        "Tamanio": size,
        "FechaFoto": fechaFoto,
    }
    return new Promise((resolve, reject) =>
        new RequestData<Blob>(apiGetEventPhoto)
            .Params(params)
            .Completion((response, _) => {
                if (!response || !response.size) reject();
                resolve(response);
            })
            .Blob()
    )
}

/**
 *   H U M O R   E V E N T
 */
export const _mapHumorValues = new Map<EHumorID, { idEvent: EHumorID, label: string, iconSVGRaw: string, }>([
    [EHumorID.FELIZ, { idEvent: EHumorID.FELIZ, iconSVGRaw: ic_feliz, label: _L("c_humor.1") }],
    [EHumorID.SERIO, { idEvent: EHumorID.SERIO, iconSVGRaw: ic_serio, label: _L("c_humor.2") }],
    [EHumorID.TRISTE, { idEvent: EHumorID.TRISTE, iconSVGRaw: ic_triste, label: _L("c_humor.3") }],
    [EHumorID.TRAVIESO, { idEvent: EHumorID.TRAVIESO, iconSVGRaw: ic_travieso, label: _L("c_humor.4") }],
    [EHumorID.ENOJADO, { idEvent: EHumorID.ENOJADO, iconSVGRaw: ic_enojado, label: _L("c_humor.5") }],
    [EHumorID.SOMNOLIENTO, { idEvent: EHumorID.SOMNOLIENTO, iconSVGRaw: ic_somnoliento, label: _L("c_humor.6") }],
    [EHumorID.ENFERMO, { idEvent: EHumorID.ENFERMO, iconSVGRaw: ic_enfermo, label: _L("c_humor.7") }],
    [EHumorID.LLOROSO, { idEvent: EHumorID.LLOROSO, iconSVGRaw: ic_lloroso, label: _L("c_humor.8") }]
]);

export function _ClearEventsData() {
    _mapCommentByEvent.clear();
    maxUpdatedFood = null;
    _mapFoodCatalog.clear();
    maxUpdatedDayFood = null;
    // _mapFoodMenu.clear();
    _mapFoodCalendarMenu.clear();
}