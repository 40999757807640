import { Selection, ascending, select } from "d3";
import "../../../styles/pages/Event-food.scss";
import { EEventId, FOOD_TYPE_VALUES, ICatalogFood, IChildFoodEvent } from "../../data/models/Entities";
import { _mapConfiguration } from "../../data/services/Configuration";
import { IItemListOptionsFood, PORCION_BIBERON, TIPO_BIBERON, _AddEventFood, _GetIdComment, _LoadCatalogFoods, _LoadChildsFoodEvents, _LoadDayFood, _mapCommentByEvent, _mapFoodCalendarMenu, _mapFoodCatalog } from "../../data/services/Event";
import { _GetDateFromInputHour } from "../../data/utils/General";
import { EMAbortController } from "../../data/utils/RequestData";
import { BotSendWarn } from "../../utils/AlertBot";
import { _TimeToInputFmt } from "../../utils/General";
import _L, { _HttpMsg } from "../../utils/Labels";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { CheckBox } from "../components/CheckBox";
import { Input } from "../components/Input";
import { LOADING } from "../components/Loading";
import { RadioButton, RadioGroup } from "../components/RadioButton";
import { ShowToast } from "../components/Toast";
import { IPortionFood as IPF, _CreateElementFromHTML, _mapFoodPortions } from "../utils/General";


interface IItemBabyContent {
    label: string;
    id: number;
    controlTime?: Selection<HTMLInputElement, any, any, any>;
    checkMain?: CheckBox;
    input1: { placeHolder: string, dataList: any[], control?: Input };
    input2: { placeHolder: string, dataList: any[], control?: Input };
    inputComment: { placeHolder: string, control?: Input };
}

interface ICatgFoodChilds extends ICatalogFood { childs?: Array<IChildFoodEvent> };

interface IPortionFood extends IPF {
    selected: boolean
}

interface ITypeFoodInfo {
    Type: FOOD_TYPE_VALUES,
    IdComidaCalendar?: number,
}

export class EventFood extends BaseUIEvent {

    private listPortionsFood: IPortionFood[];
    private headerCatalogFoods: Selection<HTMLDivElement, any, any, any>;
    private itemsFoodContainer: Selection<HTMLDivElement, any, any, any>;
    private portionsContainer: Selection<HTMLDivElement, any, any, any>;
    private divListFoodChilds: Selection<HTMLDivElement, any, any, any>;

    private txtTime?: Selection<HTMLInputElement, any, any, any>;
    private txtComment?: Input;

    private grpsTypeFood?: RadioGroup<ITypeFoodInfo>;

    private dataListTab: ICatalogFood[];
    private tabSelected: ICatalogFood;
    private listBabyContents: Array<IItemBabyContent>;
    private listCatgFoodChilds: Map<number, ICatgFoodChilds> = new Map();
    private radioSchoolFood: RadioButton<ITypeFoodInfo>;
    private radioCalendarFoods: RadioButton<ITypeFoodInfo>[];

    private listChildFoodEvents: IChildFoodEvent[];

    constructor() {
        super('ui-event-food', EEventId.FOOD);

        LOADING.Show();
        this.headerCatalogFoods = this.bodyContainer.append("div").classed("header-tabs", true);
        this.itemsFoodContainer = this.bodyContainer.append("div").classed("items-food-container", true);

        this.listPortionsFood = Array.from(_mapFoodPortions.values()).map(d => ({ ...d, selected: false }));
        this.listChildFoodEvents = [];
        this.radioCalendarFoods = [];

        this.SetTabCatalogFoods();
        this.AddFoodItems();
        this.AddSaveBtn();

        this.LoadData();
    }

    LoadData() {
        if (_mapFoodCatalog.size > 0) LOADING.Dismiss();

        this.LoadChildEventFood();
        _LoadCatalogFoods((res) => {
            if (!res.length) return;
            LOADING.Dismiss();

            this.SetTabCatalogFoods();
            this.SetSelectedTab();

            this.ListChildEventFoodValidation(true);
        });

        _LoadDayFood((_) => {
            this.RefreshRadioCalendarFood();
        });
    }


    private SetTabCatalogFoods() {
        const foodConfig = [..._mapConfiguration.values()].find(({ Tipo }) => (Tipo == EEventId.FOOD));
        let listCatFood = Array.from(_mapFoodCatalog.values())
        if (foodConfig) {
            const tabOrden = foodConfig.Valores;
            listCatFood.sort((a, b) => ((tabOrden.indexOf(a.Nombre)) - tabOrden.indexOf(b.Nombre)));
        }
        this.dataListTab = [];
        if (listCatFood.length == 0) return;

        let groupData: Array<ICatalogFood[]> = [[], []];
        listCatFood.forEach(item => {
            if (item.Obligatorio) groupData[0].push(item);
            else groupData[1].push(item);
        });

        let obligatory = groupData[0] || [];
        this.dataListTab = groupData[1] || [];

        //Biberón/papilla
        let hasBaby = obligatory.length > 0;
        this.listBabyContents = [];

        if (hasBaby) {
            obligatory.sort((a, b) => ascending(a.Nombre, b.Nombre));
            for (let a = 0; a < obligatory.length; a++) {
                let item = obligatory[a];

                if (item.TipoEstado === 2) {
                    this.listBabyContents.push(this.CreateItemBaby(item.IdComida, item.Nombre, _L("food.tipo_required"), _L("food.porcion_required"), TIPO_BIBERON, PORCION_BIBERON));
                } else if (item.TipoEstado === 1) {
                    this.listBabyContents.push(this.CreateItemBaby(item.IdComida, item.Nombre, _L("food.tipo_pap"), _L("food.porcion"), null, null));
                }
            }

            this.dataListTab.push({ IdComida: -1, Nombre: _L("food.bib/pap") } as any);
        }

        this.tabSelected = this.dataListTab[0];
        const foodNames = this.headerCatalogFoods.selectAll<HTMLDivElement, any>(".item-tab-name").data(this.dataListTab);

        const self = this;
        foodNames.exit().remove();
        foodNames.enter().append("div")
            .classed("item-tab-name", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("span").classed("name-food", true);
            })
            .merge(foodNames)
            .on("click", function (_, d) {
                self.headerCatalogFoods.selectAll(".item-tab-name.active").classed("active", false);
                select(this).classed("active", true);
                self.tabSelected = d;

                self.SetSelectedTab();
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.select(".name-food").text(datum.Nombre);
                if (datum.IdComida == this.tabSelected.IdComida)
                    elemnt.classed("active", true)
            });

        ///DataListTab
        this.dataListTab.forEach(item => {
            if (item.IdComida !== -1) {
                const itmTemp = item as ICatgFoodChilds;
                itmTemp.childs = [];
                this.listCatgFoodChilds.set(item.IdComida, item);
            }
        });
    }

    private CreateItemBaby(id: number, title: string, placeholderType: string, placeholderPortion: string, types: IItemListOptionsFood[] | null, portions: IItemListOptionsFood[] | null): IItemBabyContent {
        types = types || [];
        portions = portions || [];
        return {
            id: id,
            label: title,
            input1: { placeHolder: placeholderType, dataList: types.map(o => o.Mensaje) },
            input2: { placeHolder: placeholderPortion, dataList: portions.map(o => o.Mensaje) },
            inputComment: { placeHolder: `${_L("general.comentario")} (${_L("general.optional").toLowerCase()})` }
        }
    }

    private LoadChildEventFood() {
        if (this.IS_DESTROYED) return;
        _LoadChildsFoodEvents(this.childList.map(d => d.IdChild), (list) => {
            this.listChildFoodEvents = list;
            this.ListChildEventFoodValidation();
        })
    }

    private ListChildEventFoodValidation(sendBotAlert = false) {
        for (const eEvento of this.listChildFoodEvents) {
            const categoria = this.listCatgFoodChilds.get(eEvento.Categoria);
            if (!categoria) {
                if (eEvento.TipoCategoria > 0) { // No es Biberon/Papilla
                    if (sendBotAlert) BotSendWarn("FoodCategoryNoFound", `IdChildReq ${eEvento?.IdChild}\n` + JSON.stringify(eEvento, undefined, 2))
                    console.warn("FoodCategoryNoFound", eEvento, this.listCatgFoodChilds);
                }
                continue;
            }
            const childHasEv = categoria.childs.find(d => d.IdChild == eEvento.IdChild && d.Identificador == eEvento.Identificador && d.Categoria == eEvento.Categoria);
            if (categoria && !childHasEv)
                categoria.childs?.push(eEvento);
        }

        this.UpdateChildsFood();
        this.EvalChildSelection();
    }

    private AddFoodItems() {
        const foodAndType = this.itemsFoodContainer.append("div").classed("food-and-type", true);
        const divComment = this.itemsFoodContainer.append("div").classed("d-comment", true);
        this.divListFoodChilds = this.itemsFoodContainer.append("div").classed("d-list-food-child", true);

        const typesFood = foodAndType.append("div").classed("check-types", true);

        this.grpsTypeFood = new RadioGroup(typesFood, "type-food");

        this.radioSchoolFood = this.grpsTypeFood.AddRadioButton().SetIdentifier('food_school')
            .SetLabel(_L("food.school_food"))
            .SetValue({ Type: FOOD_TYPE_VALUES.COMIDA_CALENDARIO })
            .OnChange(() => this.OnChangeFoodType(FOOD_TYPE_VALUES.COMIDA_CALENDARIO));

        this.grpsTypeFood.AddRadioButton().SetIdentifier('food_house')
            .SetLabel(_L("food.food_house"))
            .SetValue({ Type: FOOD_TYPE_VALUES.COMIDA_CASA })
            .OnChange(() => this.OnChangeFoodType(FOOD_TYPE_VALUES.COMIDA_CASA));
        this.grpsTypeFood.AddRadioButton().SetIdentifier('cake')
            .SetLabel(_L("food.cake"))
            .SetValue({ Type: FOOD_TYPE_VALUES.PASTEL })
            .OnChange(() => this.OnChangeFoodType(FOOD_TYPE_VALUES.PASTEL));

        const timeAndPortions = foodAndType.append("div").attr("class", "time-and-portions")
        this.txtTime = timeAndPortions.append("input").attr("type", "time").classed("time", true)
        this.txtTime.attr("value", _TimeToInputFmt());

        this.portionsContainer = timeAndPortions.append("div").classed("portions-items-food", true);
        this.DrawPortions();

        this.RefreshRadioCalendarFood();

        const listCommentFood = _mapCommentByEvent.get(EEventId.FOOD) || [];
        const commentsDefualt = listCommentFood.map(o => o.Mensaje);
        this.txtComment = new Input(divComment, { concatValueSelected: true })
            .SetPlaceholder(_L("general.comentario"))
            .SetDefaultOptions(commentsDefualt)

        this.UpdateChildsFood();
    }

    private OnChangeFoodType(type: FOOD_TYPE_VALUES) {
        this.portionsContainer.selectAll(".item-portion-food.active").classed("active", false);

        if (type == FOOD_TYPE_VALUES.COMIDA_CASA)
            this.listPortionsFood.map(o => {
                o.enabled = o.portion < 1.5
                if (!o.enabled && o.selected) {
                    o.selected = false
                }
            });
        else
            this.listPortionsFood.map(o => o.enabled = true);

        this.DrawPortions();
    }

    private RefreshRadioCalendarFood() {
        const lastValueSelected = this.grpsTypeFood.GetRadioSelectedInfo()
        this.radioCalendarFoods.forEach(radio => {
            this.grpsTypeFood._RemoveRadioButton(radio)
        })
        this.radioCalendarFoods = []
        if (!this.tabSelected) {
            // this.radioSchoolFood.element.classed("hide", true);
            return
        }
        const calendarFoodsMap = _mapFoodCalendarMenu.getFoodsArray(this.tabSelected.IdComida)
        if (!calendarFoodsMap?.length) {
            this.radioSchoolFood.element.classed("hide", false);
            if (!this.grpsTypeFood.GetRadioSelectedInfo()) {
                this.radioSchoolFood.Checked(true)
                this.OnChangeFoodType(this.radioSchoolFood.GetValue().Type)
            }
            return
        }
        this.radioSchoolFood.element.classed("hide", true);
        calendarFoodsMap.forEach(c => {
            const foodTypeComidaCalendario = FOOD_TYPE_VALUES.COMIDA_CALENDARIO
            const newRadio = this.grpsTypeFood.AddRadioButton()
                .SetLabel(_L("food.cal_food") + ": " + c.Nombre)
                .SetIdentifier("calendar_food_" + c.IDComida)
                .SetValue({ Type: foodTypeComidaCalendario, IdComidaCalendar: c.IDComida })
                .OnChange(() => this.OnChangeFoodType(foodTypeComidaCalendario));
            this.radioCalendarFoods.push(newRadio)
            if (lastValueSelected?.IdComidaCalendar == c.IDComida) {
                newRadio.Checked(true)
                this.OnChangeFoodType(newRadio.GetValue().Type)
            }
        })
        this.grpsTypeFood._GetRadioButton('food_house')?.element.raise()
        this.grpsTypeFood._GetRadioButton('cake')?.element.raise()
    }

    private DrawPortions() {
        const foodNames = this.portionsContainer.selectAll<HTMLDivElement, any>(".item-portion-food").data(this.listPortionsFood);

        const self = this;
        foodNames.exit().remove();
        foodNames.enter().append("div")
            .classed("item-portion-food", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("img").classed("ic_size", true)
                    .classed("img-size-food", true);
            })
            .merge(foodNames)
            .on("click", function (_, datum) {
                if (!datum.enabled) return;

                self.listPortionsFood.forEach(f => { f.selected = false })
                datum.selected = true
                self.portionsContainer.selectAll(".item-portion-food.active").classed("active", false)
                select(this).classed("active", true);
            })
            .each((datum, i, divs) => {
                select(divs[i])
                    .classed("disabled", !datum.enabled)
                    .classed("active", datum.selected)
                    .html("")
                    .append(() => _CreateElementFromHTML(datum.iconSVGRaw));
            });
    }


    private AddBabiesItems() {
        for (let i = 0; i < this.listBabyContents.length; i++) {
            const item = this.listBabyContents[i];
            const itemBabyFood = this.itemsFoodContainer.append("div").classed("items-baby-food", true);
            itemBabyFood.append("h2").text(item.label);

            const divTimeCheck = itemBabyFood.append("div").classed("d-time-check", true);
            item.checkMain = new CheckBox(divTimeCheck)
                .SetIdentifier("chk-1")
                .SetValue((i + 1));

            item.controlTime = divTimeCheck.append("input").attr("type", "time").property("value", _TimeToInputFmt());

            item.input1.control = new Input(divTimeCheck, { placeholder: item.input1.placeHolder, inputType: 'text' })
            if (item.input1.dataList.length > 0)
                item.input1.control.SetDefaultOptions(item.input1.dataList);

            item.input2.control = new Input(itemBabyFood, { placeholder: item.input2.placeHolder, inputType: 'text' })
            if (item.input2.dataList.length > 0)
                item.input2.control.SetDefaultOptions(item.input2.dataList);

            item.inputComment.control = new Input(itemBabyFood, { placeholder: item.inputComment.placeHolder, inputType: 'text' })
        }

    }

    private SetSelectedTab() {
        if (!this.tabSelected) return;
        this.itemsFoodContainer.selectAll("*").remove();
        this.EvalChildSelection();

        if (this.tabSelected.IdComida == -1) {//BEBEs
            this.AddBabiesItems();
        } else {
            this.AddFoodItems();
        }
    }

    private EvalChildSelection() {
        let eCategoria = !!this.tabSelected ? this.listCatgFoodChilds.get(this.tabSelected.IdComida) : null; // ?? idcomida?
        let childIds = eCategoria ? eCategoria.childs.map((d) => d.IdChild) : [];

        this.ToggleSelectValChilds(true, ...this.childList.map(d => d.IdChild));
        this.ToggleSelectValChilds(false, ...childIds);

        const enableEv = this.GetSelectedChilds().length > 0;

        this.ToggleEnableSaveBtn(enableEv);
    }

    private UpdateChildsFood() {
        const listCatalogByChild = Array.from(this.listCatgFoodChilds.values());
        const foodNames = this.divListFoodChilds.selectAll<HTMLDivElement, any>(".ls-food-name").data(listCatalogByChild);

        foodNames.exit().remove();
        foodNames.enter().append("div")
            .classed("ls-food-name", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("span").classed("name-food", true);
                elemnt.append("div").classed("items-child", true)
            })
            .merge(foodNames)
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                let name = datum.Nombre + ": ";
                if (!datum.childs || datum.childs.length == 0) {
                    name += _L("food.no_alumnorecsel");
                    elemnt.select(".items-child").selectAll("*").remove();
                } else {
                    this.CreateListChild(elemnt.select(".items-child"), datum.childs as any);
                }

                elemnt.select(".name-food").text(name);
            });
    }

    protected OnSaveClick(): void {
        if (!this.tabSelected) return;

        if (this.tabSelected.IdComida === -1) {//BABIES
            let someIsCheck = false;
            for (let i = 0; i < this.listBabyContents.length; i++) {
                const item = this.listBabyContents[i];
                if (!item.checkMain?.IsChecked()) continue;
                someIsCheck = true;

                if (!this.EvaluateTime(item.controlTime, item.label)) return;

                if (!item.input1.control.Validate()) return;
                const hasValue = item.input1.control?.value != "";
                if (!hasValue) {
                    // ShowToast(_L("food.title"), "Seleccionar el tipo " + item.label, "info");
                    ShowToast(_L("food.title"), `${item.label}: ${_L("food.tipo_required")}`, "info");
                    return;
                }

                if (!item.input2.control.Validate()) return;
                const hasPortion = item.input2.control?.value != "";
                if (!hasPortion) {
                    // ShowToast(_L("food.title"), "Seleccionar porción " + item.label, "info");
                    ShowToast(_L("food.title"), `${item.label}: ${_L("food.porcion_required")}`, "info");
                    return;
                }
            }

            if (!someIsCheck) {
                ShowToast(_L("food.title"), _L("food.tbib/pall_required"), "info");
                return;
            }

        } else {
            const radSelected = this.grpsTypeFood?.GetRadioSelectedInfo();
            if (!radSelected) {
                ShowToast(_L("food.title"), _L("food.tcomida_required"), 'info');
                return;
            }

            if (!this.EvaluateTime(this.txtTime)) {
                return
            };

            const portionFood = this.portionsContainer.select(".item-portion-food.active");
            const portionSelected = portionFood.size() > 0 ? portionFood.datum() : null;
            if (!portionSelected) {
                ShowToast(_L("food.title"), _L("food.porcion_required"), "info");
                return;
            }
        }


        // if (CONFIRM_FOOD_IDS_KINDER.indexOf(APP_DATA.userData.IdKinder) > -1) {
        //     new ConfirmDialog()
        //         .SetDescription(_L("food.confirm_reg", this.tabSelected.Nombre))
        //         .SetOnConfirm(() => {
        //             this.RegisterFood();
        //         });
        // } else
        this.RegisterFood();
    }

    private EvaluateTime(inptTime?: Selection<HTMLInputElement, any, any, any>, addMsg?: string): boolean {
        const timeEvent = _GetDateFromInputHour(inptTime)
        if (!timeEvent) {
            ShowToast(_L("food.title"), addMsg ? _L("food.hour_required_to", addMsg) : _L("food.hour_required"), 'info');
            return false;
        } else if (new Date().getTime() < timeEvent.getTime()) {
            ShowToast(_L("food.title"), _L("food.hour_menor"), "info");
            return false;
        }

        return true;
    }

    private RegisterFood() {
        let ids = this.GetSelectedChilds().map(o => o.IdChild);

        if (!ids.length) {
            ShowToast(_L("food.title"), _L("food.childs_novalid"), "warn");
            return
        }

        if (this.tabSelected.IdComida != -1) {
            if (!this.txtComment.Validate()) return;
            const commentDefault = this.txtComment?.value || "";
            const idComment = _GetIdComment(EEventId.FOOD, commentDefault);

            const typeFood = this.grpsTypeFood?.GetRadioSelectedInfo();
            const portionSelected = this.portionsContainer.select(".item-portion-food.active").datum();

            let time = this.txtTime?.node()?.value;
            const [hr, min] = time?.split(':') || [0, 0];
            const timeEvent = new Date();
            timeEvent.setHours(+hr, +min);

            LOADING.Show();
            _AddEventFood(ids
                , "" + portionSelected.portion
                , commentDefault
                , this.tabSelected.IdComida
                , typeFood.Type
                , timeEvent
                , ""
                , this.IS_EXTEMPORAL
                , idComment
                , typeFood.IdComidaCalendar
                , (result) => this.OnResponseAddEvent(result, true));
        } else {//Biberon...
            let items = this.listBabyContents.filter(item => item.checkMain?.IsChecked());

            for (let i = 0; i < items.length; i++) {
                let item = items[i];

                let time = item.controlTime?.node()?.value;
                const [hr, min] = time?.split(':') || [0, 0];
                const dtEvent = new Date();
                dtEvent.setHours(+hr, +min);

                if (!item.input1.control.Validate() || !item.input2.control.Validate() || !item.inputComment.control.Validate()) {
                    return
                }

                let food = item.input1.control?.value || "";
                let portion = item.input2.control?.value || "";
                let comment = item.inputComment.control?.value || "";

                LOADING.Show();
                _AddEventFood(ids
                    , portion
                    , comment
                    , item.id
                    , null
                    , dtEvent
                    , food
                    , this.IS_EXTEMPORAL
                    , 0
                    , undefined
                    , (result) => this.OnResponseAddEvent(result, i == items.length - 1));
            }
        }
    }

    private OnResponseAddEvent(status: number, exit: boolean) {
        if (status === 1) {
            ShowToast(_L("food.title"), _HttpMsg("evento/RegistrarAlimento", status, "record"));
            if (exit) {
                LOADING.Dismiss();
                history.back();
            }
        } else {
            LOADING.Dismiss();
            const foodError = [-2, -3, -4, -5];
            if (foodError.includes(status))
                ShowToast(_L("food.title"), _HttpMsg("evento/RegistrarAlimento", status, "record"), "error");
            else
                this.EventComunError(status, _L("food.title"));
        }
    }

    protected RefreshChildHeader() {
        super.RefreshChildHeader();

        //Actualizar lista niños comida
        if (!this.childList || this.childList.length == 0 || !this.listCatgFoodChilds) return;

        this.listCatgFoodChilds.forEach((ctgFood) => {
            const nwChlds: IChildFoodEvent[] = [];
            ctgFood.childs.forEach(item => {
                const idx = this.childList.findIndex(o => o.IdChild == item.IdChild)
                if (idx > -1) nwChlds.push(item);
            });

            ctgFood.childs = nwChlds;
        });


        this.UpdateChildsFood();
    }

    public OnDestroy(): void {
        super.OnDestroy();

        EMAbortController.AbortByTag('_LoadCatalogFoods');
        EMAbortController.AbortByTag('_LoadChildsFoodEvents');
    }

}