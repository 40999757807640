import { apiConfig } from "../API";
import { IApiResult, IConfiguration } from "../models/Entities";
import { RequestData } from "../utils/RequestData";

export var _mapConfiguration: Map<number, IConfiguration> = new Map();
var maxDateConfiguration: string | null = null;

export function _LoadConfig(idEscolaridad: number, callback: (status: number, result: Array<IConfiguration>) => void, resetMaxDate: boolean = false) {
    if (resetMaxDate) maxDateConfiguration = null;

    let params = {
        IdEscolaridad: idEscolaridad,
        Modificacion: maxDateConfiguration
    }

    if (maxDateConfiguration == null)
        _mapConfiguration.clear();

    new RequestData<IApiResult<IConfiguration[]>>(apiConfig)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;

            if (error)
                console.log(error?.name, error?.message);

            const resultData = response?.Datos || [];
            resultData.forEach(item => {
                if (!item.EnUso)
                    _mapConfiguration.delete(item.Id)
                else
                    _mapConfiguration.set(item.Id, item);
            });

            let maxDate = response?.Maxima || null;
            if (resultData.length > 0 && maxDate != null)
                maxDateConfiguration = maxDate;

            if (callback) callback(status, resultData);
        }).Get();
}