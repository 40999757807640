import { Selection } from "d3";
import { _CreateElementFromHTML } from "../utils/General";


export class RadioGroup<Value = any> {

    public element: Selection<HTMLDivElement, any, any, any>;
    private idGroup: string;
    private setRadios: Set<RadioButton<Value>>;

    constructor(container: Selection<HTMLDivElement, any, any, any>, idGroup?: string) {
        this.element = container.append("div").classed("radio-group", true);
        this.idGroup = idGroup || "" + Date.now();
        this.setRadios = new Set();
    }

    public AddRadioButton(): RadioButton<Value> {
        const rdo = new RadioButton(this.element)
            .SetName(this.idGroup);

        this.setRadios.add(rdo);

        return rdo;
    }

    public _RemoveRadioButton(radioBtn: RadioButton<Value>): void {
        if (!radioBtn || !this.setRadios.has(radioBtn)) return
        radioBtn.Checked(false)
        radioBtn.element.remove()
        this.setRadios.delete(radioBtn)
    }

    public _RemoveRadioButtonById(identifier: string): void {
        let radioBtnToRemove: RadioButton
        for (const radio of this.setRadios) {
            if (radio.GetIdentifier() == identifier) {
                radioBtnToRemove = radio
                break
            }
        }
        this._RemoveRadioButton(radioBtnToRemove)
    }

    public GetRadioSelectedInfo(): Value | null {
        // for (let i = 0; i < this.setRadios.length; i++) {
        for (const item of this.setRadios) {
            // const item = this.setRadios[i];
            if (item.IsChecked()) return item.GetValue();
        }
    }

    public _GetRadioButton(identifier: string): RadioButton<Value> | null {
        return Array.from(this.setRadios).find(radio => radio.GetIdentifier() == identifier)
    }
}

export class RadioButton<Value = any> {

    public element: Selection<HTMLDivElement, any, any, any>;
    private inputCheck: Selection<HTMLInputElement, any, any, any>;
    private label: Selection<HTMLLabelElement, any, any, any>;


    constructor(container: Selection<HTMLDivElement, any, any, any>) {
        this.element = container.append("div").classed("radiobutton-item", true)
        this.inputCheck = this.element.append("input")
            .attr("type", "radio")

        this.label = this.element.append("label")

    }

    public GetIdentifier(): string {
        return this.inputCheck.attr("id")
    }

    public SetIdentifier(id: string): this {
        this.inputCheck.attr("id", id);
        this.label.attr("for", id)

        return this;
    }

    public SetName(name: string): this {
        this.inputCheck.attr("id", name)
            .attr("name", name)

        this.label.attr("for", name)

        return this;
    }

    public SetLabel(label: string): this {
        this.label.text(label);
        return this;
    }

    public IsChecked(): boolean {
        return !!this.inputCheck.node()?.checked;
    }

    public Checked(val: boolean): this {
        this.inputCheck.node().checked = val;
        return this;
    }

    public SetValue(val: Value): this {
        this.inputCheck.datum(val);
        return this;
    }

    public AddIcon(icon: string): this {
        this.element.insert("img", "label").attr("src", icon)
            .on("click", () => {
                this.inputCheck.node()?.click();
            });

        return this;
    }

    public AddIconSvg(icon: string): this {
        this.element.insert("div", "label")
            .classed("radiobutton-icon", true)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(icon))
                    .style("height", "100%")
                    .style("width", "100%");
            })
            .on("click", () => {
                this.inputCheck.node()?.click();
            });

        return this;
    }

    public GetValue(): Value {
        return this.inputCheck.datum();
    }

    public OnChange(callback: () => void): this {
        this.inputCheck.on("change", () => callback());
        return this;
    }

}