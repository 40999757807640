import { Base } from "../ui/bases/Base";
import { THashPath } from "./Routes";
import { UIs } from "./Routes";
import { _APP_DATA } from "../data/AppData";

export let lastInstance: Base;

export function _ActiveObserveHistoryHash() {
    _HashReplace("#");

    window.onhashchange = OnPageHashChange;
    // window.onpopstate = () => { };
    var rs = history.replaceState;
    history.replaceState = function () {
        rs.apply(history, arguments as any);
        OnPageHashChange();
    };

    var ps = history.pushState;
    history.pushState = function () {
        ps.apply(history, arguments as any);
        OnPageHashChange();
    };
}

function OnPageHashChange() {
    let hash = window.location.hash.replace("#", "") as THashPath;

    if ((!_APP_DATA || !_APP_DATA.userData) && hash != "login")
        return;

    if (hash == "login" && !!_APP_DATA && !!_APP_DATA.userData) { //Evitar la navegacion para Login.
        history.go(1);
        return;
    }

    if (<any>hash == "") hash = "home";

    LoadUIFromHash(hash);
}

export function LoadUIFromHash(hash: THashPath) {
    let clsUI = UIs[hash];

    if (clsUI) {
        CreateInstance(clsUI);
    } else {
        console.warn("No window found..")
    }
}

function CreateInstance<clsUI extends Base>(cls: new () => clsUI): void {
    if (lastInstance instanceof cls) {
        // lastInstance._Update(hash);
        return;
    }

    if (lastInstance) {
        lastInstance.OnDestroy();
    }

    lastInstance = new cls();
}

type TComplexHashPath = THashPath | "#" | { [k in THashPath]: `#${k}` }[THashPath]

export function _HashAssign(hash: TComplexHashPath) {
    if (!hash.startsWith("#")) hash = "#" + hash as TComplexHashPath;
    window.location.assign(hash);
}

export function _HashReplace(hash: TComplexHashPath) {
    if (!hash.startsWith("#")) hash = "#" + hash as TComplexHashPath;
    window.location.replace(hash);
}

export function _HistoryBack() {
    window.history.back();
}

export function _ReplaceState(hash: TComplexHashPath, objData: any) {
    const valueHash = (!hash.startsWith("#") ? "#" : "") + hash;
    history.replaceState(objData, hash, valueHash);
}

export function _PushState(hash: TComplexHashPath, objData: any) {
    const valueHash = (!hash.startsWith("#") ? "#" : "") + hash;
    history.pushState(objData, hash, valueHash);
}
