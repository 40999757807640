import { _MAX_PHOTO_UPLOAD } from "../../data/utils/General";
import _L from "../../utils/Labels";
import { BotSendError, BotSendWarn } from "../../utils/AlertBot";
import { _MobileObject } from "../../utils/Device";
import { ShowToast } from "./Toast";
import heic2any from "heic2any";
import { LOADING } from "./Loading";

/**
*   P h o t o s   &   F i l e   M a n a g e r
*/
type eUploadFile = {
    // maxPixel?: number;
    capture?: boolean;
    /** @example
    *  "* /*" | "image/*" | "video/*" | "audio/*" | "application/*" | "image/png" | "image/png,image/jpeg" | "application/pdf" ...
    */
    accept: string,
    /** Retorna valor false se detendrá la carga de las fotos */
    onChange?: (size: number) => boolean | void;
    onLoadFile?: (b64: string, fileInfo: iFileinfo) => void;
    onFinishLoadFiles?: (files: File[]) => void;
} & TMultipleConfig;

type TMultipleConfig = {
    [k in (0 | 1)]: {
        multiple?: (k extends 1 ? true : false);
    } & (
        k extends 1 ? {
            /**
             * Default:
             * {@link _MAX_PHOTO_UPLOAD}
             */
            maxFiles?: number,
        } : {
        }
    )
}[0 | 1];

interface iFileinfo extends File {
    extension: string;
}

export function _LoadFileManager(config: eUploadFile): void {
    try {
        LoadFileManager(config);
    } catch (e) {
        BotSendError((e || {}) as Error, "FileManager error");
    }
}
function LoadFileManager(config: eUploadFile): void {
    let innpImg = <HTMLInputElement>document.getElementById("file-input");//NOTA: Agregar un "input" con el id: "file-input" en index.html
    if (!innpImg) {
        innpImg = document.createElement("input");
        innpImg.type = "file";
        innpImg.id = "file-input";
        innpImg.style.display = "none";
        document.body.appendChild(innpImg);
    }
    config.multiple = config.multiple != null ? config.multiple : false;

    if (!!_MobileObject()?._UseFileChooser) {
        _MobileObject()._UseFileChooser(true);
    }
    if (!!_MobileObject()?._FileChooserEnableMultiple) {
        _MobileObject()._FileChooserEnableMultiple(config.multiple);
    }

    if (config.multiple) {
        config.maxFiles = config.maxFiles != null ? config.maxFiles : _MAX_PHOTO_UPLOAD;
        innpImg.setAttribute("multiple", "");
    } else {
        innpImg.removeAttribute("multiple");
    }

    if (config.capture) {
        innpImg.setAttribute("capture", "camera");
    } else {
        innpImg.removeAttribute("capture");
    }

    innpImg.setAttribute("accept", config.accept);

    innpImg.onchange = (_) => {
        let files = innpImg.files;
        if (!files || files.length === 0) return;

        if (config.onChange && config.onChange(files.length) === false)
            return;

        LoadFiles(files, config);
    };

    innpImg.onclick = (_) => {
        innpImg.value = "";
    };

    innpImg.click();
}

async function LoadFiles(files: FileList, config: eUploadFile) {
    const finalFiles: File[] = [];

    let nItem = 1;
    const maxFiles = config.multiple ? config.maxFiles : 1;
    for (const file of files) {
        if (nItem > maxFiles) {
            ShowToast("", _L("general.maxfilesonceimport", maxFiles), "info");
            break;
        }
        const f = await ResolveFile(file);

        if (!f) {
            continue;
        }

        const fileMimeType = f.file.type;
        const fileMimeTypeAll = (fileMimeType.split("/")[0] + "/" + "*");
        const acceptTypes = config.accept.split(",").map(d => d.trim());
        const isValidTypeFile = acceptTypes.find(d => (d == fileMimeTypeAll || d == fileMimeType));
        if (!acceptTypes.includes("*/*") && !isValidTypeFile) {
            ShowToast("", _L("general.filenosoported", f.file.name, maxFiles), "info");
            continue;
        }
        if (config.onLoadFile) {
            config.onLoadFile(f.base64Path, f.file);
        }
        finalFiles.push(f.file);
        nItem++;
    }

    if (config.onFinishLoadFiles) {
        config.onFinishLoadFiles(finalFiles);
    }
}

async function ResolveFile(file: File): Promise<{ file: iFileinfo, base64Path: string }> {
    return new Promise(async (resolve) => {
        if (!file.size) {
            resolve(null);
            return;
        }
        if (file.name.split('.').pop().trim().toLowerCase() == 'heic') {
            LOADING.Show();
            try {
                let fileBlob = await heic2any({
                    blob: file,
                    toType: "image/png"
                });
                file = new File(fileBlob instanceof Blob ? [fileBlob] : fileBlob, file.name.replace(/\.heic$/i, ".png"), { type: "image/png", lastModified: Date.now() });
            } catch (error) {
                console.warn("-d, Error: Heic2PngConversion");
                ShowToast(_L("perfil.upt_title"), "Error al procesar la imagen", 'warn');
                BotSendWarn("Heic2PngConversion:", (error as any).message)
                resolve(null);
            }
            LOADING.Dismiss();
        }

        const fileReader = new FileReader();
        fileReader.onload = function (e) {
            const finalFile = <iFileinfo>file;
            const ext = file.name.split('.').pop();

            if (!ext) {
                BotSendWarn("Invalid file extension", file.name);
                resolve(null);
                return;
            }

            finalFile.extension = "." + ext;

            resolve({
                file: finalFile,
                base64Path: e.target.result as string,
            });
        }
        fileReader.onerror = () => {
            resolve(null);
        }
        fileReader.readAsDataURL(file);
    })
}

// /** Reduce el peso de una imagen
//  *
//  * @param imagenComoArchivo `File`
//  * @param porcentajeCalidad de `0` a `100` por ciento
//  */
// export function CompressImgFile(imagenComoArchivo: File, porcentajeCalidad: number): Promise<Blob> {
//     return new Promise<Blob>((resolve, reject) => {
//         const canvas = document.createElement("canvas");
//         const image = new Image();
//         image.onload = () => {
//             canvas.width = image.width;
//             canvas.height = image.height;
//             canvas.getContext("2d").drawImage(image, 0, 0);
//             canvas.toBlob(
//                 (blob) => {
//                     if (blob === null) {
//                         return reject(blob);
//                     } else {
//                         resolve(blob);
//                     }
//                     URL.revokeObjectURL(image.src);
//                 },
//                 "image/jpeg",
//                 porcentajeCalidad / 100
//             );
//         };
//         image.src = URL.createObjectURL(imagenComoArchivo);
//     });
// };