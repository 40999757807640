import '../../../styles/pages/Qr-view.scss'

import { Selection } from "d3";
import { Base } from "../bases/Base";
import ic_exit from '/icons/ic_exit.svg'
import ic_bubble_qr from '/icons/ic_bubble_qr.svg'
import ic_bubble_qr_error from '/icons/ic_bubble_qr_error.svg'
import { LOADING } from '../components/Loading';
import { IResultQR } from '../../data/models/Entities';
import { _ScanQRChildInfo } from '../../data/services/Kinder';
import { SCANED_QR_VALUE } from '../../utils/Device';
import _L, { _HttpMsg } from '../../utils/Labels';
import { _HistoryBack } from '../../routes/UIManager';

export class QRView extends Base {

    private divMain?: Selection<HTMLDivElement, any, any, any>;

    constructor() {
        super('qr-view', true);

        LOADING.Show();
        this.CreateLoginUI();

        this.OnScanQR();
    }

    private OnScanQR() {
        _ScanQRChildInfo(SCANED_QR_VALUE, (result: IResultQR) => {
            this.UpdateFromResponse(result);
            LOADING.Dismiss();
        });
    }

    public CreateLoginUI() {
        const headerContainer = this.mainContainer.append("div").classed("header", true);
        headerContainer.append("img")
            .attr("src", ic_exit)
            .classed("img-exit", true)
            .attr("alt", "close-icon")
            .on("click", () => {
                _HistoryBack();
            });

        // const mainContainer = select("body");
        this.divMain = this.mainContainer.append("div").classed("container", true)

        this.divMain.append("img")
            .attr("src", ic_bubble_qr)
            .classed("img-logo-kidi", true)

        this.divMain.append("h1").classed("title", true);
        this.divMain.append("h2").classed("description", true);

        const btnLoggin = this.divMain.append("button")
            .attr("type", "button")
            .classed("btn-confirm-qr", true)
            .style("display", "none")
            .text(_L("general.acepta").toUpperCase());


        btnLoggin.on("click", () => {
            _HistoryBack();
        });
    }

    private UpdateFromResponse(response: IResultQR) {

        this.divMain.select(".img-logo-kidi")
            .attr("src", response.Resultado > 0 ? ic_bubble_qr : ic_bubble_qr_error);

        const category = [
            _L("modulo.E/S"),
            _L("E/S.entrada"),
            _L("E/S.salida"),
        ][response.IdCategoria];

        this.divMain.select(".title").text(category);

        const description = this.GetMessageDescription(response);
        this.divMain.select(".description").text(description);

        this.divMain.select(".btn-confirm-qr").style("display", "block")
    }

    private GetMessageDescription(result: IResultQR): string {
        if (result?.Resultado > 0) {
            return result.Descripcion;
        }
        return _HttpMsg("grupo/EscanearQr", result?.Resultado || -111);
    }
}