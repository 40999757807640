import { Selection, select } from "d3"
import '../../../styles/components/Loading.scss'

export class LOADING {

    private divLoading: Selection<HTMLDivElement, any, any, any>;
    private static csLoading: LOADING | null;

    constructor() {
        this.divLoading = select("body")
            .append("div").classed("lds-loading", true);
        this.CreateLoading();
        // <div class="loading lds-grid">
    }

    /**
     * Check if exist an Loading instance.
     * @returns Loading instance
     */
    public static Show(): LOADING {
        if (this.csLoading) return this.csLoading;

        this.csLoading = new LOADING();
        return this.csLoading;
    }

    private CreateLoading() {
        this.divLoading.append("div");
        this.divLoading.append("div");
        this.divLoading.append("div");
    }

    public static Dismiss() {
        if (!this.csLoading) return;

        this.csLoading.divLoading.remove();
        this.csLoading = null;
    }

    public static IsActive(): boolean {
        return !!this.csLoading;
    }

}