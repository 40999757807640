//export const urlBase: string = "http://192.168.13.16/";//pruebas local
// const urlBase: string = "https://rst1-dev.kidi.mx/";//pruebas dominio
//export const urlBase: string = "https://rst1.kidi.mx/";//produccion

const ref_url_teacher = import.meta.env.APP_URL_SRVROOTV1; //urlBase + "MaestraJS/";

const ref_agenda = ref_url_teacher + "Agenda/";
export const ref_agenda_ObtenerTareasAgenda = ref_agenda + "ObtenerTareasAgenda";
export const ref_agenda_NuevaTareaAgenda = ref_agenda + "NuevaTareaAgenda";
export const ref_agenda_ActualizarTareaAgenda = ref_agenda + "ActualizarTareaAgenda";
export const ref_agenda_EliminarTareaAgenda = ref_agenda + "EliminarTareaAgenda";

const ref_event = ref_url_teacher + "Evento/"
// export const ref_event_RegistrarEventoGeneral = ref_event + "RegistrarEventoGeneralV2";
export const ref_event_ObtenerEventosChildV2 = ref_event + "ObtenerEventosChildV2";
// export const ref_event_RegistrarEventoSiesta = ref_event + "RegistrarEventoSiesta";
export const ref_event_ObtenerComentariosEvento = ref_event + "ObtenerComentariosEvento";
export const ref_event_ObtenerEventosCalendario = ref_event + "ObtenerEventosCalendario";

const ref_logro = ref_url_teacher + "Logro/";
export const ref_logro_ObtenerLogros = ref_logro + "ObtenerLogros";
export const ref_logro_ObtenerCategorias = ref_logro + "ObtenerCategorias";
export const ref_logro_ObtenerLogrosAsignacionesChild = ref_logro + "ObtenerLogrosAsignacionesChild";
// export const ref_logro_LogroVisto = ref_logro + "LogroVisto";// Ya no aplica


/**
 * New Services MobileDev
 */

const urlTeacher = import.meta.env.APP_URL_SRVROOTV2; //urlBase + "teacher/"
const urlGroup = urlTeacher + "grupo/";
const urlConfiguration = urlTeacher + "escolaridad/";
const urlChat = urlTeacher + "chat/";
const urlTutor = urlTeacher + "tutor/";
const urlChild = urlTeacher + "alumno/";
const urlEvent = urlTeacher + "evento/";
const urlFood = urlTeacher + "alimento/";
const urlSchool = urlTeacher + "escuela/";
const urlMilestone = urlTeacher + "logro/";
const urlUser = urlTeacher + "usuario/";
const urlSession = urlTeacher + "sesion/";
const urlCalificacion = urlTeacher + "calificacion/";

export const apiEnterGroup = urlGroup + "Entrada";
export const apiOutGroup = urlGroup + "Salida";
export const apiOutKinder = urlGroup + "SalidaEscuela";
export const apiRefuseEntry = urlGroup + "RechazarEntrada";
export const apiLogin = urlGroup + "LoginV4";
export const apiLogout = urlGroup + "CerrarSesion";
export const apiLoadSchoolHour = urlGroup + "ObtenerHorario";
export const apiValidateSession = urlGroup + "VerificarSesion";
export const apiScanQR = urlGroup + "EscanearQr";
export const apiGrupoTeachersInfo = urlGroup + "ObtenerMaestrosInfo";

export const apiConfig = urlConfiguration + "ObtenerConfiguracion";

export const apiInitialUnreadMessages = urlChat + "ObtenerNoLeidosInicial";
export const apiUpdateUnreadMessages = urlChat + "ObtenerNoLeidosActualizacion";
export const apiLoadMessages = urlChat + "ObtenerLista";
export const apiNewMessages = urlChat + "NuevoMensaje";
export const apiLoadFile = urlChat + "ObtenerArchivo?";//No se esta usando
export const apiMarkAsRead = urlChat + "MarcarMensajesLeidos";

export const apiLoadTutor = urlTutor + "ObtenerLista";

export const apiLoadChild = urlChild + "ObtenerLista";
export const apiLoadPhoto = urlChild + "ObtenerFoto";
export const apiAlumnoObtenerEventos = urlChild + "ObtenerEventos";
export const apiLoadTodaySchedule = urlChild + "ObtenerHorarioHoy"


export const apiNewFood = urlEvent + "RegistrarAlimento";
export const apiUploadPhoto = urlEvent + "RegistrarFotoV2";
export const apiAddHygiene = urlEvent + "RegistrarHigiene";
export const apiLoadEventsSummary = urlEvent + "ObtenerResumenEventos";
export const apiGetEventPhoto = urlEvent + "ObtenerFoto";
export const apiGetChildsFoodEvents = urlEvent + "ObtenerComidasEventos";


export const apiLoadFoodTypes = urlFood + "ObtenerTipos";
export const apiLoadFoodToday = urlFood + "ObtenerListaDelDia";

export const apiLoadWeather = urlSchool + "ObtenerClima";
export const apiLoadConfig = urlSchool + "ObtenerConfiguracion";

export const apiLoadChildInfo = urlChild + "ObtenerInfo";
export const apiUpdatePhotoProfile = urlChild + "ActualizarFotoPerfil";

export const apiLoadCovidScore = urlChild + "ObtenerPuntuacion";
export const apiEditAssignmentStatus = urlMilestone + "EditarEstadoAsignacionV2";
export const apiLoadGroups = urlUser + "ObtenerGrupos";
export const apiLoadGroupsById = urlGroup + "ObtenerGruposInfo";
export const apiRecoverPassword = urlSession + "RecuperarContrasenia";

//Nuevos
export const apiLoadExtemporaneo = urlChild + "ObtenerExtemporaneosDisponiblesHoy";
export const apiRegistrarEventoSiesta = urlEvent + "RegistrarEventoSiestaV2";
export const apiRegistrarEventoGeneral = urlEvent + "RegistrarEventoGeneralV3";


export const apiCalificacionObtenerPendientes = urlCalificacion + "ObtenerPendientes";
/** @deprecated */
export const apiCalificacionAsignar = urlCalificacion + "Asignar";
export const apiCalificacionAsignarV2 = urlCalificacion + "AsignarV2";