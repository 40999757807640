import _Label from "../../utils/Labels";
import { _HashAssign } from "../../routes/UIManager";
import { _APP_DATA } from "../AppData";
import { _storeChat, _storeGRPChat } from "../services/Chats";
import { _mapConfiguration } from "../services/Configuration";
import { DBDelete, InitDB, SetDBName, iDBStore } from "./DB";
import { EEventId, IChild, IConfiguration, IEvent } from "../models/Entities";
import { _GetKinderEventsArray } from "../services/Kinder";
import { _storeUser } from "../services/UserInfo";
import { THashPath } from "../../routes/Routes";


export const _MAX_PHOTO_UPLOAD: number = 10;
export const _ID_CHAT_GROUP: number = -199999;

export const _SERVER_IMAGES_ACCEPT = [
    "image/png", "image/jpeg", "image/jpg",
    "image/nef", "image/avif", "image/webp",
    "image/heif", "image/heic", "image/heif-sequence", "image/heic-sequence", // IOS
];

export const WEEKDAY = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
export const MOUNTH_NAMES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export function GetAbrevDayAndDate(dt: Date): string {
    return WEEKDAY[dt.getDay()] + " " + dt.getDate();
}

export function FormatDateStrToHour(str: string): string {
    const dt = new Date(str);
    return FormatDateToHour(dt);
}

export function FormatDateToHour(dt: Date | null): string {
    if (!dt) return "";

    let hours = dt.getHours();
    const min = dt.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? 'P.M' : 'A.M';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return hours.toString().padStart(2, "0") + ':' + min + ' ' + ampm;
}

export function _GetDateDiff(dt1: Date, dt2: Date) {
    let ret = { days: 0, months: 0, years: 0 };
    if (dt1 == dt2) return ret;

    if (dt1 > dt2) {
        let dtmp = dt2;
        dt2 = dt1;
        dt1 = dtmp;
    }

    let year1 = dt1.getFullYear();
    let year2 = dt2.getFullYear();

    let month1 = dt1.getMonth();
    let month2 = dt2.getMonth();

    let day1 = dt1.getDate();
    let day2 = dt2.getDate();

    ret['years'] = year2 - year1;
    ret['months'] = month2 - month1;
    ret['days'] = day2 - day1;

    if (ret['days'] < 0) {
        let dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

        let numDays = dtmp1.getDate();

        ret['months'] -= 1;
        ret['days'] += numDays;

    }

    if (ret['months'] < 0) {
        ret['months'] += 12;
        ret['years'] -= 1;
    }

    return ret;
}


export function _CreateUUID(): string {
    return 'xxxxxx-xxxx-xxxx-xxxxx'.replace(/[x]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function _RequestFile(urlFile: string, callback: (result: Blob) => void): void {
    let xhr = new XMLHttpRequest();
    xhr.onload = () => callback(xhr.response);
    xhr.open('GET', urlFile);
    xhr.responseType = 'blob';
    xhr.send();
}

export function _DownloadFile(url: string, fileName: string) {
    _RequestFile(url, (result) => {
        if (!result) return;

        let a_element = document.createElement("a");
        a_element.download = fileName;
        a_element.href = URL.createObjectURL(result);
        a_element.click();
    });

}

export function _LoadUIMain() {
    const hashUi: THashPath = _APP_DATA.userData.IdGrupo > 0
        ? "home"
        : "groups";
    _HashAssign(hashUi);
}

// export var CONFIRM_FOOD_IDS_KINDER: Array<Number> = [2, 3];

export function _ValidRegisterEventChild(childs: IChild[], evnt: IEvent): string | null {
    if (evnt.id === EEventId.SNOOZE) {
        if (childs.length > 1) {
            const firstStatus = childs[0].EstadoEvt;
            const lengthOtherStatus = childs.filter(o => o.EstadoEvt != firstStatus).length;
            if (lengthOtherStatus > 0)
                return _Label("snooze.require_samestatus");
        }
    } else if (evnt.id === EEventId.MILLESTONE) {
        if (childs.length > 1)
            return _Label("milestone.nomultiplechild");
    } else if (evnt.id === EEventId.EVALUATION) {
        if (childs.length > 1)
            return _Label("evaluacion.nomultiplechild");
    }
    /*else if (datum.id === EEventId.CHECK_IN) {
       let childs = this.childList.length > 1 ? " todos los alumn@s seleccionados" : this.childList[0].Nombre;

       UtilUI._confirmDialog._Show("¿Está seguro de dar entrada a " + childs + " ?", (isOk) => {
           if (!isOk) {
               this._Destroy();
               return;
           }

           LOADING._Show();
           this.childList.forEach((item, i) => {
               let fn = (this.childList.length - 1) == i ? this.CallbackCheckIn.bind(this) : null;
               this.ExecCheckInGroup(item, fn);
           });
       });
   } else if (datum.id === EEventId.CHECK_OUT) {
       if (this.childList.length > 1) {
           UtilUI._dialogNotification.fun_mostrar("Selecciona solamente a un alumno", "info");
           this._Destroy();
           return;
       }

       let child = this.childList[0]
       UtilUI._confirmDialog._Show("¿Está seguro de no aceptar a " + child.Nombre + " ?", (isOk) => {
           if (!isOk) {
               this._Destroy();
               return;
           }

           LOADING._Show();
           this.ValidateRefuseEntry(child, this.CallbackCheckIn.bind(this));
       });
   }*/

    return null;
}

export function _GetDateFromInputHour(inptTime: any): Date | null {// input => Selection<HTMLInputElement, ...>
    const time = inptTime?.node()?.value;
    if (!time) return null;

    const [hr, min] = time.split(':');
    const newDate = new Date();
    newDate.setHours(+hr, +min, 0, 0);

    return newDate;
}

export async function _CheckActiveConfiguration(checkConfig: boolean = true): Promise<any[]> {
    let result = await _GetKinderEventsArray();
    let config: IConfiguration[] = [];
    if (checkConfig)
        config = Array.from(_mapConfiguration.values()).sort((a, b) => a.Tipo - b.Tipo);

    let data = [];
    let activos = [];
    for (let i = 0; i < result.length; i++) {
        let itemEvent = result[i];
        itemEvent.enabled = false;
        let tipoEvent = itemEvent.id;
        for (let a = 0; a < config.length; a++) {
            let itemConfig = config[a];
            let tipoConfig = itemConfig.Tipo;
            if (tipoConfig == tipoEvent) {
                itemEvent.enabled = true;
                config.splice(a, 1);
                activos.push(itemEvent);
            }
        }

        data.push(itemEvent);
    }

    let allData = [];
    allData[0] = data;
    allData[1] = activos;

    return allData;
}

export function _IsSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() == date2.getFullYear()
        && date1.getMonth() == date2.getMonth()
        && date1.getDate() == date2.getDate();
}
export function _AddSvgLoading(container?: HTMLDivElement | null) {
    if (!container) return;

    const { width } = container.getBoundingClientRect();
    const _svgLoading = `<svg width="${width}px" height="18">
        <line
            stroke="darkgray"
            stroke-width="10"
            opacity="0.6"
            x2="${width}px">
        </line>
        <rect width="50" height=5 rx=2.5 ry=2.5 fill="darkgray">
            <animateTransform 
                attributeType="XML" 
                attributeName="transform" 
                type="translate" 
                values="0,0;${width},0;" 
                begin="0s; click" 
                end="mouseover" 
                dur="1s" 
                repeatCount="indefinite">
            </animateTransform>
        </rect>
    </svg>`;
    container.innerHTML = _svgLoading;
}

export function _IsFileImage(extension: string): boolean {
    extension = extension.toLowerCase();
    return extension == ".jpeg" || extension == ".jpg" || extension == ".png";
}


/**
 *
 * Manage DB
 *
 */

const _dbName = "KidiTeacher";
const _tblsInfo = new Array<iDBStore>();

export async function _Init() {
    SetDBName(_dbName);

    //Init Tables
    _tblsInfo.push(_storeUser);
    _tblsInfo.push(_storeChat);
    _tblsInfo.push(_storeGRPChat);
    // _tblsInfo.push(Chat._store);
}

export async function _RestartAndCreateDB(): Promise<boolean> {
    return DBDelete().then(() => {
        return InitDB(_tblsInfo);
    });
}