import { _APP_DATA } from "../data/AppData";
import { DBDelete } from "../data/utils/DB";
import { _CreateUUID, _DownloadFile, _LoadUIMain, _RestartAndCreateDB } from "../data/utils/General";
import { _GetUserInfo } from "../data/services/UserInfo";
import { _HashAssign } from "../routes/UIManager";
import { LOADING } from "../ui/components/Loading";
import { ShowToast } from "../ui/components/Toast";

export function _IsMobileDevice() {
    return 'ontouchstart' in window;

    // if (navigator.userAgent.match(/Android/i)
    //     || navigator.userAgent.match(/webOS/i)
    //     || navigator.userAgent.match(/iPhone/i)
    //     || navigator.userAgent.match(/iPad/i)
    //     || navigator.userAgent.match(/iPod/i)
    //     || navigator.userAgent.match(/BlackBerry/i)
    //     || navigator.userAgent.match(/Windows Phone/i)
    // ) return true;
    // else return false;
}

export function _MobileObjectIsDefined() {
    return !!(window["Mobile"]);
}

export function _MobileObject() {
    if (!_MobileObjectIsDefined()) {
        return null;
    }
    return Mobile;
}

export const _CURRENT_MOBILEKEYBOARD: KeyboardSizeChangeEvent["detail"] = { BodyTranslateY: 0, KeyboardHeight: 0 };
export const _IS_SAFARI_NAVIGATOR = !!navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

export declare var Mobile: IMobile;
declare var heightStatusBar: number;

export const IS_MOBILE = (typeof Mobile === "object");

export var SCANED_QR_VALUE: string = "";

export function _GetAPPInfo() {
    if (IS_MOBILE) {
        let dataRes = Mobile._LoadAPPInfo();
        if (dataRes) Mobile._OnSetAPPInfo(dataRes);

        Mobile._SetSizeWindow(window.innerWidth, window.innerHeight);//Enviar el tamaño de la pantalla

    } else {
        let result = <IAppData>{};

        result.username = localStorage.getItem("username");
        result.password = localStorage.getItem("password");

        //Default App Values
        result.mobileType = 3;// => 1: iOS, 2: Android, 3: Web

        let uuid = localStorage.getItem("UUID");
        if (!uuid) uuid = "WEB-" + _CreateUUID();
        result.IMEI = uuid;

        OnLoadAPPData(result);
    }
}

async function OnLoadAPPData(result: IAppData) {
    Object.assign(_APP_DATA, result);

    if (result.username && result.password) {
        const userInfo = await _GetUserInfo();
        if (userInfo) {
            _APP_DATA.userData = userInfo;

            _LoadUIMain();

        } else {
            _Logout();
        }
    } else {
        _HashAssign("login");
    }
}

export function _SaveUserData(infoUser: any) {
    let info: any = {};
    Object.keys(infoUser).forEach(key => {
        let value = infoUser[key];
        if (value != null && value != undefined) {
            if ((value instanceof Date && Number.isNaN(value.valueOf())))
                return;

            info[key] = value;
        }
    });

    if (IS_MOBILE) {
        Mobile._SaveUserData(JSON.stringify(info));
    } else {
        localStorage.setItem("username", infoUser.username);
        localStorage.setItem("password", infoUser.password);
        localStorage.setItem("UUID", _APP_DATA.IMEI);
    }

    _APP_DATA.username = infoUser.username;
    _APP_DATA.password = infoUser.password;
    _APP_DATA.userData = infoUser;
}

export function _ShowMessage(msg: string) {
    if (IS_MOBILE)
        Mobile._ShowMessage(msg);
    else ShowToast("", msg);
}

export function _ClearHistory() {
    if (IS_MOBILE) Mobile._ClearHistory();
}

export function _ClearCache() {
    if (IS_MOBILE) Mobile._ClearCache();
}

export function _Logout() {
    DBDelete().then(() => {
        _APP_DATA.username = null;
        _APP_DATA.password = null;

        localStorage.removeItem("username")
        localStorage.removeItem("password")
        localStorage.removeItem("UUID")
        if (IS_MOBILE) Mobile._Logout();

        _ClearHistory();

        location.reload();
    }).catch((_) => {
        console.log("Error al eliminar bd")
    });
}

export function _SaveImage(urlImage: string, fileName: string) {
    if (IS_MOBILE) {
        var info = {
            url: urlImage,
            name: fileName
        }
        Mobile._SaveImage(JSON.stringify(info));
    } else
        _DownloadFile(urlImage, fileName);
}

export function _ScanQR() {
    if (IS_MOBILE) {
        Mobile._ScanQR();
    } else {
        console.log("Función no disponible.")
    }
}
export function _GetUserAgent() {
    let sBrowser, sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("MSIE") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } else {
        sBrowser = "UserAgent Unknow";
    }

    return sBrowser;
}


// ****************************************************************
// BUILD MOBILE OBJECT
// ****************************************************************

export let _MobileSetKeyboardSize: (keyboardHeight: number) => void;

if (IS_MOBILE) {
    Mobile._OnSetAPPInfo = (resData: string) => {
        var info = JSON.parse(resData);
        OnLoadAPPData(info);
    }

    Mobile._SetKeyBoardSize = function (val: number) {
        _MobileSetKeyboardSize(val);
    }

    let __OnFocusIn: typeof document.onfocus;
    _MobileSetKeyboardSize = (keyboardHeight: number) => setTimeout(() => {
        if (_IS_SAFARI_NAVIGATOR) { // iOS no necesita esta función
            return;
        }
        // document.body.style.transform = "";
        const initActive = document.activeElement;
        let val = keyboardHeight;
        if (keyboardHeight != 0 && initActive) {
            const resolveLimitElementsDefault = 30;
            const resolveLimitElements = (initActive.hasAttribute("keyboard_limit") ? Number(initActive.getAttribute("keyboard_limit") || 0) : resolveLimitElementsDefault);
            const { y, height } = initActive.getBoundingClientRect();
            const { bottom: bodyBottom } = document.body.getBoundingClientRect();
            val -= (bodyBottom - (y + height + resolveLimitElements));
            if (val < 0) val = 1;
        }
        if (val > 0) {
            document.body.style.transition = "transition .5s";
            document.body.style.transform = "translateY(-" + val + "px)";
            // console.debug(document.body.style.transform);
            if (__OnFocusIn) {
                document.removeEventListener("focusin", __OnFocusIn);
            }
            __OnFocusIn = () => setTimeout(() => {
                const { activeElement } = document;
                const activeIsInput = activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement;
                // console.warn("focusin", activeElement, activeIsInput);
                if (activeIsInput && initActive != activeElement) {
                    _MobileSetKeyboardSize(keyboardHeight);
                }
            });
            document.addEventListener("focusin", __OnFocusIn);
        }
        else {
            document.body.style.transition = "";
            document.body.style.transform = "";
            document.removeEventListener("focusin", __OnFocusIn);
            __OnFocusIn = null;
        }

        // dispatch event
        _CURRENT_MOBILEKEYBOARD.BodyTranslateY = val;
        _CURRENT_MOBILEKEYBOARD.KeyboardHeight = keyboardHeight;
        const evt = new CustomEvent<KeyboardSizeChangeEvent["detail"]>("keyboardsizechange", {
            bubbles: true,
            cancelable: true,
            detail: { ..._CURRENT_MOBILEKEYBOARD },
        })
        document.dispatchEvent(evt);
    }, 10);

    Mobile._OnBack = function () {
        if (LOADING.IsActive()) {
            history.back();
        }
    }

    Mobile._OnSetQRValue = function (value: string) {
        SCANED_QR_VALUE = value;

        _HashAssign("qrview");
    }
}