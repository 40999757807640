type TEvent = "swipeDown" | "swipeUp" | "swipeLeft" | "swipeRight";
type TElementSwipe = HTMLElement & {
    __eventsPlaying?: ISwipeEvents;
}

interface ISwipeEvents {
    touchstart: (e: TouchEvent) => void;
    mousedown: (e: MouseEvent) => void;
    touchmove: (e: TouchEvent) => void;
    mousemove: (e: MouseEvent) => void;
    touchend: (e: TouchEvent) => void;
    mouseup: (e: MouseEvent) => void;
}

export function _RemoveSwipeEvent(element: HTMLElement) {
    const elementE = element as TElementSwipe;
    if (!elementE.__eventsPlaying) return;
    for (let k in elementE.__eventsPlaying) {
        elementE.removeEventListener(k, elementE.__eventsPlaying[k], false);
    }
}

export function _AddSwipeEvent(element: HTMLElement, eventName: TEvent, handleEvent: (() => void)) {
    const elementE = element as TElementSwipe;

    if (elementE.__eventsPlaying) {
        _RemoveSwipeEvent(elementE);
    }
    elementE.__eventsPlaying = GetEventsRequired(eventName, handleEvent);

    for (let k in elementE.__eventsPlaying) {
        elementE.addEventListener(k, elementE.__eventsPlaying[k], false);
    }
}

function GetEventsRequired(eventName: TEvent, handleEvent: (() => void)): ISwipeEvents {
    let eStart = 0, eEnd = 0;
    return {
        "touchstart": function (e: TouchEvent) {
            switch (eventName) {
                case "swipeUp":
                case "swipeDown":
                    eStart = e.targetTouches[0].clientY;
                    break;
                case "swipeLeft":
                case "swipeRight":
                    eStart = e.targetTouches[0].clientX;
                    break;
                default:
                    eStart = e.targetTouches[0].clientY;
                    break;
            }
        },
        "mousedown": function (e: MouseEvent) {
            switch (eventName) {
                case "swipeDown":
                case "swipeUp":
                    eStart = e.clientY;
                    break;
                case "swipeRight":
                case "swipeLeft":
                    eStart = e.clientX;
                    break;
                default:
                    eStart = e.clientY;
                    break;
            }
        },
        "touchmove": function (e: TouchEvent) {
            e.preventDefault();
        },
        "mousemove": function (e: MouseEvent) {
            e.preventDefault();
        },
        "touchend": function (e: TouchEvent) {
            switch (eventName) {
                case "swipeUp":
                case "swipeDown":
                    eEnd = e.changedTouches[0].clientY;
                    break;
                case "swipeLeft":
                case "swipeRight":
                    eEnd = e.changedTouches[0].clientX;
                    break;
                default:
                    eEnd = e.changedTouches[0].clientY;
                    break;
            }

            var moveVal = eEnd - eStart;
            var moveAbsVal = Math.abs(moveVal);

            // swipeUp
            if (moveVal < 0 && moveAbsVal > 30 && eventName == "swipeUp") {
                // console.log("swipeUp");
                handleEvent();
            }
            // swipeDown
            if (moveVal > 0 && moveAbsVal > 30 && eventName == "swipeDown") {
                // console.log("swipeDown");
                handleEvent();
            }
            // swipeLeft
            if (moveVal < 0 && moveAbsVal > 30 && eventName == "swipeLeft") {
                // console.log("swipeLeft");
                handleEvent();
            }
            // swipeRight
            if (moveVal > 0 && moveAbsVal > 30 && eventName == "swipeRight") {
                // console.log("swipeRight");
                handleEvent();
            }
        },
        "mouseup": function (e: MouseEvent) {
            switch (eventName) {
                case "swipeUp":
                case "swipeDown":
                    eEnd = e.clientY;
                    break;
                case "swipeLeft":
                case "swipeRight":
                    eEnd = e.clientX;
                    break;
                default:
                    eEnd = e.clientY;
                    break;
            }

            var moveVal = eEnd - eStart;
            var moveAbsVal = Math.abs(moveVal);

            // swipeUp
            if (moveVal < 0 && moveAbsVal > 30 && eventName == "swipeUp") {
                // console.log("swipeUp");
                handleEvent();
            }
            // swipeDown
            if (moveVal > 0 && moveAbsVal > 30 && eventName == "swipeDown") {
                // console.log("swipeDown");
                handleEvent();
            }
            // swipeLeft
            if (moveVal < 0 && moveAbsVal > 30 && eventName == "swipeLeft") {
                // console.log("swipeLeft");
                handleEvent();
            }
            // swipeRight
            if (moveVal > 0 && moveAbsVal > 30 && eventName == "swipeRight") {
                // console.log("swipeRight");
                handleEvent();
            }
        },
    }
}