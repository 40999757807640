import { Selection, select, timeFormat } from "d3"
import '../../../styles/components/Photo-viewer.scss'
import ic_crop from '/icons/ic_crop.svg?raw'
import ic_change_orientation from '/icons/ic_change_orientation.svg?raw'
import ic_flip from '/icons/ic_flip.svg?raw'
import ic_done from '/icons/ic_done.svg?raw'
import ic_download from '/icons/ic_download.svg?raw'
import ic_exit from '/icons/ic_exit.svg?raw'
import { EImageOrientation, _SetImageOrientation } from "../utils/ImageEditor";
import { _SaveImage } from "../../utils/Device";
import _L from "../../utils/Labels";
import { _CreateElementFromHTML } from "../utils/General";

export interface IInfoFile {
    b64: string,
    name: string,
    extension: string,
    description: string
}

export class ImageViewer {

    private content: Selection<HTMLDivElement, any, any, any>;
    private footer: Selection<HTMLDivElement, any, any, any>;

    private imgElement?: Selection<HTMLImageElement, any, any, any>;
    private txtDescription?: Selection<HTMLInputElement, any, any, any>;

    private srcImg?: string;
    private OnSaveCompletion: (infoFile: IInfoFile) => void;

    private btnsEdition: Selection<HTMLDivElement, any, any, any>;
    private isEditing: boolean = false;
    private srcOriginal: any;
    private extention: string;
    private description: string;
    private donwloadUrl: string;
    private name: string;

    private RWH?: { w: number, h: number };
    private boundsIMG: { x: number, y: number, w: number, h: number, scale: number } = { x: 0, y: 0, w: 0, h: 0, scale: 0 };

    constructor() {
        this.content = select("body")
            .append("div").classed("photo-viewer", true);

        this.CreateViewer();
    }


    private CreateViewer() {
        this.imgElement = this.content.append("img").classed("img-preview", true);

        //Header
        this.content.append(() => _CreateElementFromHTML(ic_exit))
            .attr("class", "img-exit ic_close")
            .attr("alt", "close-icon")
            .on("click", () => {
                // ImageViewer.Dismiss();
                this.OnClose();
            })

        this.name = "KIDI_File_" + (timeFormat("%Y_%m_%d_%H_%M_%S")(new Date()));
        this.footer = this.content.append("div").classed("footer-div", true);
    }

    public AddEditOptions(): ImageViewer {
        this.btnsEdition = this.footer.append("div").classed("btns-edition", true);

        this.AddCircleButton(this.btnsEdition, 'crop', () => {
            this.btnsEdition.style("display", "none");
            this.txtDescription?.style("display", "none");
            this.isEditing = true;
            this.srcOriginal = this.srcImg;

            this.footer.append("h3").text(_L("general.cancela")).classed("txt-cancel", true)
                .on("click", () => {
                    this.SetImage(this.srcOriginal);
                    this.OnExitEdit();
                });

            // this.AddCanvasAndCrop()
            // this.AddRectCropp();
            // InitCrop(this.boundsIMG, this.srcOriginal);

            this.OnEdit()

            // ShowToast("Recortar imagen", "Selecciona el area a recortar", 'success', "BOTTOM-RIGHT");
        }, ic_crop);

        this.AddCircleButton(this.btnsEdition, 'rotate', () => {
            if (!this.srcImg) return;

            _SetImageOrientation(this.srcImg, EImageOrientation.ROTAR_270, (b64) => {
                this.SetImage(b64);
            });
        }, ic_change_orientation);
        this.AddCircleButton(this.btnsEdition, 'flip', () => {
            if (!this.srcImg) return;

            _SetImageOrientation(this.srcImg, EImageOrientation.ESPEJO_1, (b64) => {
                this.SetImage(b64);
            });
        }, ic_flip);
        this.AddCircleButton(this.footer, 'save', () => {
            // ImageViewer.Dismiss();
            if (this.isEditing) {
                // this.AddCanvasAndCrop();
                this.OnClickSaveCrop();
                this.OnExitEdit();
            } else {
                const description = this.txtDescription?.node()?.value || "";
                const fileName = this.name + this.extention;
                this.OnSaveCompletion({ b64: this.srcImg || "", name: fileName, extension: this.extention, description: description });
                this.OnClose();
            }
        }, ic_done);

        return this;
    }

    public SetImage(img: string): ImageViewer {
        this.srcImg = img;
        this.imgElement?.attr("src", img);

        this.TEMPADD();

        return this;
    }

    // public SetImageFile(file: File, calidad: number = 100): ImageViewer {
    //     CompressImgFile(file, calidad)
    //         .then(fixedFile => {
    //             ShowToast(">", file.size + " <> " + fixedFile.size)
    //             const src = URL.createObjectURL(fixedFile);
    //             this.srcImg = src;
    //             this.srcOriginal = src;
    //             this.imgElement?.attr("src", src);

    //             this.imgElement["__revokeURLResourse"] = () => {
    //                 // URL.revokeObjectURL(src);
    //                 this.imgElement.node().removeEventListener("load", this.imgElement["__revokeURLResourse"]);
    //             };
    //             this.imgElement.node().addEventListener("load", this.imgElement["__revokeURLResourse"]);

    //             this.TEMPADD();
    //         });

    //     return this;
    // }

    public Extension(ext: string): ImageViewer {
        this.extention = ext;
        return this;
    }

    public Description(description: string): ImageViewer {
        this.description = description;
        console.log(this.description)
        return this;
    }

    public DonwloadFile(url: string): ImageViewer {
        if (!url) return this;

        this.donwloadUrl = url;

        this.AddCircleButton(this.footer, 'download', () => {
            const fileName = this.name + this.extention;
            _SaveImage(this.donwloadUrl, fileName)
        }, ic_download, "80%", "80%");

        return this;
    }

    public Name(value: string): ImageViewer {
        this.name = value;
        return this;
    }

    public AddTextBox(): ImageViewer {
        this.txtDescription = this.footer.insert("input", ".btns-edition")
            .attr("type", "text")
            .classed("text-description", true)
            .attr("keyboard_limit", 10)
            .attr("placeholder", _L("general.write_descript"));

        return this;
    }

    public OnSuccessCompletion(completion: (info: IInfoFile) => void) {
        this.OnSaveCompletion = completion;
    }

    private OnExitEdit() {
        this.btnsEdition.style("display", "block");
        this.txtDescription?.style("display", "block");
        this.content.select(".footer-div").select(".txt-cancel").remove();
        this.isEditing = false;
        this.content.select(".rect-select-containers").remove();
        this.cropper.destroy();
    }

    private cropper: any;
    private OnEdit() {
        const image = this.imgElement?.node()
        //@ts-ignore
        this.cropper = new Cropper(image, {
            // aspectRatio: 16 / 9,
            zoomable: false,
            zoomOnWheel: false,
        });
    }


    private OnClickSaveCrop() {
        this.cropper.getCroppedCanvas().toBlob((blob: Blob) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                var base64data = reader.result as any;
                this.SetImage(base64data);
            }
        });
    }


    private TEMPADD() {
        const bounds = this.imgElement?.node()?.getBoundingClientRect();
        const wth = bounds?.width || 1000;
        const hgt = bounds?.height || 1000;

        let realWidth = this.imgElement?.node()?.naturalWidth || 0;
        let realHeight = this.imgElement?.node()?.naturalHeight || 0;
        this.RWH = { w: realWidth, h: realHeight };

        const RWH_w = this.RWH?.w || 0;
        const RWH_h = this.RWH?.h || 0;
        let scale = 0;

        if (RWH_w < RWH_h) {
            scale = hgt / RWH_h;
        } else {
            scale = wth / RWH_w;
        }

        this.boundsIMG.w = RWH_w * scale;
        this.boundsIMG.h = RWH_h * scale;
        this.boundsIMG.x = (wth - this.boundsIMG.w) / 2;
        this.boundsIMG.y = (hgt - this.boundsIMG.h) / 2;
        this.boundsIMG.scale = scale;
    }

    private AddCircleButton(content: Selection<HTMLDivElement, any, any, any>, cls: string, OnClick: () => void, iconSVG: string, width = "100%", height = "100%"): Selection<HTMLButtonElement, any, any, any> {
        return content.append("button").classed("button-circle", true)
            .classed(cls, true)
            .on("click", OnClick)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(iconSVG))
                    .style("width", width)
                    .style("height", height)
            })
    }

    public OnClose() {
        this.content?.remove();
        // this.csImgPrev = null;
    }
}

