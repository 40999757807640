import { _SignOut } from "../../data/services/UserInfo";
import { _RestartAndCreateDB } from "../../data/utils/General";
import { _Logout } from "../../utils/Device";
import _L from "../../utils/Labels";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";

export function _LogoutDialog() {
    new ConfirmDialog()
        .SetTitle(_L("sesion.title_confirm"))
        .SetDescription(_L("sesion.description"))
        .SetOnConfirm(() => {
            LOADING.Show();

            _SignOut(async (resStatus) => {
                if (resStatus === 1) {
                    await _RestartAndCreateDB();
                    _Logout();
                } else {
                    ShowToast(_L("sesion.logout"), _L("sesion.logout_fail"), 'error');
                }

                LOADING.Dismiss();
            });
        });
}