import "../../../styles/animations/SnoozeAnimation.scss"
import { active, easeLinear, range, select, Selection } from "d3";
import { ToImageSvgElement } from "../utils/ImageSVG";
import ic_snooze from '/icons/event/use-icon-siesta.svg'

export function _childSleep(element: SVGGElement | null, outer_radius: number, inner_radius: number, transition = false, stop = false): void {
    let el_g_container = select(element);
    if (!el_g_container.classed("sleep") && !stop) {
        el_g_container.classed("sleep", true);
        let el_g_sleep = el_g_container.append("g")
            .classed("item-g-sleep", true)
            .attr("pointer-events", "none");

        el_g_sleep.append("circle")
            .classed("sleep-circle-opacity", true)
            .style("opacity", .25)
            .style("fill", "#000000")
            .attr("cx", outer_radius)
            .attr("cy", outer_radius)
            .attr("r", inner_radius);

        let text_init_size = 2;
        let text_init_pos_x = outer_radius;
        let text_init_pos_y = outer_radius * 1.5;

        let list_aux: number[] = [];
        let all_text = el_g_sleep.selectAll<SVGTextElement, number>(".sleep-text-z").data(range(0, 6));
        all_text.exit().remove();
        let texts_merge = all_text.enter()
            .append<SVGTextElement>("text").text("Z")
            .attr("class", "sleep-text-z")
            .classed("no-apply", true)
            .attr("display", "none")
            .attr("fill", "rgba(255, 255, 255, 0.8)")
            .style("font-family", "'Lato', Arial, sans-serif;")
            .merge(all_text)
            .attr("font-size", text_init_size)
            .style("font-weight", "bold")
            .attr("x", text_init_pos_x)
            .attr("y", text_init_pos_y)
            .each(() => list_aux.push(1))

        texts_merge.transition()
            .ease(easeLinear)
            .duration(2000)
            .delay((datum) => datum * 1500)
            .on("start", function repeat(datum: any) {
                active(this)?.attr("display", "block")
                    .attr("font-size", text_init_size * (list_aux[datum] + 8))
                    .attr("x", text_init_pos_x + ((list_aux[datum] % 2 === 0 ? -5 : 20) * list_aux[datum]))
                    .attr("y", text_init_pos_y + (-20 * list_aux[datum]))
                    .on("end", () => {
                        if (list_aux[datum] === 4) {
                            select(this).attr("display", "none")
                                .attr("font-size", text_init_size)
                                .attr("x", text_init_pos_x)
                                .attr("y", text_init_pos_y);

                            list_aux[datum] = 1;
                        }
                    }).transition().on("start", repeat);

                list_aux[datum] += 1;
            });
    } else if (stop) {
        el_g_container.classed("sleep", false);
        el_g_container.select(".item-g-sleep").remove();
    }

    if (!stop && transition) {
        el_g_container.select(".item-g-sleep")
            .select(".sleep-circle-opacity")
            .interrupt().transition().duration(300)
            .attr("r", inner_radius);
    }
}

export function _SnoozeAnimation(element: Selection<HTMLDivElement, any, any, any>, isSleep: boolean) {
    element.select(".anim-snooze-container").remove();
    if (!isSleep) return;

    const animDiv = element.append("div").classed("anim-snooze-container", true);
    animDiv.html(`<div class="snooze-animation">
            <span>z</span>
            <span>z</span>
            <span>z</span>
            <span>z</span>
        </div>`)
}

export function AddSleepIndicator(element: Selection<HTMLDivElement, any, any, any>, isSleep: boolean) {
    element.select(".anim-snooze-container").remove();
    if (!isSleep) return;

    const animDiv = element.append("div").classed("snooze-indc-container", true);
    ToImageSvgElement(ic_snooze, animDiv)
}

export function AddShadoCardSvg(svgBubbleContainer: Selection<SVGGElement, any, any, any>,) {
    svgBubbleContainer.append("defs")
        .html(`
            <filter id="shadowed" width="1.5" height="1.5" x="-0.25" y="-0.25">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2.5" result="blur"></feGaussianBlur>
                <feColorMatrix result="bluralpha" type="matrix" values="1 0 0 0   0 0 1 0 0   0 0 0 1 0   0 0 0 0 0.4 0 "></feColorMatrix>
                <feOffset in="bluralpha" dx="3" dy="3" result="offsetBlur"></feOffset>
                <feMerge>
                    <feMergeNode in="offsetBlur"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>`)
}

export function AddCloudBubble(bubble: Selection<SVGGElement, any, any, any>, isSleep: boolean) {
    bubble.select(".anim-snooze-container").remove();
    if (!isSleep) return;

    const gSle = bubble.append("g")
        .classed("anim-snooze-container", true)
        .attr("transform", "translate(60, -1)")
    gSle.append("rect")
        .attr("fill", "white")
        .attr("width", 50)
        .attr("height", 24)
        .attr("stroke", "gba(0, 0, 0, 0.1)")
        .attr("rx", 12)
        .attr("ry", 12)

    gSle.append("rect")
        .attr("fill", "white")
        .attr("width", 10)
        .attr("height", 10)
        .attr("stroke", "gba(0, 0, 0, 0.1)")
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("x", 36)
        .attr("y", 26)
    gSle.append("rect")
        .attr("fill", "white")
        .attr("width", 6)
        .attr("height", 6)
        .attr("stroke", "gba(0, 0, 0, 0.1)")
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("x", 30)
        .attr("y", 34)

    gSle.append("text")
        .text("Z Z Z")
        .attr("font-weight", 'bold')
        .style("font-size", "10px")
        .attr("x", 25)
        .attr("y", 12)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("dominant-baseline", "middle")
}