import "../../../styles/pages/Event-hygiene.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { ECategoryHygiene, EEventId, ETypeBath } from "../../data/models/Entities";
import { CheckBox } from "../components/CheckBox";
import { Selection } from "d3";
import { ShowToast } from "../components/Toast";
import { _GetDateFromInputHour } from "../../data/utils/General";
import { _AddHygieneEvent, _GetIdComment, _mapCommentByEvent } from "../../data/services/Event";
import { LOADING } from "../components/Loading";
import { _TimeToInputFmt } from "../../utils/General";
import { Input } from "../components/Input";
import { RadioGroup } from "../components/RadioButton";
import _L, { _HttpMsg } from "../../utils/Labels";


interface ICategoryHygiene {
    id: ECategoryHygiene;
    name: string;
    checkMain?: CheckBox;
    controlTime?: Selection<HTMLInputElement, any, any, any>;
    radioGroup?: RadioGroup;
    check1: { id: number, label: string, /*check?: CheckBox */ };
    check2: { id: number, label: string, /*check ?: CheckBox */ };
    textArea?: Input;
};

export class EventHygiene extends BaseUIEvent {

    private listCategoriesHyg: Array<ICategoryHygiene> = [];

    constructor() {
        super('ui-event-hygiene', EEventId.HYGIENE);

        this.CreateCategories();
        this.AddItemHygiene();
        this.AddSaveBtn();
    }

    private CreateCategories() {
        this.listCategoriesHyg = [{
            id: ECategoryHygiene.PIPI, name: _L("hygiene.pipi"),
            check1: { id: ETypeBath.ACCIDENTE, label: _L("hygiene.accident") },
            check2: { id: ETypeBath.INTENTO, label: _L("hygiene.intent") }
        }, {
            id: ECategoryHygiene.POPO, name: _L("hygiene.popo"),
            check1: { id: ETypeBath.ACCIDENTE, label: _L("hygiene.accident") },
            check2: { id: ETypeBath.INTENTO, label: _L("hygiene.intent") }
        }];
    }

    private AddItemHygiene() {

        this.listCategoriesHyg.forEach((item, i) => {
            const itemContainer = this.bodyContainer.append("div").classed("item-hygiene-container", true);
            item.checkMain = new CheckBox(itemContainer)
                .SetIdentifier(item.name.toLowerCase())
                .SetLabel(item.name)
                .SetValue(item.id);

            const dDescription = itemContainer.append("div").classed("d-description", true);

            item.controlTime = dDescription.append("input").attr("type", "time").classed("time", true);
            item.controlTime.attr("value", _TimeToInputFmt());

            item.radioGroup = new RadioGroup(dDescription);
            item.radioGroup.AddRadioButton()
                .SetIdentifier(i + "_accidente")
                .SetLabel(item.check1.label)
                .SetValue(item.check1.id);

            item.radioGroup.AddRadioButton()
                .SetIdentifier(i + "_intento")
                .SetLabel(item.check2.label)
                .SetValue(item.check2.id);


            // item.check1.check = new CheckBox(dDescription)
            //     .SetIdentifier(i + "_accidente")
            //     .SetLabel(item.check1.label)
            //     .SetValue(item.check1.id);

            // item.check2.check = new CheckBox(dDescription)
            //     .SetIdentifier(i + "_intento")
            //     .SetLabel(item.check2.label)
            //     .SetValue(item.check2.id);

            const listSubCategories = _mapCommentByEvent.get(EEventId.HYGIENE) || [];
            const filterCommentDef = listSubCategories.filter((d) => (item.id == d.Categoria));

            item.textArea = new Input(itemContainer, { inputType: 'textarea', concatValueSelected: true })
                .SetPlaceholder(_L("general.comentario"))
                .SetDefaultOptions(filterCommentDef.map(o => o.Mensaje))
        });
    }

    protected OnSaveClick(): void {
        let someChecked = false;
        for (let i = 0; i < this.listCategoriesHyg.length; i++) {
            let item = this.listCategoriesHyg[i];

            if (item.checkMain?.IsChecked()) {
                const dtTime = _GetDateFromInputHour(item.controlTime);
                if (!dtTime) {
                    ShowToast(_L("hygiene.title"), _L("hygiene.hour_required", item.name), "info");
                    return;
                }

                if (!dtTime || new Date().getTime() < dtTime.getTime()) {
                    ShowToast(_L("hygiene.title"), _L("hygiene.hour_menor", item.name), "info");
                    return;
                }

                if (!item.textArea.Validate()) return

                someChecked = true;
            }
        }

        if (!someChecked) {
            ShowToast(_L("hygiene.title"), _L("hygiene.tipo_required"), 'info');
            return;
        }

        LOADING.Show();
        this.RegisterEventHygiene(0);
    }

    private RegisterEventHygiene(index: number) {
        let item = this.listCategoriesHyg[index];
        if (!item) {
            LOADING.Dismiss();
            history.back();
            return;
        }

        if (item.checkMain?.IsChecked()) {
            let idCategoria = 0;// NINGUNO/ACCIDENTE/INTENTO

            // if (item.check1.check?.IsChecked())
            //     idCategoria = item.check1.id;
            // else if (item.check2.check?.IsChecked())
            //     idCategoria = item.check2.id;

            const radInfoSelected = item.radioGroup.GetRadioSelectedInfo();
            if (radInfoSelected) idCategoria = radInfoSelected;

            const comment = item.textArea?.value || "";
            const idComment = _GetIdComment(EEventId.HYGIENE, comment);
            const dtTime = _GetDateFromInputHour(item.controlTime) || new Date();

            let ids = this.childList.map(o => o.IdChild);

            _AddHygieneEvent(ids, comment, item.id, idCategoria, dtTime, this.IS_EXTEMPORAL, idComment, (result) => {
                if (result == 1) {
                    ShowToast(`${_L("hygiene.title")} (${item.name})`, _HttpMsg("evento/RegistrarHigiene", result, "record"));
                    item.checkMain.SetChecked(false);
                    this.RegisterEventHygiene(index + 1);
                } else {
                    this.EventComunError(result, `${_L("hygiene.title")} (${item.name})`);
                    LOADING.Dismiss();
                }
            });

        } else
            this.RegisterEventHygiene(index + 1);
    }
}