import { Selection, xml } from "d3";

let __CACHE_SVGS__TEMP: Map<string, HTMLElement | any> = new Map();
let __CACHE_SVGS__: Map<string, HTMLElement | any> = new Map();

/**
 * @deprecated The method should not be used
 */
export function ImageToSvgElement(pathImg: string, completion: (svgElem: HTMLElement) => void) {
    const id = pathImg.replace(/\//g, '_');
    const svgVal = __CACHE_SVGS__TEMP.get(id);
    if (svgVal) {
        completion(svgVal);
        return;
    }

    xml(pathImg)
        .then(data => {
            data.documentElement.querySelector("title")?.remove();
            __CACHE_SVGS__TEMP.set(id, data.documentElement);

            completion(data.documentElement)
        }).catch(e => console.log(e));
}

export function ToImageSvgElement(pathImg: string, parentNode: Selection<any, any, any, any>, OnLoaded?: () => void) {
    const id = pathImg.replace(/\//g, '_');
    const svgVal = __CACHE_SVGS__.get(id);
    if (svgVal) {
        parentNode.html(svgVal);
        parentNode.select("svg title").remove();
        if (OnLoaded) OnLoaded();
        return;
    }

    xml(pathImg)
        .then(data => {
            const valOuter = data.documentElement.outerHTML;
            __CACHE_SVGS__.set(id, valOuter);
            parentNode.html(valOuter);
            parentNode.select("svg title").remove();
            if (OnLoaded) OnLoaded();
        }).catch(e => console.log(e));
}

function CacheSvg(_: Object, __: string, descriptor: TypedPropertyDescriptor<any>) {
    const originalMethod = descriptor.value; // save a reference to the original method

    descriptor.value = function (...args: any[]) {
        const key = args[0];
        if (__CACHE_SVGS__.has(key)) return __CACHE_SVGS__.get(key);

        const result = originalMethod.apply(this, args);
        __CACHE_SVGS__.set(key, result);

        return result;
    };

    return descriptor;
}


export class ImageSVG {
    //@ts-ignore
    @CacheSvg
    myMethod(path: string): string {
        return path;//svg outerhtml
    }
}
