import "../../../styles/pages/Event-photo.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { EEventId } from "../../data/models/Entities";
import { Selection, select } from "d3";
import ic_edit from '/icons/ic_edit.svg?raw'
import ic_cam from '/icons/ic_camera.svg?raw'
import ic_done from '/icons/ic_done.svg?raw'
import { _LoadFileManager } from "../components/FileManager";
import { ShowToast } from "../components/Toast";
import { ImageViewer } from "../components/ImageViewer";
import { _SERVER_IMAGES_ACCEPT } from "../../data/utils/General";
import { LOADING } from "../components/Loading";
import { _Uploadphoto } from "../../data/services/Event";
import _L, { _HttpMsg } from "../../utils/Labels";
import { _HistoryBack } from "../../routes/UIManager";
import { _CreateElementFromHTML } from "../utils/General";
import { _IS_SAFARI_NAVIGATOR } from "../../utils/Device";

enum EStatusPhoto {
    NOT_DEFINED = 0,
    SELECTED = 1,
    UPLOADED = 2,
    ERROR = 3
}

interface IPhotoItem {
    id: number,
    file: any,
    url: string,
    status: EStatusPhoto
};


export class EventPhoto extends BaseUIEvent {

    private listPhotos: IPhotoItem[] = [];

    private divListPhotos: Selection<HTMLDivElement, any, any, any>;

    constructor() {
        super('ui-event-photo', EEventId.PHOTOS);

        this.divListPhotos = this.bodyContainer.classed("list-photos", true)
        this.SetGridPhotos();
        this.AddSaveBtn();
    }

    private SetGridPhotos() {
        const bubblesgroup = this.divListPhotos.selectAll<HTMLDivElement, any>(".photo-item").data(this.listPhotos);

        bubblesgroup.exit().remove();
        bubblesgroup.enter().append("div")
            .classed("photo-item", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("img").classed("photo", true);
                elemnt.append("div")
                    .classed("ic_edit", true)
                    .call((btn) => {
                        btn.append(() => _CreateElementFromHTML(ic_edit))
                            .style("height", "100%")
                            .style("width", "100%");
                    })
                elemnt.append("div")
                    .classed("uploaded", true)
                    .call((btn) => {
                        btn.append(() => _CreateElementFromHTML(ic_done))
                    })

            })
            .merge(bubblesgroup)
            .on('click', function (_, d) {
                if (d.status == EStatusPhoto.UPLOADED) return;

                const isActive = d.status == EStatusPhoto.SELECTED;
                d.status = isActive ? EStatusPhoto.NOT_DEFINED : EStatusPhoto.SELECTED;
                select(this).attr("class", "photo-item status-photo-" + d.status);
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                elemnt.attr("class", "photo-item status-photo-" + datum.status);

                elemnt.select(".photo").attr("src", datum.url)
                elemnt.select(".ic_edit")
                    .on("click", (e) => {
                        e.stopPropagation();

                        new ImageViewer()
                            .SetImage(datum.url)
                            .AddEditOptions()
                            .OnSuccessCompletion((file) => {
                                datum.url = file.b64
                                fetch(file.b64)
                                    .then(response => response.blob())
                                    .then(blob => {
                                        datum.file = blob;
                                        elemnt.select(".photo").attr("src", datum.url);
                                    });
                            })
                    })
            });
    }

    protected AddSaveBtn() {
        super.AddSaveBtn();

        const self = this;
        this.footerContainer.append("div").classed("btn-take-pic", true)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_cam))
                    .style("height", "100%")
                    .style("width", "100%");
            })
            .on("click", () => {
                _LoadFileManager({
                    multiple: true,
                    // DOTEST Es necsario en todas las implementaciones
                    // * capture=true evita que aparezca el selector de fotos, solo abre la camara
                    // * capture=false muestra el selector y camara
                    // ¿Es así en todos los dispositivos iOS?
                    capture: _IS_SAFARI_NAVIGATOR ? false : true,
                    accept: _SERVER_IMAGES_ACCEPT.toString(),
                    onChange() {
                        LOADING.Show();
                        return true;
                    },
                    onLoadFile(b64, file) {
                        const objPhoto: IPhotoItem = {
                            id: Date.now(),
                            file: file,
                            url: b64,
                            status: EStatusPhoto.NOT_DEFINED
                        };

                        self.listPhotos.push(objPhoto);

                        self.SetGridPhotos();
                    },
                    onFinishLoadFiles() {
                        LOADING.Dismiss();
                    },
                })
            });
    }

    protected OnSaveClick(): void {
        if (this.childList.length === 0) {
            ShowToast(_L("photo.title"), _L("photo.alumno_required"), "info");
            return;
        }

        if (this.listPhotos.length === 0) {
            ShowToast(_L("photo.title"), _L("photo.loadphoto_required"), "info");
            return;
        }

        let photosToUpload = this.listPhotos.filter((datum) => datum.status == EStatusPhoto.SELECTED);
        if (photosToUpload.length == 0) {
            ShowToast(_L("photo.title"), _L("photo.selectphoto_required"), "info");
            return;
        }

        LOADING.Show();

        let idsChild = this.childList.map((d) => d.IdChild);
        this.UploadPhoto(0, photosToUpload, idsChild);
    }

    UploadPhoto(indexPhoto: number, photosToUpload: IPhotoItem[], idsChild: number[]) {
        let datumPhoto = photosToUpload[indexPhoto];

        if (datumPhoto !== undefined && datumPhoto !== null) {
            const dtEvent = new Date();
            _Uploadphoto(idsChild, datumPhoto.file, dtEvent, this.IS_EXTEMPORAL, (STATUS) => {
                if (STATUS === 1)
                    datumPhoto.status = EStatusPhoto.UPLOADED;
                else
                    datumPhoto.status = EStatusPhoto.ERROR;

                this.SetGridPhotos();

                this.UploadPhoto((indexPhoto + 1), photosToUpload, idsChild);
            });

        } else {
            if (photosToUpload.filter((d) => d.status == EStatusPhoto.UPLOADED).length === 0)
                ShowToast(_L("photo.title"), _HttpMsg("evento/RegistrarFotoV2", -1), "error");
            else
                ShowToast(_L("photo.title"), _HttpMsg("evento/RegistrarFotoV2", 1), 'success');

            let totalUp = this.listPhotos.filter((d) => d.status == EStatusPhoto.UPLOADED).length;
            if (totalUp === this.listPhotos.length)
                _HistoryBack();

            LOADING.Dismiss();
        }
    }

}
