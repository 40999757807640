import labels from '../resouces/labels-es.json';
import htmllabels from '../resouces/http-es.json';

const LABELSEPARATOR = ".";
const LABELTAGS: { [k in TLabelTag]: string } = Object.keys(labels).reduce((res, g) => {
    for (let l in labels[g])
        res[g + LABELSEPARATOR + l] = labels[g][l];
    return res;
}, {}) as any;

type TLabels = typeof labels;
type TLabelGroup = keyof TLabels & string;
type TLabelGroupTags<G extends TLabelGroup> = keyof TLabels[G] & string;
type TLabelTag = {
    [G in TLabelGroup]: {
        [K in TLabelGroupTags<G>]: `${G}${typeof LABELSEPARATOR}${K}`
    }[TLabelGroupTags<G>]
}[TLabelGroup];

/**
 * @param tag
 * @param args reemplazan a "_" en la cadena
 * @returns `string`
 */
export function _Label(tag: TLabelTag, ...args: any[]) {
    let lbl = LABELTAGS[tag];
    if (args?.length) for (let arg of args) {
        lbl = lbl.replace("_", arg);
    }
    return lbl + "";
}

const HTTPSEPARATOR = "/";
const HTTPTAGS: { [k in (THTTPSrvTag | THTTPDefaultsGroup | THTTPActionsTags)]: string } = Object.keys(htmllabels).reduce((res, g) => {
    if (g == <THTTPDefaultsGroup>"default")
        res[g] = htmllabels[g];
    else for (let l in htmllabels[g]) {
        if (g == <THTTPActionsGroup>"default.action")
            res[g + LABELSEPARATOR + l] = htmllabels[g][l];
        else
            res[g + HTTPSEPARATOR + l] = htmllabels[g][l];
    }
    return res;
}, {}) as any;

type THTTPLabels = typeof htmllabels;
type THTTPDefaultsGroup = keyof Pick<THTTPLabels, "default">;
type THTTPActionsGroup = keyof Pick<THTTPLabels, "default.action">;
type THTTPNOSERVICESGroups = THTTPDefaultsGroup | THTTPActionsGroup;
type THTTPSrvGroups = keyof Omit<THTTPLabels, THTTPNOSERVICESGroups> & string;
type THTTPLabelsGroupTags<G extends keyof THTTPLabels> = keyof THTTPLabels[G] & string;

type THTTPSrvTag = {
    [G in THTTPSrvGroups]: {
        [K in THTTPLabelsGroupTags<G>]: `${G}${typeof HTTPSEPARATOR}${K}`
    }[THTTPLabelsGroupTags<G>]
}[THTTPSrvGroups];

type THTTPActionsTags = {
    [G in THTTPActionsGroup]: {
        [K in THTTPLabelsGroupTags<G>]: `${G}${typeof LABELSEPARATOR}${K}`
    }[THTTPLabelsGroupTags<G>]
}[THTTPActionsGroup];

/**
 * @param tag
 * @param result
 * @param defaultaction
 * @param args reemplazan a "_" en la cadena
 * @returns `string`
 */
export function _HttpMsg(tag: THTTPSrvTag, result: number, defaultaction?: keyof THTTPLabels["default.action"], ...args: any[]) {
    let status: keyof typeof htmllabels["default"] = result > 0 ? "success" : "fail";
    let message: string;
    if (HTTPTAGS[tag]) {
        message = HTTPTAGS[tag][result];
        if (!message)
            message = HTTPTAGS[tag][status];

        if (args?.length) for (let arg of args)
            message = message.replace("_", arg);
    }
    if (!message && defaultaction) {
        const tagaction = (<THTTPActionsGroup>"default.action") + LABELSEPARATOR + defaultaction;
        message = HTTPTAGS[tagaction] ? HTTPTAGS[tagaction][status] : null;
    }
    if (!message) {
        message = HTTPTAGS["default"][status] || "";
    }
    return message;
}

const _L = _Label;
export default _L;

// export function _Lbl<G extends TGroup>(group: G, tag: TGroupTags<G>) {
//     const grupo = labels[group];
//     if (!grupo) return "";
//     return grupo[tag] || "";
// }

// export function _Tag(tag: TTags): string {
//     const [group, key] = tag.split("_");
//     return _Lbl(group as any, key as any);
// }