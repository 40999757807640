import { lastInstance } from "../../routes/UIManager"
import { LOADING } from "../../ui/components/Loading"
import { Login } from "../../ui/pages/Login"
import { _Logout } from "../../utils/Device"
import { _APP_DATA } from "../AppData"
import { DataUtilLocalStorage } from "./LocalStorage"

export namespace ChangeSesionController {
    interface IUserSesionRequired {
        idRequired: number
        user: string
        pwd: string
    }

    export async function _SaveAndEvalRequiredSesion(userRequired: IUserSesionRequired): Promise<boolean> {
        if (!userRequired || !userRequired.idRequired)
            return false

        DataUtilLocalStorage._SetItem("sesionadvanced", "userrequired", JSON.stringify(userRequired))
        console.warn("ChangeSesionController 1.userRequired", userRequired);
        LOADING.Show()

        const { idRequired } = userRequired
        userRequired = null
        const dbUser: IUsuario = _APP_DATA.userData // await DataUtil._GetInfoUsuario().catch(err => ([null, null]))

        if (dbUser?.IdUsuario == idRequired) {
            DataUtilLocalStorage._RemoveItem("sesionadvanced", "userrequired")
            LOADING.Dismiss()
            return true
        }
        console.warn("ChangeSesionController 2.userExist", dbUser?.IdUsuario);
        if (dbUser?.IdUsuario) {
            _Logout()
            return true
        }
        console.warn("ChangeSesionController 4.StartSesion");
        _EvalAndStartRequiredSesion()
        return true
    }

    /** Solo se invoca desde el Login */
    export function _EvalAndStartRequiredSesion(): boolean {
        const savedValue = DataUtilLocalStorage._GetItem("sesionadvanced", "userrequired")
        console.warn("ChangeSesionController 4.001", savedValue);
        if (!savedValue) return false
        setTimeout(() => {
            const userRequired: IUserSesionRequired = JSON.parse(savedValue)

            console.warn("ChangeSesionController 4.FromLogin", !!lastInstance);
            DataUtilLocalStorage._RemoveItem("sesionadvanced", "userrequired")
            Login._IniciarLoginExtern(lastInstance as Login, userRequired.user, userRequired.pwd)
        }, 500);
        return true
    }
}