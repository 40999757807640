import { _APP_DATA } from "./AppData";
import { IGroup } from "./models/Entities";
import { _SetInfoUser } from "./services/UserInfo";
import { _SaveUserData } from "../utils/Device";

export function _SetGroupInUserInfo(datum: IGroup) {
    _APP_DATA.userData.IdGrupo = datum.IdGrupo;
    _APP_DATA.userData.IdKinder = datum.IdEscuela;
    _APP_DATA.userData.HoraInicio = datum.HoraInicio;
    _APP_DATA.userData.HoraFin = datum.HoraFin;

    _APP_DATA.userData.DtHoraInicio = datum.HoraInicio != "" ? new Date(new Date(_APP_DATA.userData.HoraInicio).toLocaleString("en-US", { timeZone: _APP_DATA.userData.ZonaHoraria })) : null;
    _APP_DATA.userData.DtHoraFin = datum.HoraFin != "" ? new Date(new Date(_APP_DATA.userData.HoraFin).toLocaleString("en-US", { timeZone: _APP_DATA.userData.ZonaHoraria })) : null;

    _APP_DATA.userData.IdNivel = datum.IdNivel;
    _APP_DATA.userData.NombreGrupo = datum.NombreGrupo;
    _APP_DATA.userData.NombreNivel = datum.NombreNivel;

    _UpdateUserInfo();
}


export function _UpdateUserInfo(userData?: IUsuario) {
    userData = userData || _APP_DATA.userData;

    _SetInfoUser(userData);
    _SaveUserData(userData);
}