export class CheckBox {

    public element: TSelection<"div">;
    private inputCheck: TSelection<"input">;
    private label: TSelection<"label">;


    constructor(container: TSelection<"div">) {
        this.element = container.append("div").classed("checkbox-item", true)
        this.inputCheck = this.element.append("input")
            .attr("type", "checkbox")

        this.label = this.element.append("label")
    }

    public SetIdentifier(id: string): CheckBox {
        this.inputCheck.attr("id", id)
            .attr("name", id)
            .attr("value", id);

        this.label.attr("for", id)

        return this;
    }

    public SetLabel(label: string): CheckBox {
        this.label.text(label);
        return this;
    }

    public IsChecked(): boolean {
        return !!this.inputCheck.node()?.checked;
    }

    public SetChecked(val: boolean): this {
        this.inputCheck.node().checked = val;
        return this;
    }

    public SetValue(val: any): CheckBox { // NOTE Verificar usos
        this.inputCheck.attr("value", val);
        return this;
    }

    public AddIcon(icon: string): CheckBox {
        this.element.insert("img", "label").attr("src", icon)
            .on("click", () => {
                this.inputCheck.node()?.click();
            });

        return this;
    }

    public GetValue(): string {
        return this.inputCheck.node()?.value;
    }

}