import { Selection, pointer, select } from "d3";
import { _IsMobileDevice } from "../../utils/Device";

export function _Click(seleccion: Selection<any, any, any, any>, completion: (this: SVGGElement | any, evt?: any, datum?: any) => void) {
    if (_IsMobileDevice()) {
        if (!completion) {
            seleccion.on("touchstart", null)
                .on("touchend", null);
        } else {
            seleccion
                .on("touchstart", function (evt, _) {
                    select(this).property("start_position", pointer(evt.changedTouches[0]));
                    select(this).property("start_ms", new Date().getTime());
                }, false)
                .on("touchend", function (evt, datum) {
                    OnClickUp(completion, evt, datum, this);
                }, false);
        }
    } else {
        if (!completion) seleccion.on("click", null);
        else {
            seleccion.on("click", function (evt, datum) {
                if (evt.defaultPrevented) return;

                completion.bind(this)(evt, datum);

                if (evt.cancelable) evt.preventDefault();
            }, false);
        }
    }
}

function OnClickUp(fnc_callback_evento: Function, evt: any, datum: any, _this: any): void {
    if (evt.defaultPrevented) return;

    const _element = select(_this)
    const start_position = _element.property("start_position");
    const start_ms = _element.property("start_ms");
    const end_pos = pointer(evt.changedTouches[0]);

    if (start_ms && start_position) {
        const MARGEN_DIF = 8;

        start_position[0] = Math.round(start_position[0]);
        start_position[1] = Math.round(start_position[1]);
        end_pos[0] = Math.round(end_pos[0]);
        end_pos[1] = Math.round(end_pos[1]);

        const dif_x = (start_position[0] - end_pos[0])
        const dif_y = (start_position[1] - end_pos[1]);
        const dif_time = Date.now() - start_ms <= 1000;

        // console.warn(dif_x, dif_y)
        // console.warn(dif_x >= -MARGEN_DIF, dif_x <= MARGEN_DIF, dif_y >= -MARGEN_DIF, dif_y <= MARGEN_DIF, dif_time);
        // console.warn((dif_x >= -MARGEN_DIF && dif_x <= MARGEN_DIF), (dif_y >= -MARGEN_DIF && dif_y <= MARGEN_DIF), dif_time);

        if ((dif_x >= -MARGEN_DIF && dif_x <= MARGEN_DIF) && (dif_y >= -MARGEN_DIF && dif_y <= MARGEN_DIF) && dif_time) {
            fnc_callback_evento.bind(_this)(evt, datum);
        }
    }

    _element.property("start_position", null);
    _element.property("start_ms", null);

    if (evt.cancelable)
        evt.preventDefault();
}