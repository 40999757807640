import { Selection, select } from "d3"
import '../../../styles/components/Send-file.scss'
import { Button } from "./Button";
import { FILE_TYPE_FILES_CHAT } from "../../data/services/Chats";
import _L from "../../utils/Labels";
import { _CreateElementFromHTML } from "../utils/General";

interface IInfoSendFile extends File {
    extension: string,
    description: string
}

export class ViewSendFile {

    private divContainer: Selection<HTMLDivElement, any, any, any>;
    private imgContainer: Selection<HTMLDivElement, any, any, any>;
    private txtTitle: Selection<HTMLHeadingElement, any, any, any>;
    private txtSize: Selection<HTMLHeadingElement, any, any, any>;

    private file: IInfoSendFile;
    private txtDescription: Selection<HTMLInputElement, any, any, any>;


    private OnConfirm: (file: IInfoSendFile) => void;

    constructor() {
        this.divContainer = select("body").append("div").classed("send-file", true);
        this.divContainer.on("click", () => {
            this.OnDestroy();
        });

        this.CreateDialog();
    }

    private CreateDialog() {
        const dialogContainer = this.divContainer.append("div").classed("dialog-container", true);
        dialogContainer.on("click", (ev) => ev.stopPropagation());

        dialogContainer.append("h2").classed("title", true).text(_L("general.sendfile"));

        const fileContainer = dialogContainer.append("div").classed("file-container", true);
        this.imgContainer = fileContainer.append("div").classed("filetype-img", true);
        const dTexts = fileContainer.append("div").classed("text-container", true);
        this.txtTitle = dTexts.append("h3");
        this.txtSize = dTexts.append("h4");

        this.txtDescription = dialogContainer.append("input").attr("type", "text").classed("input__field", true);

        const footer = dialogContainer.append("div").classed("footer", true);
        new Button(footer)
            .Title(_L("general.acepta"))
            .Classed("btn-dialog-footer right")
            .OnClick(() => {
                this.OnDestroy();

                this.file.description = this.txtDescription?.node()?.value || "";

                if (this.OnConfirm) this.OnConfirm(this.file);
            });
    }

    public SetFile(file: File): ViewSendFile {
        if (!file) return this;

        this.file = file as any;

        this.txtTitle.text(file.name);
        const kb = file.size / 1024;
        if (kb < 1024)
            this.txtSize.text(kb.toFixed(1) + " KB");
        else {
            const mb = kb / 1024;
            this.txtSize.text(mb.toFixed(1) + " MB");
        }

        const extension = file.name.split(".").pop() || "";
        this.file.extension = extension;

        let objFiletype = FILE_TYPE_FILES_CHAT.get("." + extension);
        if (!objFiletype) objFiletype = FILE_TYPE_FILES_CHAT.get("unknown");

        this.imgContainer.append(() => _CreateElementFromHTML(objFiletype.icon || ""))
            .style("height", "var(--sze-icon-send-file)")
            .style("width", "var(--sze-icon-send-file)");


        return this;
    }

    public Dismiss() {
        this.divContainer.remove();
    }

    public SetOnConfirm(completion: (file: IInfoSendFile) => void): ViewSendFile {
        this.OnConfirm = completion;
        return this;
    }

    public OnDestroy() {
        this.divContainer.remove();
    }
}

