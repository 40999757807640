import "../../../styles/pages/Event-humor.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import ic_done_selected from "/icons/ic_done_selected.svg?raw"
import { EEventId, EHumorID, IConfiguration } from "../../data/models/Entities";
import { ShowToast } from "../components/Toast";
import { Selection, select } from "d3";
import { _mapCommentByEvent, _mapHumorValues } from "../../data/services/Event";
import { LOADING } from "../components/Loading";
import { _LoadConfig, _mapConfiguration } from "../../data/services/Configuration";
import { _APP_DATA } from "../../data/AppData";
import { Input } from "../components/Input";
import _L from "../../utils/Labels";
import { _CreateElementFromHTML } from "../utils/General";

export class EventHumor extends BaseUIEvent {

    private txtComment?: Input;
    private humorSelected: { idEvent: EHumorID, label: string, iconSVGRaw: string };

    constructor() {
        super('ui-event-humor', EEventId.HUMOR);

        LOADING.Show();

        this.CreateLisHumor();
        this.AddSaveBtn();
        this.LoadData();
    }

    private LoadData() {
        _LoadConfig(_APP_DATA.userData.IdNivel, (_: number, __: Array<IConfiguration>) => {
            let datum = Array.from(_mapConfiguration.values()).filter(o => o.Tipo == EEventId.HUMOR);
            let estados = datum[0].Valores;
            let moods = Array.from(_mapHumorValues.values());

            for (let i = 0; i < estados.length; i++) {
                let estado =
                    estados[i];
                moods[i].label = estado;
            }

            this.CreateLisHumor();


            LOADING.Dismiss();
        });
    }

    private CreateLisHumor() {
        this.bodyContainer.selectAll("*").remove();
        const divIcons = this.bodyContainer.append("div").classed("div-icons", true);

        const listHumor = Array.from(_mapHumorValues.values());
        let row: Selection<HTMLDivElement, any, any, any>;
        const self = this;
        listHumor.forEach((item, i) => {
            if (i % 4 == 0) {
                row = divIcons.append("div").classed("row-humor-icon", true);
            }

            const itemIc = row.append("div")
                .classed("item-icon", true)
                .datum(item).call((btn) => {
                    btn.insert("div").classed("active-indicator", true).html(ic_done_selected)
                });
            itemIc.append(() => _CreateElementFromHTML(item.iconSVGRaw));
            itemIc.append("h3").classed("txt-label", true).text(item.label)
            itemIc.on("click", function (_, d) {
                divIcons.selectAll(".item-icon.active").classed("active", false);

                select(this).classed("active", true);
                self.humorSelected = d;
            });
        });

        let listComments = _mapCommentByEvent.get(EEventId.HUMOR) || [];
        this.txtComment = new Input(this.bodyContainer, { inputType: 'textarea', concatValueSelected: true })
            .SetPlaceholder(_L("general.comentario"))
            .SetDefaultOptions(listComments.map(o => o.Mensaje));
    }

    protected OnSaveClick(): void {
        if (!this.humorSelected) {
            ShowToast(_L("humor.title"), _L("humor.humor_required"), 'info')
            return;
        }
        if (!this.txtComment.Validate()) return;

        const comment = this.txtComment?.value.trim() || "";


        this.AddCommonGralEvent({
            titleToast: _L("humor.title"),
            evtID: EEventId.HUMOR,
            comment: comment,
            category: 0,
            identifier: this.humorSelected.idEvent
        });
    }
}