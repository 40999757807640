export namespace DataUtilLocalStorage {
    type TValueSave = (number | string | boolean | Date | null);
    type TValueResult = (string | undefined | null);

    const SEPARATOR = "_";

    export function _SetItem(group: string, key: string, value: TValueSave) {
        let finalKey = GetFullKey(group, key);
        localStorage.setItem(finalKey, value + "");
        return DataUtilLocalStorage;
    }

    export function _GetItem<T = undefined>(group: string, key: string, defaultValue?: T): TValueResult | T {
        let finalKey = GetFullKey(group, key);
        let val = localStorage.getItem(finalKey);

        if (val == (undefined + "")) {
            return defaultValue != null ? defaultValue : undefined;
        }
        else if (val == (null + "")) {
            return defaultValue != null ? defaultValue : null;
        }
        return val == null && defaultValue != null ? defaultValue : val;
    }

    export function _HasItem(group: string, key: string) {
        let finalKey = GetFullKey(group, key);
        let val = localStorage.getItem(finalKey);
        return (val !== null);
    }

    export function _RemoveItem(group: string, key: string) {
        let finalKey = GetFullKey(group, key);
        localStorage.removeItem(finalKey);
        return DataUtilLocalStorage;
    }

    export function _RemoveGroup(group: string) {
        let keys: string[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.startsWith(group + SEPARATOR)) {
                keys.push(key);
            }
        }
        keys.forEach(key => localStorage.removeItem(key));
        return DataUtilLocalStorage;
    }

    export function _RemoveAll() {
        localStorage.clear();
        return DataUtilLocalStorage;
    }

    export function _GetAllItems(group?: string) {
        let items = new Map<string, string>();
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            let val = localStorage.getItem(key);
            if (group && key.startsWith(group + SEPARATOR)) {
                items.set(key.replace(group + SEPARATOR, ""), val);
            }
            else if (!group) {
                items.set(key.replace(group + SEPARATOR, ""), val);
            }
        }
        return items;
    }

    function GetFullKey(group: string, key: string) {
        return group + SEPARATOR + key;
    }
}