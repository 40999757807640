import "../../../styles/pages/Event-millestone.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { EEventId, EEstadoLogro, ILogro, IAsignacionChildLogro } from "../../data/models/Entities";
import { select, timeFormat } from "d3";
import { _EditAssignmentStatus, _LoadAssignInfo, _LoadCategoryHits, _LoadLogroInfo, _mapAssignChildLogro, _mapCategoryHit, _mapLogros } from "../../data/services/Hits";
import { CheckBox } from "../components/CheckBox";
import ic_i from '/icons/I.svg'
import ic_a from '/icons/A.svg'
import ic_t from '/icons/T.svg'
import ic_medal from '/icons/ic_medal.svg'
import { ToImageSvgElement } from "../utils/ImageSVG";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";
import _L, { _HttpMsg } from "../../utils/Labels";

interface IDataMilles {
    eLogro: ILogro;
    eAsignacion: IAsignacionChildLogro;
    controlCheck?: CheckBox;
}

interface IHitMilles {
    label: string;
    milles: IDataMilles[];
}

export class EventMillestone extends BaseUIEvent {

    private listOptioTab: { label: string, id: EEstadoLogro }[] = [
        { label: _L('milestone.registrado'), id: EEstadoLogro.REGISTRADO },
        { label: _L('milestone.iniciado'), id: EEstadoLogro.INICIADO },
        { label: _L('milestone.avanzado'), id: EEstadoLogro.AVANZADO },
        { label: _L('milestone.terminado'), id: EEstadoLogro.TERMINADO }
    ];
    private tabIdSelected: EEstadoLogro = EEstadoLogro.REGISTRADO;
    private frmtTime;

    private auxMapMailestone: Map<EEstadoLogro | -1, Map<number, Array<IDataMilles>>> = new Map();
    private mailestones: Map<number, IHitMilles[]> = new Map();

    constructor() {
        super('ui-event-millestone', EEventId.MILLESTONE);

        LOADING.Show();

        this.frmtTime = timeFormat("%d/%m/%Y %I:%M %p");

        this.CreateHeaderHit();
        this.ShowEmptyHits();
        this.AddSaveBtn();

        this.LoadData();
    }

    private LoadData() {
        let child = this.childList[0];

        _LoadAssignInfo(child.IdChild, () => {
            _LoadLogroInfo(() => {
                _LoadCategoryHits(() => {
                    LOADING.Dismiss();

                    this.CreateListAssignMiles();
                    this.OnSelectTab();
                });
            });
        });
    }

    private CreateHeaderHit() {
        const headerHits = this.bodyContainer.append("div").classed("header-tabs", true);
        const hitNames = headerHits.selectAll<HTMLDivElement, any>(".item-tab-name").data(this.listOptioTab);
        const self = this;

        hitNames.exit().remove();
        hitNames.enter().append("div")
            .classed("item-tab-name", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("h4").classed("name-hit", true);
            })
            .merge(hitNames)
            .on("click", function (_, d) {
                headerHits.selectAll(".item-tab-name.active").classed("active", false);
                select(this).classed("active", true);

                // self.AddCategories();
                self.tabIdSelected = d.id;
                self.OnSelectTab();

            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.select(".name-hit").text(datum.label);
                if (this.tabIdSelected == datum.id)
                    elemnt.classed("active", true);
            });
    }

    private CreateListAssignMiles() {
        let child = this.childList[0];
        let childAssign = _mapAssignChildLogro.get(child.IdChild);
        if (!childAssign) return;

        this.auxMapMailestone.clear();
        let arrAssign = Array.from(childAssign.data.values());
        let listChildAsig = arrAssign.sort((a, b) => a.Estado - b.Estado);

        for (let index = 0; index < listChildAsig.length; index++) {
            const itemAssign = listChildAsig[index];
            let eLogro = _mapLogros.get(itemAssign.IdLogro);

            if (eLogro)
                this.AddItemMailestone(itemAssign.Estado, eLogro, itemAssign);
        }


        this.mailestones.clear();
        this.auxMapMailestone.forEach((datum, key) => {
            let millestoneLogro: Map<EEstadoLogro, IHitMilles> = new Map();
            datum.forEach((list, key_cat) => {
                let e_categoria = _mapCategoryHit.get(key_cat);

                if (!millestoneLogro.has(key_cat))
                    millestoneLogro.set(key_cat, { label: (e_categoria ? e_categoria.Nombre : _L("general.nofound")), milles: [] });

                list.forEach((item) => {
                    millestoneLogro.get(key_cat)?.milles.push({ eLogro: item.eLogro, eAsignacion: item.eAsignacion })
                });
            });

            this.mailestones.set(key, Array.from(millestoneLogro.values()));
        });
    }

    private AddItemMailestone(idEstado: EEstadoLogro | -1, itemLogro: ILogro, itemAsignacion: IAsignacionChildLogro) {
        let logroAuxiliar = this.auxMapMailestone.get(idEstado);
        if (!logroAuxiliar) {
            this.auxMapMailestone.set(idEstado, new Map());
            logroAuxiliar = this.auxMapMailestone.get(idEstado);
        }

        let categorias = logroAuxiliar?.get(itemLogro.IdCategoria);
        if (!categorias) {
            categorias = [];
            logroAuxiliar?.set(itemLogro.IdCategoria, categorias);
        }

        categorias.push({ eLogro: itemLogro, eAsignacion: itemAsignacion });

        const idStatus = (idEstado == EEstadoLogro.TERMINADO) ? EEstadoLogro.AVANZADO :
            (idEstado == EEstadoLogro.AVANZADO) ? EEstadoLogro.INICIADO :
                (idEstado == EEstadoLogro.INICIADO) ? EEstadoLogro.REGISTRADO : -1;

        if (idStatus > -1)
            this.AddItemMailestone(idStatus, itemLogro, itemAsignacion);
    }


    private OnSelectTab() {
        this.bodyContainer.select(".empty-hits").remove();
        this.bodyContainer.selectAll(".category-item").remove();

        const listMilles = this.mailestones.get(this.tabIdSelected) || [];

        listMilles.forEach(item => {
            this.AddCategoryItem(item);
        });
    }

    private AddCategoryItem(millesInfo: IHitMilles) {
        const categoryitem = this.bodyContainer.append("div").classed("category-item", true)

        categoryitem.append("h2").text(millesInfo.label);

        const subitems = categoryitem.append("div").classed("subitems", true);
        millesInfo.milles.forEach(item => {
            const hasCheck = (this.tabIdSelected !== EEstadoLogro.TERMINADO && this.tabIdSelected === item.eAsignacion.Estado);
            if (hasCheck) {
                item.controlCheck = new CheckBox(subitems)
                    .SetIdentifier(item.eLogro?.Nombre || "")
                    .SetValue(item.eAsignacion.IdLogro)
                    .SetLabel(item.eLogro?.Nombre || "");
            } else {


                const icAndLbl = subitems.append("div").classed("ic-and-label", true);
                const icImg = icAndLbl.append("img");

                const titleAndDate = icAndLbl.append("div").classed("title-and-date", true);
                titleAndDate.append("label").text(item.eLogro.Nombre).classed("title", true);
                const subtitle = titleAndDate.append("label").classed("subtitle", true);

                if (item.eAsignacion.Estado === EEstadoLogro.TERMINADO)
                    subtitle.text(_L("milestone.terminado") + ": " + (item.eAsignacion.Terminado ? this.frmtTime(item.eAsignacion.Terminado) : ""));
                else if (item.eAsignacion.Estado === EEstadoLogro.AVANZADO)
                    subtitle.text(_L("milestone.avanzado") + ": " + (item.eAsignacion.Avanzado ? this.frmtTime(item.eAsignacion.Avanzado) : ""));
                else if (item.eAsignacion.Estado === EEstadoLogro.INICIADO)
                    subtitle.text(_L("milestone.iniciado") + ": " + (item.eAsignacion.Iniciado ? this.frmtTime(item.eAsignacion.Iniciado) : ""));
                else subtitle.attr("display", "none");


                let iconStatus = (item.eAsignacion.Estado === EEstadoLogro.TERMINADO) ? ic_t :
                    (item.eAsignacion.Estado === EEstadoLogro.AVANZADO) ? ic_a :
                        (item.eAsignacion.Estado === EEstadoLogro.INICIADO) ? ic_i : "";
                icImg.attr("src", iconStatus);
            }
        });
    }

    private ShowEmptyHits() {
        const emptyHits = this.bodyContainer.append("div").classed("empty-hits", true)

        let dImg = emptyHits.append("div")
            .classed("img-event-icon", true)
            .classed("event-icon-" + EEventId.MILLESTONE, true)
        ToImageSvgElement(ic_medal, dImg);

        emptyHits.append("h2").text(_L("milestone.aunsinlogros"));
    }


    protected OnSaveClick(): void {
        const millesSelectedIds: number[] = [];
        const listMilles = this.mailestones.get(this.tabIdSelected) || [];
        listMilles.forEach(item => {
            item.milles.forEach(mill => {
                if (mill.controlCheck?.IsChecked()) {
                    millesSelectedIds.push(mill.eLogro.IdLogro);
                }
            })
        });

        if (millesSelectedIds.length < 1) {
            ShowToast(_L("milestone.title"), _L("milestone.logro_required"), 'info');
            return;
        }

        let child = this.childList[0];
        const dtEvent = this.GetDateExtempByChild(child);

        LOADING.Show();

        let idStatus = this.tabIdSelected;

        idStatus = (idStatus === EEstadoLogro.REGISTRADO) ? EEstadoLogro.INICIADO :
            (idStatus === EEstadoLogro.INICIADO) ? EEstadoLogro.AVANZADO :
                (idStatus === EEstadoLogro.AVANZADO) ? EEstadoLogro.TERMINADO : idStatus;

        this.SaveMilestone(0, child.IdChild, dtEvent, idStatus, millesSelectedIds);
    }

    private SaveMilestone(index: number, idChild: number, dtEvent: Date, idStatus: number, idsMillestone: number[]) {
        let idMilestone = idsMillestone.shift();
        if (idMilestone !== undefined && idMilestone !== null) {

            _EditAssignmentStatus(idChild, idMilestone, idStatus, dtEvent, this.IS_EXTEMPORAL, (STATUS) => {
                if (STATUS === 1)
                    this.SaveMilestone((index + 1), idChild, dtEvent, idStatus, idsMillestone);
                else {
                    ShowToast(_L("milestone.title"), _HttpMsg("logro/EditarEstadoAsignacionV2", STATUS), 'error');

                    _LoadAssignInfo(idChild, () => {
                        this.CreateListAssignMiles();
                        this.OnSelectTab();
                    });

                    LOADING.Dismiss();
                }
            });
        } else {
            LOADING.Dismiss();
            ShowToast(_L("milestone.title"), _HttpMsg("logro/EditarEstadoAsignacionV2", 1), 'success');
            history.back();
        }
    }
}