import { apiEditAssignmentStatus, ref_logro_ObtenerCategorias, ref_logro_ObtenerLogros, ref_logro_ObtenerLogrosAsignacionesChild } from "../API";
import { _APP_DATA } from "../AppData";
import { IApiResult, IAsignacionChildLogro, ILogro, ICategoriaLogro } from "../models/Entities";
import { RequestData } from "../utils/RequestData";


export var _mapAssignChildLogro: Map<number, { childId: number, max: string | null, data: Map<number, IAsignacionChildLogro> }> = new Map();
export function _LoadAssignInfo(childId: number, callback: (result: Array<IAsignacionChildLogro>) => void) {
    const assigns = _mapAssignChildLogro.get(childId);
    const dtmax = assigns ? assigns.max : null;
    const params = {
        IdChild: childId,
        Modificacion: dtmax || null
    }

    new RequestData<IApiResult<IAsignacionChildLogro[]>>(ref_logro_ObtenerLogrosAsignacionesChild)
        .Params(params)
        .Completion((response, error) => {
            const status = response ? response.Resultado : -111;
            if (error)
                console.log(error.name, error.message);

            const resultData = status == 1 ? response?.Datos || [] : [];

            let mapChildAssig = _mapAssignChildLogro.get(childId);
            if (!mapChildAssig) mapChildAssig = { childId: childId, max: null, data: new Map() };

            if (resultData.length > 0)
                mapChildAssig.max = response?.Modificacion || null;


            resultData.forEach(item => {
                if (!item.EnUso) {
                    mapChildAssig?.data.delete(item.IdAsignacion);
                    return;
                }

                item.IdChild = childId;
                item.Iniciado = item.Iniciado ? new Date(item.Iniciado) : null;
                item.Avanzado = item.Avanzado ? new Date(item.Avanzado) : null;
                item.Terminado = item.Terminado ? new Date(item.Terminado) : null;

                mapChildAssig?.data.set(item.IdAsignacion, item);
            });

            _mapAssignChildLogro.set(childId, mapChildAssig);

            if (callback) callback(resultData);

        }).Get();
}

export var _mapLogros: Map<number, ILogro> = new Map();
var maxUpdatedLogro: string | null;
export function _LoadLogroInfo(callback: (result: Array<ILogro>) => void) {
    const params = {
        Modificacion: maxUpdatedLogro || null,
        IdKinder: _APP_DATA.userData.IdKinder
    }

    new RequestData<IApiResult<ILogro[]>>(ref_logro_ObtenerLogros)
        .Params(params)
        .Completion((response, error) => {
            const status = response?.Resultado || -111;
            if (error)
                console.log(error.name, error.message);

            const resultData = status == 1 ? response?.Datos || [] : [];

            if (resultData.length > 0)
                maxUpdatedLogro = response?.Modificacion || null;

            resultData.forEach(item => {
                if (item.EnUso) {
                    _mapLogros.set(item.IdLogro, item);
                } else _mapLogros.delete(item.IdLogro);
            });

            if (callback) callback(resultData);
        }).Get();
}

export var _mapCategoryHit: Map<number, ICategoriaLogro> = new Map();
var maxUpdatedCategories: string | null;
export function _LoadCategoryHits(callback: (result: Array<ICategoriaLogro>) => void) {
    const params = {
        Modificacion: maxUpdatedCategories || null,
        IdKinder: _APP_DATA.userData.IdKinder
    }

    new RequestData<IApiResult<ICategoriaLogro[]>>(ref_logro_ObtenerCategorias)
        .Params(params)
        .Completion((response, error) => {
            const status = response?.Resultado || -111;
            if (error)
                console.log(error.name, error.message);

            let resultData: Array<ICategoriaLogro> = [];
            if (status == 1) resultData = response?.Datos || [];

            if (resultData.length > 0)
                maxUpdatedCategories = response?.Modificacion || null;

            resultData.forEach(item => {
                if (item.EnUso) {
                    _mapCategoryHit.set(item.IdCategoria, item);
                } else _mapCategoryHit.delete(item.IdCategoria);
            });

            if (callback) callback(resultData);
        }).Get();
}

export function _EditAssignmentStatus(childId: number, logroId: number, newStatus: number, dtEvent: Date, isExtemporal: boolean, callback: (result: number) => void) {
    let params = {
        IdChild: childId,
        IdLogro: logroId,
        Estado: newStatus,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        ExTemporal: isExtemporal,
        FechaEvento: dtEvent.toISOString()
    }

    new RequestData<IApiResult<number>>(apiEditAssignmentStatus)
        .Params(params)
        .Completion((response, error) => {
            let status = response?.Resultado || -111;
            if (error)
                console.log(error.name, error.message);

            if (callback) callback(status);
        }).Post();
}

export function _ClearHitsData() {
    _mapAssignChildLogro.clear();

    maxUpdatedLogro = null;
    _mapLogros.clear();

    maxUpdatedCategories = null;
    _mapCategoryHit.clear();
}