import { Selection, select, timeDay } from "d3";
import "../../../styles/pages/Report.scss";
import { _APP_DATA } from "../../data/AppData";
import { EEventId, IChild, IConfiguration, IEvent, IResumeEventDetail } from "../../data/models/Entities";
import { _LoadConfig, _mapConfiguration } from "../../data/services/Configuration";
import { _ResumeEvents } from "../../data/services/Event";
import { _LoadDataChild, _mapChilds } from "../../data/services/Kids";
import { _GetKinderEventsArray } from "../../data/services/Kinder";
import { EPhotoSize, _URLChildPhoto } from "../../data/services/Photo";
import { FormatDateToHour } from "../../data/utils/General";
import { _HistoryBack, _PushState } from "../../routes/UIManager";
import _L from "../../utils/Labels";
import { BaseUI } from "../bases/BaseUI";
import { EmptyTag } from "../components/EmptyTag";
import { LOADING } from "../components/Loading";
import { D3BubleChild, _AddBubbleChild } from "../utils/BubbleChild";
import { _CreateElementFromHTML } from "../utils/General";
import ic_back from '/icons/ic_back.svg?raw';
import ic_classroom from '/icons/ic_classroom.svg?raw';

const ID_TAB_ALUMNOS = 0;
type TTab = IEvent | (Omit<IEvent, "id"> & { id: typeof ID_TAB_ALUMNOS })

export class Report extends BaseUI {

    private headerEventsSel: TSelection<"div">;
    private listChildsView: {
        eventsReportSel: TSelection<"div">;
        classroomSel: TSelection<"div">;
    }

    private resumeEvents: Map<EEventId | -1, IResumeEventDetail[]>;
    private tabSelected: TTab | null;

    private emptyTag: EmptyTag

    constructor() {
        super({ addMainLogoOptions: false, addOptionsInHeader: false, className: 'ui-report' });

        LOADING.Show();

        this.headerEventsSel = this.bodyContainer.append("div").classed("header-events-container", true);
        this.listChildsView = {
            eventsReportSel: this.bodyContainer.append("div").classed("list-reports-hour", true),
            classroomSel: this.bodyContainer.append("div").classed("list-childs", true),
        }

        this.CreateHeaderReport();
        this.CreateHeaderEvents();

        this.LoadData();
        this.emptyTag = new EmptyTag(this.listChildsView.classroomSel.node())
    }

    private CreateHeaderReport() {
        // this.headerContainer.selectAll("*").remove();
        const headerReport = this.headerContainer.append("div").classed("header-report", true);

        headerReport
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_back))
            })
            .on("click", () => {
                _HistoryBack();
            });
    }

    private LoadData() {
        _LoadConfig(_APP_DATA.userData.IdNivel, (_: number, __: Array<IConfiguration>) => {
            this.CreateHeaderEvents();

            this.LoadResumeData();

        });
    }

    private LoadResumeData() {
        let ids = Array.from(_mapChilds.values()).map((d) => d.IdChild);
        let dateInit = timeDay.floor(new Date());
        let dateEnd = new Date(new Date(dateInit).setDate(dateInit.getDate() + 1));

        _ResumeEvents(ids, dateInit, dateEnd, (result) => {
            this.resumeEvents = result;

            this.OnSelectEvt();
            LOADING.Dismiss();
        });
    }

    private async CreateHeaderEvents() {
        const listEventOptions: TTab[] = [
            <TTab>{
                id: ID_TAB_ALUMNOS,
                iconSVGRaw: ic_classroom,
                name: _L("report.salon"),
                enabled: true,
            },
            ...await _GetKinderEventsArray()
        ]
        const config = Array.from(_mapConfiguration.values());

        listEventOptions.forEach((item, i) => {
            if (i == 0) return;
            item.enabled = config.findIndex(o => o.Tipo == item.id) > -1;
        });

        listEventOptions

        const optionEvent = this.headerEventsSel.selectAll<HTMLDivElement, IEvent>(".opts-events").data(listEventOptions);
        this.tabSelected = listEventOptions.find(o => o.enabled);

        const self = this;

        optionEvent.exit().remove();
        optionEvent.enter().append("div")
            .classed("opts-events", true)
            .each((d, i, divs) => {
                const elemnt = select(divs[i])
                    .classed("evt-" + d.id, true);

                const dTemp = elemnt.append("div")
                dTemp.append("div")
                    .classed("opt-event", true)
                    .classed("img-event-icon", true);
                // optEvent.append("img").classed("img-opt-event", true);

                dTemp.append("h4").classed("title-ev", true);
            })
            .merge(optionEvent)
            .on('click', function (_, d) {
                const itemElemnt = select(this);
                if (itemElemnt.classed("disabled")) {
                    return;
                }

                self.tabSelected = d;

                self.headerEventsSel.selectAll(".opts-events.selected").classed("selected", false);
                itemElemnt.classed("selected", true);
                self.OnSelectEvt()
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.classed("disabled", !datum.enabled);

                if (this.tabSelected?.id == datum.id)
                    elemnt.classed("selected", true);

                elemnt.select(".opt-event")
                    .classed("event-icon-" + datum.id, true)
                    .html(datum.iconSVGRaw);

                elemnt.select(".title-ev")
                    .classed("title-evt-" + datum.id, true)
                    .text(datum.name)
            });
    }

    private OnSelectEvt() {
        const tabSelected = this.tabSelected;
        if (tabSelected == null) return;
        if (tabSelected.id == ID_TAB_ALUMNOS) {
            this.listChildsView.eventsReportSel.classed("hide", true);
            this.listChildsView.classroomSel.classed("hide", false);
            this.CreateChildsView();
            return;
        }
        this.listChildsView.eventsReportSel.classed("hide", false);
        this.listChildsView.classroomSel.classed("hide", true);
        this.listChildsView.eventsReportSel.attr("class", "list-reports-hour evt-" + this.tabSelected?.id)//Agregar tipo evento seleccionado
        this.CreateListReport();
    }

    private CreateChildsView() {
        _LoadDataChild((_) => {
            const listAlumnos = [..._mapChilds.values()].sort((a, b) => a.GrupoActivo - b.GrupoActivo);
            // LOADING.Dismiss();
            // this.LoadFromMapData();
            this.listChildsView.classroomSel.selectAll<HTMLDivElement, IChild>(".bubble-child")
                .data(listAlumnos)
                .join("div")
                .classed("bubble-child", true)
                .classed("grupo-inactivo", d => d.GrupoActivo < 0)
                .each((d, i, arr) => {
                    const element = arr[i];
                    let img = element.querySelector("img");
                    let childName = element.querySelector(".name-child")
                    if (!img) {
                        img = document.createElement("img");
                        element.append(img);
                        img.onload = () => img.removeAttribute("fail");
                        img.onerror = () => img.setAttribute("fail", "");

                        childName = document.createElement("div");
                        element.append(childName);
                        childName.className = "name-child";
                    }
                    element.onclick = () => this.OnSelectChild(d.IdChild);
                    img.src = d.urlPhoto;
                    childName.textContent = d.Nombre;
                });

            if (listAlumnos.length) {
                this.emptyTag._Remove()
            } else {
                this.emptyTag
                    ._SetParent(this.listChildsView.classroomSel.node())
                    ._SetText(_L("report.no_childs"))
                    ._SetSVGIcon(ic_classroom)
                    ._ShowTag()
            }
        });
    }

    private CreateListReport() {
        const events = this.resumeEvents?.get(this.tabSelected?.id || -1) || [];
        const mapGroupResume: Map<string, { hour: string, time: number, list: IResumeEventDetail[] }> = new Map();

        events.forEach(item => {
            const hrs = FormatDateToHour(item.Inicio);
            if (!mapGroupResume.has(hrs)) mapGroupResume.set(hrs, { hour: hrs, time: new Date(item.Inicio).getTime(), list: [] });

            const lstHrChilds = mapGroupResume.get(hrs)?.list;
            const idx = lstHrChilds?.findIndex(o => o.IdChild == item.IdChild) || 0
            if (idx == -1)
                lstHrChilds?.push(item);
        });

        let listResult = Array.from(mapGroupResume.values());
        listResult.sort((a, b) => a.time - b.time);

        const listReport = this.listChildsView.eventsReportSel.selectAll<HTMLDivElement, any>(".list-report").data(listResult);

        // const self = this;
        listReport.exit().remove();
        listReport.enter().append("div")
            .classed("list-report", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                // let bubble = elemnt.append("div").classed("bubble-group", true);
                elemnt.append("h4").classed("time", true);
                elemnt.append("div").classed("list-container-bubble", true)

            })
            .merge(listReport)
            .on('click', function (_) { })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i])//.select(".bubble-group");
                elemnt.select(".time").text(datum.hour)

                this.CreateItemsReport(elemnt.select(".list-container-bubble"), datum.list)
            });

        if (listResult.length) {
            this.emptyTag._Remove()
        } else {
            this.emptyTag
                ._SetParent(this.listChildsView.eventsReportSel.node())
                ._SetText(_L("reportchild.no_events"))
                ._SetSVGIcon(this.tabSelected.iconSVGRaw)
                ._ShowTag()
        }
    }

    private CreateItemsReport(elemnt: Selection<HTMLDivElement, any, any, any>, childs: IResumeEventDetail[]) {
        const bubblesReport = elemnt.selectAll<HTMLDivElement, any>(".bubbles-report").data(childs);

        // const self = this;
        bubblesReport.exit().remove();
        bubblesReport.enter().append("div")
            .classed("bubbles-report", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                // elemnt.append("img");
                _AddBubbleChild(elemnt);
            })
            .merge(bubblesReport)
            .on('click', (_, d) => {
                this.OnSelectChild(d.IdChild)
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i])//.select(".bubble-group");

                const bubble = <D3BubleChild>elemnt.select("div");
                let child = _mapChilds.get(datum.IdChild);
                if (child) {
                    bubble.PhotoUrl(_URLChildPhoto(child.IdUsuario, EPhotoSize.THUMBNAIL))
                        .Name(child.Nombre)
                        .Genre(child.Sexo);
                }
            });
    }

    private OnSelectChild(idAlumno: number) {
        let child = _mapChilds.get(idAlumno);
        _PushState("reportchild", child);
    }

}