import { Base } from "../ui/bases/Base";
import { Agenda } from "../ui/pages/Agenda";
import { ChatList } from "../ui/pages/ChatList";
import { ClassRoom } from "../ui/pages/ClassRoom";
import { EventActivities } from "../ui/pages/EventActivities";
import { EventComment } from "../ui/pages/EventComment";
import { EventEvaluation } from "../ui/pages/EventEvaluation";
import { EventFood } from "../ui/pages/EventFood";
import { EventHumor } from "../ui/pages/EventHumor";
import { EventHygiene } from "../ui/pages/EventHygiene";
import { EventMillestone } from "../ui/pages/EventMilestone";
import { EventPhoto } from "../ui/pages/EventPhoto";
import { EventSnooze } from "../ui/pages/EventSnooze";
import { Extemporaneos } from "../ui/pages/Extemporaneos";
import { Groups } from "../ui/pages/Groups";
import { Home } from "../ui/pages/Home";
import { Login } from "../ui/pages/Login";
import { Profile } from "../ui/pages/Profile";
import { QRView } from "../ui/pages/QRView";
import { Report } from "../ui/pages/Report";
import { ReportChild } from "../ui/pages/ReportChild";

const routesMap = {
    "login": Login,
    "home": Home,
    "classroom": ClassRoom,
    "groups": Groups,
    'chat': ChatList,
    'agenda': Agenda,
    'report': Report,
    'eventsnooze': EventSnooze,
    'eventsfood': EventFood,
    'eventshygiene': EventHygiene,
    'eventsactivities': EventActivities,
    'eventswin': EventMillestone,
    'eventsphotos': EventPhoto,
    'eventscomments': EventComment,
    'eventshumor': EventHumor,
    'profile': Profile,
    'reportchild': ReportChild,
    'extemporaneo': Extemporaneos,
    'qrview': QRView,
    'eventsevaluation': EventEvaluation,
} as const;

// export type THashParams = {
//     "chat": {
//         group: string,
//         student: string,
//     }
// }

export type THashPath = keyof typeof routesMap;
export const UIs: { [k in THashPath]: typeof Base } = routesMap;
