import '../../../styles/pages/Profile.scss'

import { Selection, select } from "d3";
import { Base } from "../bases/Base";
import ic_exit from '/icons/ic_exit.svg?raw'
import ic_pastel from '/icons/ic_kidi_logo.svg?raw'
import ic_take_pic from '/icons/ic_camera.svg?raw'
import ic_profile_default from '/icons/ic_profile_default.svg'
import { _LoadInfoChild, _UpdateProfile } from '../../data/services/Kids';
import { _LoadFileManager } from '../components/FileManager';
import { ImageViewer } from '../components/ImageViewer';
import { IChild, IInfoChild } from '../../data/models/Entities';
import { EPhotoSize, _URLChildPhoto, _UpdateURLChildPhoto } from '../../data/services/Photo';
import { MOUNTH_NAMES, _GetDateDiff, _SERVER_IMAGES_ACCEPT } from '../../data/utils/General';
import { LOADING } from '../components/Loading';
import { ShowToast } from '../components/Toast';
import _L, { _HttpMsg } from '../../utils/Labels';
import { BotSendWarn } from '../../utils/AlertBot';
import { _HistoryBack } from '../../routes/UIManager';
import { _CreateElementFromHTML } from '../utils/General';
import { _IS_SAFARI_NAVIGATOR } from '../../utils/Device';

export class Profile extends Base {

    private headerContainer: Selection<HTMLDivElement, any, any, any>;
    private bodyContainer: Selection<HTMLDivElement, any, any, any>;
    private imgProfile?: Selection<HTMLImageElement, any, any, any>;

    private childSelected: IChild;
    private infoChild: IInfoChild;

    private txtGroupName: Selection<HTMLHeadingElement, any, any, any>;
    private listBrothersContainer: Selection<HTMLDivElement, any, any, any>;



    constructor() {
        super('profile', true);
        this.childSelected = history.state;
        if (!this.childSelected) {
            _HistoryBack();
            BotSendWarn("PROFILE", `WITHOUT_DATA_CHILD (${this.childSelected})`);
            return;
        }

        this.headerContainer = this.mainContainer.append("div").classed("header", true);
        this.bodyContainer = this.mainContainer.append("div").classed("body", true);

        this.infoChild = { Grupos: [], Hermanos: [] } as any;

        this.CreateHeader();
        this.AddPhotoBirth();
        this.AddGroupsAndBrothers();

        this.OnInit();
    }

    public OnInit(): void {
        this.LoadInfoDataChild();
    }

    private LoadInfoDataChild() {
        _LoadInfoChild(this.childSelected.IdChild, (res) => {
            if (!res) return;

            this.infoChild = res;

            this.infoChild.Hermanos.forEach(item => {
                item.urlPhoto = _URLChildPhoto(item.IdUsuario, EPhotoSize.THUMBNAIL);
            });

            this.SetGroupAndBrothers();
        });
    }


    private CreateHeader() {
        this.headerContainer.append("div")
            .classed("img-exit", true)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_exit))
                    .style("height", "100%")
                    .style("width", "100%");
            })
            .on("click", () => {
                _HistoryBack();
            });
    }

    private AddPhotoBirth() {
        let photoBrth = this.bodyContainer.append("div").classed("photo-birth", true);

        let dPhoto = photoBrth.append("div").classed("photo-profile", true);
        let bubble = dPhoto.append("div").classed("bubble-child", true);
        this.imgProfile = bubble.append("img").classed("prof-child", true)
            .attr("onerror", "this.onerror=null; this.src='" + ic_profile_default + "'");
        const txtName = dPhoto.append("h2").classed("txt-name", true);

        const fullName = this.childSelected.Nombre + " " + this.childSelected.ApPaterno + " " + this.childSelected.ApMaterno;
        txtName.text(fullName);

        this.imgProfile.attr("src", this.childSelected.urlPhoto);

        bubble.on("click", () => this.OnSelectProfile())

        bubble.append("div").classed("wrapper", true)
            .append("div").classed("semi-circle", true)
            .html(ic_take_pic)
            .on("click", (ev) => {
                ev.stopPropagation();
                this.OnChoosePhotoProfile();
            });

        const dBirth = photoBrth.append("div").classed("info-birth", true);
        dBirth.append("div")
            .classed("ic-birth", true)
            .call((birthCake) => {
                birthCake.append(() => _CreateElementFromHTML(ic_pastel))
                    .style("height", "100%")
                    .style("width", "100%");
            })
        const dTexts = dBirth.append("div").classed("txts-date-year", true);
        const txtDate = dTexts.append("h2").classed("txt-secundary", true);
        const txtYears = dTexts.append("h2").classed("txt-secundary", true);


        let birthday = this.childSelected.Nacimiento;
        if (birthday) {
            const dateBirth = (birthday.getDate()) + " de " + (MOUNTH_NAMES[birthday.getMonth()]) + " de " + (birthday.getFullYear()); // FIX_LANGUAGE
            txtDate.text(dateBirth)

            const dn = _GetDateDiff(birthday, new Date())
            let oldYears = dn.years + " "
            if (dn.years == 1)
                oldYears += _L("time.anio");
            else if (!(dn.years == 0 && dn.months > 0))
                oldYears += _L("time.anios");
            if (dn.months > 0)
                oldYears += " " + dn.months + " " + (dn.months == 1 ? _L("time.mes") : _L("time.mess"));

            txtYears.text(oldYears);
        }
    }

    private AddGroupsAndBrothers() {
        const groups = this.bodyContainer.append("div").classed("groups", true);
        groups.append("h2").text(_L("perfil.groups"));
        this.txtGroupName = groups.append("h2").classed("txt-secundary", true);

        const bros = this.bodyContainer.append("div").classed("brothers", true);
        bros.append("h2").text(_L("perfil.brothers"))
        this.listBrothersContainer = bros.append("div").classed("list-brothers", true);

        this.SetGroupAndBrothers();
    }

    private SetGroupAndBrothers() {
        const groupNames = this.infoChild.Grupos.map(o => o.Grupo).join(", ");
        this.txtGroupName.text(groupNames);

        const bubblesChild = this.listBrothersContainer.selectAll<HTMLDivElement, any>(".bubbles-child").data(this.infoChild.Hermanos);

        bubblesChild.exit().remove();
        bubblesChild.enter().append("div")
            .classed("bubbles-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                let bubble = elemnt.append("div").classed("bubble-child", true);
                bubble.append("img").classed("prof-child", true)
                    .attr("onerror", "this.onerror=null; this.src='" + ic_profile_default + "'");
            })
            .merge(bubblesChild)
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                let bubble = elemnt.select(".bubble-child");
                bubble.select("img").attr("src", datum.urlPhoto)
            });
    }

    private OnChoosePhotoProfile() {
        const self = this;
        _LoadFileManager({
            // DOTEST Es necsario en todas las implementaciones
            // * capture=true evita que aparezca el selector de fotos, solo abre la camara
            // * capture=false muestra el selector y camara
            // ¿Es así en todos los dispositivos iOS?
            capture: _IS_SAFARI_NAVIGATOR ? false : true,
            accept: _SERVER_IMAGES_ACCEPT.toString(),
            onLoadFile(b64, file) {
                self.imgProfile?.attr("src", b64); // FIXME con el formato ".nef" el img toma la imagen de onerror

                LOADING.Show();
                _UpdateProfile(self.childSelected.IdChild, file, (res) => {
                    LOADING.Dismiss();
                    const httpMsg = _HttpMsg("alumno/ActualizarFotoPerfil", res);
                    if (res > 0) {
                        ShowToast(_L("perfil.upt_title"), httpMsg)
                        _UpdateURLChildPhoto(self.childSelected.IdChild);
                    } else {
                        ShowToast(_L("perfil.upt_title"), httpMsg, 'error');
                        self.imgProfile?.attr("src", self.childSelected.urlPhoto);
                    }
                });
            }
        })
    }

    private OnSelectProfile() {
        const src = _URLChildPhoto(this.childSelected.IdUsuario, EPhotoSize.PREVIEW);

        if (src) {
            new ImageViewer()
                .SetImage(src);
        }
    }

}