import "../../../styles/pages/Event-snooze.scss";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { Button } from "../components/Button";
import ic_snooze from '/icons/event/use-icon-siesta.svg?raw'
import { ShowToast } from "../components/Toast";
import { EEventId, EStatusSleepChild } from "../../data/models/Entities";
import { Selection, timeDay } from "d3";
import { LOADING } from "../components/Loading";
import { _AddEventSleep } from "../../data/services/Event";
import { _mapChilds } from "../../data/services/Kids";
import { _TimeToInputFmt } from "../../utils/General";
import _L, { _HttpMsg } from "../../utils/Labels";
import { _HistoryBack } from "../../routes/UIManager";
import { _CreateElementFromHTML } from "../utils/General";

export class EventSnooze extends BaseUIEvent {


    private txtBtnContainer?: Selection<HTMLDivElement, any, any, any>;
    private timeContainer?: Selection<HTMLDivElement, any, any, any>;
    private btnSnooze?: Button;

    private txtTimeStart?: Selection<HTMLInputElement, any, any, any>;
    private txtTimeEnd?: Selection<HTMLInputElement, any, any, any>;


    constructor() {
        super('ui-event-snooze', EEventId.SNOOZE);

        this.CheckSleepStatus();

        if (this.IS_EXTEMPORAL)
            this.OnClickAddSnooze();
    }

    private CheckSleepStatus() {
        let isSleeeping = this.childList[0].EstadoEvt === EStatusSleepChild.DORMIDO;
        if (isSleeeping) {
            this.CreateBtnWakeUp();
        } else {
            this.CreateButtons();
        }
    }

    private CreateButtons() {
        this.txtBtnContainer?.remove();
        this.footerContainer.selectAll("*").remove();
        this.bodyContainer.select(".wake-up").remove();

        this.txtBtnContainer = this.bodyContainer.append("div").classed("text-btn-container", true);

        const btns = this.txtBtnContainer.append("div").classed("btns-container", true)

        if (!this.IS_EXTEMPORAL) {
            new Button(btns)
                .Classed("btn-sleep-snooze")
                .Title(_L("snooze.sleep_now"))
                .OnClick(() => {
                    this.OnClickSleepWakeUp();
                });

            const separator = btns.append("div").classed("o-separate", true);
            separator.append("span");
            separator.append("span").classed("center", true);
            separator.append("span");
        }

        this.btnSnooze = new Button(btns)
            .Classed("btn-sleep-snooze")
            .Title(_L("snooze.record_snooze"))
            .OnClick(() => {
                if (this.IS_EXTEMPORAL) return;

                this.OnClickAddSnooze();
            });


        this.txtBtnContainer.append("span").classed("s-select-option", true).text(_L("general.option_required"))
    }

    private CreateBtnWakeUp() {
        this.bodyContainer.select(".wake-up").remove();
        this.txtBtnContainer?.remove();

        const divWakeUp = this.bodyContainer.append("div").classed("wake-up", true);
        const btns = divWakeUp.append("div").classed("btns-container", true)
        new Button(btns)
            .Classed("btn-sleep-snooze")
            .Title(_L("snooze.wake_up"))
            .OnClick(() => {
                this.OnClickSleepWakeUp();
            });

        divWakeUp.append("div")
            .classed("img-event-icon", true)
            .classed("event-icon-" + 1, true)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_snooze))
            })
    }

    private OnClickAddSnooze() {
        const isSelected = this.btnSnooze?._button.property("selected");
        this.timeContainer?.remove();
        this.footerContainer.selectAll("*").remove();
        const txtTitleSOption = this.txtBtnContainer?.select<HTMLSpanElement>(".s-select-option");

        this.txtTimeStart?.property("value", _TimeToInputFmt())
        this.txtTimeEnd?.property("value", _TimeToInputFmt())

        if (!isSelected) {
            this.txtBtnContainer?.style("align-items", "start");
            txtTitleSOption?.text(_L("snooze.deshacer_select"));
            this.CreateStartEndSnooze();
        } else {
            this.txtBtnContainer?.style("align-items", "center");
            txtTitleSOption?.text(_L("general.option_required"));
        }

        this.btnSnooze?._button.classed("selected", !isSelected);
        this.btnSnooze?._button.property("selected", !isSelected);
    }

    private CreateStartEndSnooze() {
        this.timeContainer = this.txtBtnContainer?.append("div").classed("time-container", true);
        const divTimeStart = this.timeContainer?.append("div")//.classed("time-start-container", true);
        divTimeStart?.append("label").text(_L("snooze.inicio"));
        this.txtTimeStart = divTimeStart?.append("input").attr("type", "time").property("value", _TimeToInputFmt());

        const divTimeEnd = this.timeContainer?.append("div")//.classed("time-end-container", true);
        divTimeEnd?.append("label").text(_L("snooze.fin"));
        this.txtTimeEnd = divTimeEnd?.append("input").attr("type", "time").property("value", _TimeToInputFmt());

        this.AddSaveBtn();
    }

    protected OnSaveClick(): void {
        const valueStart = this.txtTimeStart?.node()?.value;
        const valueEnd = this.txtTimeEnd?.node()?.value;

        if (!valueStart || !valueEnd) {
            ShowToast(_L("snooze.title"), _L("snooze.iniciofin_required"), 'error');
            return;
        }

        const timeStart = valueStart.split(":");
        const timeEnd = valueEnd.split(":");

        const dtStart = new Date();
        dtStart.setHours(+timeStart[0], +timeStart[1]);
        const dtEnd = new Date();
        dtEnd.setHours(+timeEnd[0], +timeEnd[1]);

        let dtStartValue = dtStart.getTime() - timeDay.floor(dtStart).getTime();
        let dtEndValue = dtEnd.getTime() - timeDay.floor(dtEnd).getTime();

        if (dtEnd.getTime() > new Date().getTime()) {
            ShowToast(_L("snooze.title"), _L("snooze.error_fininicio"), 'info');
            return;
        }

        if (dtStartValue > dtEndValue) {
            ShowToast(_L("snooze.title"), _L("snooze.error_iniciofin"), 'info');
            return;
        }

        let current_status_child = this.childList[0].EstadoEvt;
        if (current_status_child === EStatusSleepChild.DORMIDO) {
            ShowToast(_L("snooze.title"), _L("snooze.error_dormido"), 'info');
            return;
        }

        this.SaveStatusSleep(dtStart, dtEnd);
    }

    private OnClickSleepWakeUp() {
        let statusChild = this.childList[0].EstadoEvt;
        let dStart = statusChild === EStatusSleepChild.DESPIERTO ? new Date() : null;
        let dEnd = statusChild === EStatusSleepChild.DORMIDO ? new Date() : null;
        this.SaveStatusSleep(dStart, dEnd);
    }

    SaveStatusSleep(start: Date | null, end: Date | null) {
        LOADING.Show();

        let ids = this.childList.map(o => o.IdChild);
        _AddEventSleep(ids, start, end, this.IS_EXTEMPORAL, (result) => {
            if (result == 1) {
                if (start && !end || !start && end) {
                    this.childList = [];
                    ids.forEach(item => {
                        let child = _mapChilds.get(item);
                        if (child) {
                            if (start && !end) child.EstadoEvt = EStatusSleepChild.DORMIDO;
                            if (!start && end) child.EstadoEvt = EStatusSleepChild.DESPIERTO;

                            this.childList.push(child);
                        }
                    });

                    this.RefreshChildHeader();
                }

                this.CheckSleepStatus();

                ShowToast(_L("snooze.record_snooze"), _L("snooze.record_success"));
                // if (this.IS_EXTEMPORAL)
                _HistoryBack();

            } else if ([-4].includes(result)) {
                ShowToast(_L("snooze.record_snooze"), _HttpMsg("evento/RegistrarEventoSiestaV2", result), "error");
            }
            else
                this.EventComunError(result, _L("snooze.record_snooze"));

            LOADING.Dismiss();
        });
    }

}