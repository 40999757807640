import { Selection, selection } from "d3";


export interface D3BubleChild extends Selection<HTMLDivElement, any, HTMLElement | any, any> {
    PhotoUrl(value: string): D3BubleChild;
    Name(value: string): D3BubleChild;
    Genre(value: number): D3BubleChild;
}

export function _AddBubbleChild(elemnt: Selection<HTMLDivElement, any, any, any>): D3BubleChild {
    const dBble = elemnt.html(`
    <div class="bubble-child">
        <div class="name-child"></div>
        <img class="prof-child" onerror="this.style.display='none';" onload="this.style.display='inline-block';"/>
    </div>
    `) as D3BubleChild;

    return dBble;
}


function _src(this: D3BubleChild, val: string): D3BubleChild {
    this.select("img").attr("src", val);
    return this;
}

function _name(this: D3BubleChild, val: string): D3BubleChild {
    this.select("div").text(val);
    return this;
}

function _genre(this: D3BubleChild, val: number): D3BubleChild {
    this.classed("female", val == 1);
    return this;
}

selection.prototype.PhotoUrl = _src;
selection.prototype.Name = _name;
selection.prototype.Genre = _genre;