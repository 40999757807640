/**
 * DB from EMUtils
 */

var _dbName: string;

export const SetDBName = (dbName: string) => { _dbName = dbName; }

function getInstance(version?: number): IDBOpenDBRequest {
    var indexedDB = self.indexedDB;
    let DBInstance: IDBOpenDBRequest;
    if (version)
        DBInstance = indexedDB.open(_dbName, version);
    else
        DBInstance = indexedDB.open(_dbName);

    return DBInstance;
}

//Método para inicializar la BD con todos los ObjectStore
export async function InitDB(stores: Array<iDBStore>): Promise<any> {
    return new Promise(function (resolve) {
        let db = getInstance();
        db.onupgradeneeded = function () {
            stores.forEach(store => {
                let storeName: string = store.name;
                let key: string = store.key;
                let fields: string[] = store.fieldKeys;

                var dbStore = db.result.createObjectStore(storeName, { keyPath: key });

                fields.forEach(element => {
                    let _unique: boolean = false;
                    if (element == key)
                        _unique = true;

                    dbStore.createIndex(element, element, { unique: _unique });
                });
            });

            // // db.result.close();
            // console.info("DB init successfull");
            // resolve(true);
        };

        db.onsuccess = function () {
            // console.info("DB init successfull");
            db.result.close();
            resolve(true);
        }

        db.onerror = function (ev) {
            console.warn("error:", ev);
            //reject(Error("Error on Error;"))
            resolve(false);
        }

        db.onblocked = function (ev) {
            console.warn("Blocked:", ev);
            db.result.close();
            resolve(false);
        }
    });
}

export async function createObjectStore(storeName: string, key: string, columns: string[]) {
    return new Promise(function (resolve, reject) {
        var db = getInstance();

        db.onupgradeneeded = function () {
            //VALIDAR si Esto se generaria desde aqui
            var dbStore = db.result.createObjectStore(storeName, { keyPath: key });

            columns.forEach(element => {
                let _unique: boolean = false;
                if (element == key)
                    _unique = true;

                dbStore.createIndex(element, element, { unique: _unique });
            });
        }

        db.onsuccess = function () {

            if (db.result.objectStoreNames.contains(storeName)) {
                reject(Error("ObjectStore already exists!"));
                return;
            }

            var version: number = parseInt(db.result.version.toString()) + 1;
            var _db = getInstance(version);

            _db.onupgradeneeded = function () {
                var dbStore = _db.result.createObjectStore(storeName, { keyPath: key });

                columns.forEach(element => {
                    let _unique: boolean = false;
                    if (element == key)
                        _unique = true;

                    dbStore.createIndex(element, element, { unique: _unique });
                });

                db.result.close();
                //Successfully!
                resolve(true);
            }
        }
    });
}

export async function addRows(storeName: string, listRows: Array<any>) {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_db) {
            let store = getIndexStore(db, storeName, true, 'readwrite');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            store.transaction.oncomplete = function (_) { }
            store.transaction.onerror = function (_) { }
            store.transaction.onabort = function (e: any) {
                let error = e.target.error;
                if (error.name == 'QuotaExceededError') {
                    reject(Error(error.name));
                }
            }

            listRows.forEach(item => {
                if (!store) return;

                let request = store.add(item);
                request.onerror = function (e: any) {
                    reject(Error(e));//some error when add data
                    return;
                }

                request.onsuccess = function (_: any) { }
            });

            //Successfully!
            db.result.close();
            resolve(true);
        };
    });
}

export async function setRows(storeName: string, listRows: Array<any>): Promise<any> {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_db) {
            let store = getIndexStore(db, storeName, true, 'readwrite');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            store.transaction.oncomplete = function (_) {
                resolve(true);
            }
            store.transaction.onerror = function (e: any) {
                reject(Error(e.target.error.name))
            }
            store.transaction.onabort = function (e: any) {
                let error = e.target.error;
                if (error.name == 'QuotaExceededError') {
                    console.log(error.name)
                }
                reject(Error(e.target.error.name))
            }

            listRows.forEach(item => {
                if (!store) return;

                let request = store.put(item);
                request.onerror = function (e: any) {
                    db.result.close();
                    reject(Error(e));//some error when add data
                    console.log(request.result);
                    return;
                }

                request.onsuccess = function (_: any) { }
            });

            db.result.close();
        };

        db.onerror = function (_) {
            console.log("error,,, create db");
            reject(false);
        }
    });
}


export async function getRowById(storeName: string, id: any) {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_db) {
            let store = getIndexStore(db, storeName, true, 'readonly');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            let request = store.get(id);

            request.onsuccess = function (_: any) {
                if (request.result) {
                    if (Object.keys(request.result).length > 0)
                        resolve(request.result);
                    else
                        resolve(null);
                } else //reject(Error("No Data found for this ID!"));
                    resolve(null);

                db.result.close();
            };
        };
    });
}

export async function getRows<T>(storeName: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        var db = getInstance();

        db.onsuccess = function (_db) {
            let store = getIndexStore(db, storeName, true, 'readonly');

            if (!store) {
                reject(Error("ObjectStore '" + storeName + "' not found on " + _dbName + "!"));
                return;
            }

            let request = store.getAll();
            request.onsuccess = function (event: any) {
                if (event.target.result) {
                    resolve(event.target.result)
                } else
                    reject(Error("The ObjectStore is empty!"));

                db.result.close();
            }
        }
    })/*.catch(function (error) {
        console.log(error);
    });*/
}

export async function getFilterRows(storeName: string, filterColumn: string, value: any) {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_db) {
            let store = getIndexStore(db, storeName, true, 'readonly');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            let request = store.index(filterColumn);
            request.getAll(value).onsuccess = function (event: any) {
                var result = event.target.result;

                resolve(result);
            }
        };
    });
}

export async function getMaxValue(storeName: string, filterColumn: string, direction: "next" | "nextunique" | "prev" | "prevunique", optionValue: string | null = null) {
    return new Promise(function (resolve, reject) {

        let db = getInstance();
        db.onsuccess = function () {
            let store = getIndexStore(db, storeName, true, 'readonly');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            var index = store.index(filterColumn);
            var openCursorRequest = index.openCursor(optionValue, direction);
            var maxRevisionObject: any = null;

            openCursorRequest.onsuccess = function (event: any) {
                if (event.target.result) {
                    maxRevisionObject = event.target.result.value; //the object with max revision   
                }

                resolve(maxRevisionObject);
                db.result.close();
            };
        };
    });
}

export async function countRows(storeName: string, filterColumn: string) {
    return new Promise(function (resolve, reject) {
        let db = getInstance();
        db.onsuccess = function () {
            let store = getIndexStore(db, storeName, true, 'readonly');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            var index = store.index(filterColumn);
            var request = index.count();

            request.onsuccess = function (_: any) {
                resolve(request.result);
                db.result.close();
            };
        };
    });
}

export async function deleteRows(storeName: string, ids: any[]): Promise<any> {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_db) {

            let store = getIndexStore(db, storeName, true, 'readwrite');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            ids.forEach(key => {
                if (!store) return;

                store.delete(key);
            });

            //Successfully!
            resolve(true);
            db.result.close();

            // tx.complete();
        }
    });
}


export async function deleteObjectStore(storeName: string) {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function () {
            if (!db.result.objectStoreNames.contains(storeName)) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            var version = parseInt(db.result.version.toString()) + 1;
            var _db = getInstance(version);

            _db.onupgradeneeded = function () {
                var request: any = _db.result.deleteObjectStore(storeName);
                request.onsuccess = function () {
                    console.log("Se ha eliminado el Store!!!");

                    //Successfully!
                    resolve(true);
                    db.result.close();
                }
            }
        }
    });
}

export async function clearObjectStore(storeName: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        var db = getInstance();
        db.onsuccess = function () {
            let store = getIndexStore(db, storeName, true, 'readwrite');
            if (!store) {
                reject(Error("ObjectStore not Found!"));
                return;
            }

            store.clear();

            //Successfully!
            resolve(true);
            db.result.close();
        }
    });
}

export async function DBDelete(): Promise<any> {
    var _iDB = window.indexedDB;//|| window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var dbRequest = _iDB.deleteDatabase(_dbName);

    return new Promise((resolve) => {
        dbRequest.onsuccess = function () {
            console.log("Database deleted successfully");
            resolve(true);
        }

        dbRequest.onerror = function () {
            console.log("error, create db");
            resolve(false);
        }

        dbRequest.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
            resolve(false);
        };
    });
}


function getIndexStore(db: IDBOpenDBRequest, storeName: string, ifContains: boolean, optionRead: any): IDBObjectStore | null {
    let store: IDBObjectStore | null = null;
    try {
        if (db.result.objectStoreNames.contains(storeName) == ifContains) {
            let tx = db.result.transaction(storeName, optionRead);
            store = tx.objectStore(storeName);
        } else {
            db.result.close();
        }
    } catch (e) {
        console.warn("unhandled error >> ", e)
    }

    return store;
}

export function isStoreExist(storeName: string) {
    return new Promise((resolve, reject) => {
        let db = getInstance();
        db.onsuccess = function () {
            if (db.result.objectStoreNames.contains(storeName)) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
        db.onerror = function () {
            reject("error DB....");
        }
    });
}

export async function getJoinStores(store1: string, store2: string, idStore1: string, idStore2: string, indexStore1?: string, valueStore1?: number | string, range?: "upper" | "lower" | "only"): Promise<any> {
    var mapResult = new Map();

    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_bd) {

            var active = db.result;

            var transactionStore = active.transaction([store1, store2], 'readonly');
            var objectStore1 = transactionStore.objectStore(store1);
            var objectStore2 = transactionStore.objectStore(store2);
            var cursor1: any, cursor2: any;
            var idMap: number;

            if (!objectStore1 || !objectStore2) {
                reject(Error("ObjectStore not Found!"));
                return
            }

            transactionStore.oncomplete = function (_: any) {
                resolve(Array.from(mapResult.values()));
                db.result.close();

            }

            var cursorStore1;
            if (indexStore1) {
                var keyRangeOnly;

                var IndexadoStore1 = objectStore1.index(indexStore1);

                if (range == undefined || range == "only") {

                    keyRangeOnly = IDBKeyRange.only(valueStore1);

                } else if (range == "lower") {
                    keyRangeOnly = IDBKeyRange.lowerBound(valueStore1);
                } else if (range == "upper") {
                    keyRangeOnly = IDBKeyRange.upperBound(valueStore1);
                }

                cursorStore1 = IndexadoStore1.openCursor(keyRangeOnly);
            } else {
                cursorStore1 = objectStore1.openCursor();
            }
            cursorStore1.onsuccess = function (event: any) {
                cursor1 = event.target.result;
                if (cursor1 !== null) {
                    idMap = cursor1.value[idStore1];
                }
                MatchStores();
            }

            objectStore2.openCursor().onsuccess = function (event: any) {
                cursor2 = event.target.result;
                MatchStores();
            }

            function MatchStores() {
                if (!cursor1 || !cursor2) return;

                if (cursor2 && cursor1.value[idStore1] == cursor2.value[idStore2]) {
                    cursor1.value["_" + store2] = cursor2.value;
                    mapResult.set(idMap, cursor1.value);
                    cursor2.continue();

                } else {
                    mapResult.set(idMap, cursor1.value)
                    cursor1.continue();
                }
            }

        }
    });
}

export async function getJoinStore(store1: string, store2: string, valueID: number | string, idStore2: string, indexed?: boolean) {
    return new Promise(function (resolve, reject) {

        var db = getInstance();

        db.onsuccess = function (_bd) {

            var active = db.result;

            var transactionStore = active.transaction([store1, store2], 'readonly');
            var objectStore1 = transactionStore.objectStore(store1);
            var objectStore2 = transactionStore.objectStore(store2);
            var resultStore1: any;
            var resultStore2: any;

            if (!objectStore1 && !objectStore2) {
                reject(Error("ObjectStore not Found!"));
                return
            }

            var obtnerStore1 = objectStore1.get(valueID);

            obtnerStore1.onsuccess = function (event: any) {
                resultStore1 = event.target.result;

                if (resultStore1) {

                    if (indexed) {
                        var indiceStore2 = objectStore2.index(idStore2);
                        var getIndiceStore2 = indiceStore2.getAll(valueID);

                        getIndiceStore2.onsuccess = function (event: any) {
                            resultStore2 = event.target.result;

                            Match();
                        }
                    } else {
                        var obtenerStore2 = objectStore2.get(valueID);
                        obtenerStore2.onsuccess = function (event: any) {
                            resultStore2 = event.target.result;

                            Match();

                        }
                    }
                } else {
                    resolve(null)
                }

            }

            function Match() {

                resultStore1["_" + store2] = resultStore2;
                resolve(resultStore1);

            }
        }
    });
}

export function deleteRange(store: string, rangeLower: number, rangeUpper: number, lowerOpen?: boolean, upperOpen?: boolean) {
    return new Promise(function (_, __) {
        var db = getInstance();

        db.onsuccess = function (_bd) {

            if (lowerOpen == undefined)
                lowerOpen = false;

            if (upperOpen == undefined)
                upperOpen = false;

            var keyRangeValue = IDBKeyRange.bound(rangeLower, rangeUpper, lowerOpen, upperOpen);

            var active = db.result;
            var transacion = active.transaction(store, 'readwrite');
            var objectStore = transacion.objectStore(store);

            objectStore.openCursor(keyRangeValue).onsuccess = function (event: Event) {

                var c = <IDBRequest>event.target;
                var cursor = <IDBCursorWithValue>c.result;

                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                }
            }
        }

    });
}

export function deleteRangePosicion(store: string, rangeUpper: number) {
    return new Promise(function (resolve, _) {
        var db = getInstance();
        db.onsuccess = function (_bd) {

            var active = db.result;
            var transacion = active.transaction(store, 'readwrite');
            var objectStore = transacion.objectStore(store);
            var cont = 0;

            objectStore.openCursor().onsuccess = function (event: Event) {
                var c = <IDBRequest>event.target;
                var cursor = <IDBCursorWithValue>c.result;

                if (cursor) {
                    cont++;
                    if (cont <= rangeUpper) {
                        cursor.delete();
                        cursor.continue();
                    } else {
                        db.result.close();
                        resolve(true);
                    }
                }
            }
        }

    });
}

export function getAllIndex(store: string, index: string, value: number): Promise<any> {
    return new Promise(function (resolve, reject) {
        var db = getInstance();
        db.onsuccess = function (_db) {
            var transaccionStore = getIndexStore(db, store, true, 'readonly');

            if (!transaccionStore) {
                reject(Error("ObjectStore not Found!"));
                return;
            }
            var indiceStore = transaccionStore.index(index);
            var getResult = indiceStore.getAll(value);

            getResult.onsuccess = function (_) {
                resolve(getResult.result);
                db.result.close();
            }
        }
    });
}

export function deleteRangeByIndex(store: string, index: string, rangeLower: any, rangeUpper: any, lowerOpen?: boolean, upperOpen?: boolean): Promise<any> {
    return new Promise(function (resolve, reject) {
        var db = getInstance();

        db.onsuccess = function (_db) {
            var transaccionStore = getIndexStore(db, store, true, 'readwrite');

            if (!transaccionStore) {
                reject(Error("ObjectStore not Found!"));
                return;
            }
            var objectStore = transaccionStore.index(index);

            if (lowerOpen == undefined) lowerOpen = false;
            if (upperOpen == undefined) upperOpen = false;

            var keyRangeValue;
            if (!rangeLower || rangeLower == "")
                keyRangeValue = IDBKeyRange.upperBound(rangeUpper, upperOpen);
            else if (!rangeUpper || rangeUpper == "")
                keyRangeValue = IDBKeyRange.lowerBound(rangeLower, lowerOpen)
            else
                keyRangeValue = IDBKeyRange.bound(rangeLower, rangeUpper, lowerOpen, upperOpen);

            objectStore.openCursor(keyRangeValue).onsuccess = function (event: Event) {
                var c = <IDBRequest>event.target;
                var cursor = <IDBCursorWithValue>c.result;

                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                } else {
                    resolve(true);
                    db.result.close();
                }
            }
        }
    });
}

export interface iDBStore {
    name: string;
    key: string;
    fieldKeys: Array<string>;
}