import { create } from "d3";

export namespace Spinner {
    const SPINNER_MAINCLASS = "spinner-component";
    type SpinnerElement = HTMLDivElement & {
        stylesRemoveInParent: string[];
    }

    export function _ShowSpinner(element: TSelection<any>) {
        let spinner = element.select<HTMLDivElement>(":scope>." + SPINNER_MAINCLASS)
        if (!spinner.node()) {
            spinner = create("div").attr("class", SPINNER_MAINCLASS);
            spinner.append("div").attr("class", "spinner");
            const spinnerNode: SpinnerElement = spinner.node() as any;
            spinnerNode.stylesRemoveInParent = [];
            spinnerNode.onclick = e => e.stopPropagation();
            if (!(element.node() as HTMLDivElement).style.overflow) {
                element.style("overflow", "hidden");
                spinnerNode.stylesRemoveInParent.push("overflow");
            }
        }
        element.append(() => spinner.node())
    }

    export function _RemoveSpinner(element: TSelection<any>) {
        let spinner = element.select<HTMLDivElement>(":scope>." + SPINNER_MAINCLASS);
        if (!spinner.node()) return;
        const spinnerNode: SpinnerElement = spinner.node() as any
        spinnerNode.remove();
        spinnerNode.stylesRemoveInParent.forEach(style => element.style(style, null));
    }
}