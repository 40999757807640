export namespace ObjectUtil {
    type GettersV2<T> = Readonly<Partial<{
        [x in keyof T as `get${x & string}`]: x extends PropertyKey ? ((thisDato: T, setValue: T[x]) => T[x]) : never;
    }>>;
    type Setters<T> = Readonly<Partial<{
        [x in keyof T as `set${x & string}`]: x extends PropertyKey ? ((value: T[x]) => void) : never;
    }>>;
    type DefaultSets<T> = Readonly<Partial<{
        [x in keyof T as `_${x & string}_`]: x extends PropertyKey ? any : never;
    }>>;
    /**
     * @param dato
     * @param properties
     * * `_PROP_` -> agrega get y set encapsuladores a una propiedad correspondiente oculta `___PROP___`
     * * `getPROP` -> agrega un get personalizado. `setValue` es el valor almacenado en `___PROP___`
     * * `setPROP` -> agrega un set personalizado, se almacena en `___PROP___`
     *
     * Es válido establecer `_PROP_` y configurar get o set personalizado
     * @returns
     */
    export function _GettersSetters<DataBase, DataResult = DataBase>(dato: DataBase, properties: GettersV2<DataResult> | Setters<DataResult> | DefaultSets<DataResult>): DataResult {
        type TInit = { [k: string]: { get: GettersV2<DataBase>[any], set: Setters<DataBase>[any], default: any } };
        const setget: TInit = Object.getOwnPropertyNames(properties)
            .reduce((res, key) => {
                const typeProperty = key.substring(0, 3) as "get" | "set";
                const hasGetSet = ["get", "set"].includes(typeProperty);
                const realKey = hasGetSet ? key.substring(3) : key.substring(1, key.length - 1);
                if (!res[realKey]) {
                    res[realKey] = {} as any;
                }
                if (hasGetSet) {
                    res[realKey][typeProperty] = properties[key];
                } else if (key[0] == "_" && key[key.length - 1] == "_") {
                    res[realKey].default = properties[key];
                }
                return res;
            }, <TInit>{})

        for (const k in setget) {
            const property = k as keyof DataResult & string;
            const calls = setget[property];
            const attributes: PropertyDescriptor & ThisType<any> = {
                configurable: true,
            }
            const extraProperty = `___${property}___`;
            const setExtraProperty = (value) => Object.defineProperty(dato, extraProperty, {
                configurable: true,
                value: value,
                // writable: false
            })
            const getExtraProperty = () => Object.getOwnPropertyDescriptor(dato, extraProperty)?.value;
            if (calls.default !== undefined) {
                attributes.get = () => {
                    return getExtraProperty();
                }
                attributes.set = (value) => {
                    setExtraProperty(value);
                }
                setExtraProperty(calls.default);
            }
            if (calls.get) {
                attributes.get = () => {
                    const setValue = getExtraProperty();
                    const returnVal = (calls.get as any)(dato, setValue);
                    if (setValue !== returnVal) {
                        setExtraProperty(returnVal);
                    }
                    return returnVal;
                }
            }
            if (calls.set) {
                attributes.set = (value) => {
                    (calls.set as any)(value);
                    setExtraProperty(value);
                }
            }
            Object.defineProperty(dato, property, attributes);
        }
        return dato as any as DataResult;
    }
}