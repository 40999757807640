import { ref_agenda_ActualizarTareaAgenda, ref_agenda_EliminarTareaAgenda, ref_agenda_NuevaTareaAgenda, ref_agenda_ObtenerTareasAgenda } from "../API";
import { _APP_DATA } from "../AppData";
import { IApiResult } from "../models/Entities";
import { RequestData } from "../utils/RequestData";

export interface ITaskAgenda {
    IdTarea: number;
    IdKinder: number;
    Titulo: string;
    Descripcion: string;
    Inicio: Date;
    Fin: Date;
    EnUso: boolean;
}

export interface IDatumActivity {
    intId: number;
    id: string;
    range: string;
    start: Date;
    end: Date;
    title: string;
    description: string;
    color: string;
    type: ETipoTarea;
    icon: string;
}
export enum ETipoTarea { TITLE_DAY = 0, ACT = 1, CUMPLE = 2, ESPECIAL = 3 };

export function _LoadActivitiesInfo(start: Date, end: Date, itemParse: ((item: ITaskAgenda) => void) | null, callback: (result: Array<ITaskAgenda>) => void) {
    let params = {
        IdMaestra: _APP_DATA.userData.IdMaestra,
        Inicio: start.toISOString(),
        Fin: end.toISOString(),
        Modificacion: null
    }

    new RequestData<IApiResult<ITaskAgenda[]>>(ref_agenda_ObtenerTareasAgenda)
        .Params(params)
        .Completion((response, error) => {
            if (error)
                console.log(error.name, error.message);

            const resultData = (response ? response.Datos : [])
                .filter(d => d.IdKinder == _APP_DATA.userData.IdKinder)
                .map(item => {
                    item.Inicio = new Date(item.Inicio);
                    item.Fin = new Date(item.Fin);
                    if (itemParse) itemParse(item);
                    return item;
                });

            if (callback) callback(resultData);
        }).Get();
}

export function _AddTaskAgenda(title: string, start: Date, end: Date, callback: (STATUS: number) => void): void {
    let params = {
        Titulo: title
        , Descripcion: ""
        , Inicio: start.toISOString()
        , Fin: end.toISOString()
        , IdKinder: _APP_DATA.userData.IdKinder
    }

    SetTaskAgenda(params, ref_agenda_NuevaTareaAgenda, callback);
}

export function _UpdateTaskAgenda(idTask: number, title: string, start: Date, end: Date, callback: (STATUS: number) => void): void {
    let params = {
        IdTarea: idTask
        , Titulo: title
        , Descripcion: ""
        , Inicio: start.toISOString()
        , Fin: end.toISOString()
    }

    SetTaskAgenda(params, ref_agenda_ActualizarTareaAgenda, callback);
}

function SetTaskAgenda(params: any, url: string, callback: (STATUS: number) => void) {
    params.IdUsuario = _APP_DATA.userData.IdUsuario;
    params.IdMaestra = _APP_DATA.userData.IdMaestra;

    new RequestData<IApiResult<any>>(url)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -100;
            if (error)
                console.log(error.name, error.message);

            callback(status);
        }).Post();
}

export function _RemoveTaskAgenda(idTask: number, callback: (STATUS: number) => void) {
    let params = {
        IdTarea: idTask
    }

    SetTaskAgenda(params, ref_agenda_EliminarTareaAgenda, callback);
}