import { apiLoadChild, apiLoadChildInfo, apiLoadExtemporaneo, apiLoadSchoolHour, apiLoadTodaySchedule, apiLoadTutor, apiUpdatePhotoProfile } from "../API";
import { _APP_DATA } from "../AppData";
import { _maxChatMessageUpdated } from "./Chats";
import { IApiResult, IChild, IInfoChild, IParent, ISchoolHour, ITodaySchoolHour } from "../models/Entities";
import { EPhotoSize, _URLChildPhoto } from "./Photo";
import { RequestData } from "../utils/RequestData";
import { ObjectUtil } from "../../utils/ObjectUtil";

export let _mapChilds: Map<number, IChild> = new Map();
const mapChildsPositions: Map<number, IChild> = new Map(); // FIXME Funciona pero no sigue un estándar
var maxDateChild: string | null = null;

export function _LoadDataChild(callback: (res: Array<IChild>) => void) {
    let params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
        Modificacion: maxDateChild
    }

    new RequestData<IApiResult<IChild[]>>(apiLoadChild)
        .Params(params)
        .Completion((response, error) => {
            let resultData: Array<IChild> = response?.Datos || [];

            if (error) {
                console.log(error.name, error.message);
                if (callback) callback(resultData);
                return;
            }

            resultData.forEach(item => {
                // Data.Photos._childPhotos.delete(item.IdUsuario);// VALIDAR SI AUN APLICARIA

                if (!item.EnUso) {
                    _mapChilds.delete(item.IdChild);
                    return;
                }

                item.Nacimiento = new Date(item.Nacimiento);
                item.urlPhoto = _URLChildPhoto(item.IdUsuario, EPhotoSize.THUMBNAIL);
                const oldChild = mapChildsPositions.get(item.IdChild);
                if (oldChild) {
                    (item as any).x = (oldChild as any).x; // FIXME PARA NO PERDER LA POSICIÓN DEL ALUMNO EN EL SALÓN
                    (item as any).y = (oldChild as any).y; // FIXME
                }
                _mapChilds.set(item.IdChild, ObjectUtil._GettersSetters(item, {
                    get_NombreCompleto: (dato) => `${dato.Nombre} ${dato.ApPaterno} ${dato.ApMaterno}`.trim(),
                }));
            });

            let maxDate = response?.Maxima;
            if (resultData.length > 0 && maxDate != null)
                maxDateChild = maxDate;

            if (callback) callback(resultData);
        }).Get();
}

export var _mapSchoolHourChild: Map<number, ISchoolHour> = new Map();
var maxDateSchedule: string | null = null;

export function _LoadSchoolHour(callback: (resul: Array<any>) => void) {
    let day: number = new Date().getDay();
    day = day > 0 ? day - 1 : 6;

    let params = {
        Dia: day,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        Modificacion: maxDateSchedule
    }

    new RequestData<IApiResult<ISchoolHour[]>>(apiLoadSchoolHour)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response ? response.Datos : [];
            // let status = response ? response.Estado : -111;

            if (error)
                console.log(error.name, error.message);

            resultData.forEach(item => {
                let obj = Object.assign({}, item) as any;
                item.Entrada = obj.Entrada ? new Date(obj.Entrada) : null;
                item.Salida = obj.Salida ? new Date(obj.Salida) : null;

                if (!item.EnUso)
                    _mapSchoolHourChild.delete(item.IdAlumno);
                else
                    _mapSchoolHourChild.set(item.IdAlumno, item);
            });

            if (resultData.length > 0 && response)
                maxDateSchedule = response.Maxima;

            if (callback) callback(resultData);
        }).Get();
}

export function _LoadTodaySchoolHour(idChild: number, omitirGrupoActual: boolean, callback: (status: number, resul: Array<ITodaySchoolHour>) => void) {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder,
        IdAlumno: idChild,
        IdGrupo: _APP_DATA.userData.IdGrupo,
        OmitirGrupoActual: omitirGrupoActual
    }

    new RequestData<IApiResult<ITodaySchoolHour[]>>(apiLoadTodaySchedule)
        .Params(params)
        .Completion((response, error) => {
            let status = response ? response.Resultado : -111;
            let resultData = response && status > 0 ? response.Datos : [];

            if (error)
                console.log(error.name, error.message);

            resultData.forEach(item => {
                item.Entrada = new Date(item.Entrada);
            });

            if (callback) callback(status, resultData);
        }).Get();
}

export function _LoadInfoChild(idChild: number, callback: (result: IInfoChild | null) => void) {
    let params = {
        IdAlumno: idChild
    }

    new RequestData<IInfoChild>(apiLoadChildInfo)
        .Params(params)
        .Completion((response, error) => {
            if (error)
                console.log(error.name, error.message);

            let status = response ? response.Resultado : -111;
            let resultData = response;

            if (status < 1) {
                console.log("Ocurrió un error, al descargar infoAlumno");
                resultData = null;
            }

            if (callback) callback(resultData);
        }).Get();
}

export function _UpdateProfile(childId: number, image: File, callback: (status: number) => void) {
    let params = {
        IdAlumno: childId,
        IdUsuario: _APP_DATA.userData.IdUsuario,
        Foto: image
    }

    new RequestData<IApiResult<number>>(apiUpdatePhotoProfile)
        .Params(params)
        .ExtraInfoInErrorBotMessage(`ImgSize(${image?.size} bytes)`)
        .Completion((response, _) => {
            let status = response ? response.Resultado : -111;

            if (status < 1)
                console.log("Ocurrió un error, al guardar el archivo infoAlumno");

            if (callback) callback(status);
        }).FORM();
}

export var _mapParents: Map<number, IParent> = new Map();
var maxDateTutor: string | null = null;
export function _LoadTutorData(callback: (data: Array<IParent>) => void) {
    let params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
        Modificacion: maxDateTutor
    }

    new RequestData<IApiResult<IParent[]>>(apiLoadTutor)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || [];

            if (error)
                console.log(error.name, error.message);

            resultData.forEach(item => {
                if (!item.EnUso)
                    _mapParents.delete(item.Id)
                else
                    _mapParents.set(item.Id, item);
            });

            let maxDate = response?.Maxima || null;
            if (resultData.length > 0 && maxDate != null)
                maxDateTutor = maxDate;

            if (resultData.length > 0 && callback) callback(resultData);
        }).Get();
}


export function _LoadExtempDataChild(callback: (status: number, res: Array<IChild>) => void) {
    let params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
        Modificacion: null
    }

    new RequestData<IApiResult<IChild[]>>(apiLoadExtemporaneo)
        .Params(params)
        .Completion((response, error) => {
            let resultData: Array<IChild> = response?.Datos || [];
            const status = response?.Resultado || -111;

            if (error) {
                console.log(error.name, error.message);
                if (callback) callback(status, resultData);
                return;
            }

            resultData.forEach(item => {
                if (item.EnUso === false) {
                    return;
                }

                item.Nacimiento = new Date(item.Nacimiento);
                item.urlPhoto = _URLChildPhoto(item.IdUsuario, EPhotoSize.THUMBNAIL);
            });

            if (callback) callback(status, resultData);
        }).Get();
}

export function _ClearChildsData() {
    _mapChilds.forEach(d => mapChildsPositions.set(d.IdChild, d));
    _mapChilds.clear();
    maxDateChild = null;
    _mapSchoolHourChild.clear();
    maxDateSchedule = null;
    _mapParents.clear();
    maxDateTutor = null;
    _maxChatMessageUpdated.clear();
}