import { _URL_REGEX } from "./General";

export function _ExtractLinks(str: string): string[] {
    const links: string[] = [];
    const resolver = str => (links.push(str), str);

    if (!str) return links;

    String.prototype.replaceAll
        ? str.replaceAll(_URL_REGEX, resolver)
        : str.replace(_URL_REGEX, resolver)

    return links;
}