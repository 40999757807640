import "../../../styles/Base-ui-event.scss";
import { Selection, max, min, select } from "d3";
import { CreateLogoOptions } from "../components/LogoOptions";
import { CreateListOptionEvents, _SetOnClickOptionEvent } from "../components/ListOptionEvents";
import { EEventId, EStatusSleepChild, IChild, ICommonAddGralEvent, IEvent, IEventStateHistory } from "../../data/models/Entities";
import { Base } from "./Base";
import { D3BubleChild, _AddBubbleChild } from "../utils/BubbleChild"
import { _HistoryBack, _ReplaceState } from "../../routes/UIManager";
import { ShowToast } from "../components/Toast";
import { _ValidRegisterEventChild } from "../../data/utils/General";
import { _SnoozeAnimation } from "../components/SleepIndicator";
import { _AddGeneralEvent, _GetIdComment, _mapCommentByEvent } from "../../data/services/Event";
import { LOADING } from "../components/Loading";
import _L, { _HttpMsg } from "../../utils/Labels";
import { _CreateElementFromHTML } from "../utils/General";
import ic_done from "/icons/ic_done.svg?raw";
import ic_exit from '/icons/ic_exit.svg?raw';

interface IChildEv extends IChild {
    __Selected?: boolean;
}

export class BaseUIEvent extends Base {

    protected headerContainer: Selection<HTMLDivElement, any, any, any>;
    protected bodyContainer: Selection<HTMLDivElement, any, any, any>;
    protected footerContainer: Selection<HTMLDivElement, any, any, any>;
    protected divListChild: Selection<HTMLDivElement, any, any, any>;
    protected btnSave: TSelection<"button">;

    protected IS_EXTEMPORAL: boolean;
    protected childList: IChildEv[] = [];

    protected CHILD_MAX_DATE: string = null;
    protected CHILD_MIN_DATE: string = null;

    protected IS_DESTROYED = false;

    constructor(className: string, idEvent: EEventId) {
        const cls = "main-container-event " + (className || "");

        super(cls, false);

        const objState = <IEventStateHistory>history.state;
        this.IS_EXTEMPORAL = objState.isExtemp;

        this.SetListChildEvt_Dates(objState.dataChilds)

        const bodyAndFooter = this.mainContainer.select(".all-body").select(".body-footer");
        if (bodyAndFooter.size() > 0) {//Evaluar si se ha creado la base para una ventana de eventos
            this.headerContainer = this.mainContainer.select(".all-body").select(".header");

            this.bodyContainer = bodyAndFooter.select(".body-container");
            this.footerContainer = bodyAndFooter.select(".footer-container");

            this.bodyContainer.selectAll("*").remove();
            this.footerContainer.selectAll("*").remove();

            this.RefreshChildHeader();
            _SetOnClickOptionEvent(this.OnClickOptionEvent.bind(this));

            return;
        }

        CreateLogoOptions(this.mainContainer);
        const allBody = this.mainContainer.append("div").classed("all-body", true);


        this.headerContainer = allBody.append("div").classed("header", true);
        this.divListChild = this.headerContainer.append("div").classed("list-child", true);
        this.headerContainer.append(() => _CreateElementFromHTML(ic_exit))
            .attr("class", "img-exit")
            .on("click", () => {
                _HistoryBack();
            });
        this.RefreshChildHeader();

        const subBody = allBody.append("div").classed("sub-body", true);
        const bodyFooter = subBody.append("div").classed("body-footer", true);
        const listEvs = subBody.append("div").classed("list-events", true);


        this.bodyContainer = bodyFooter.append("div").classed("body-container", true);
        this.footerContainer = bodyFooter.append("div")
            .classed("footer-container", true);

        const divEvtlist = listEvs.append("div");
        CreateListOptionEvents(divEvtlist, idEvent, (itm) => this.OnClickOptionEvent(itm));
    }

    private OnClickOptionEvent(datum: IEvent) {
        const msgInfo = _ValidRegisterEventChild(this.childList, datum);
        if (msgInfo) {
            ShowToast(_L("event.evrecord"), msgInfo, "info");
            return false;
        }
        const objState: IEventStateHistory = { isExtemp: this.IS_EXTEMPORAL, dataChilds: this.childList };
        _ReplaceState(datum.hash, objState);
        return true;
    }


    protected RefreshChildHeader() {
        if (!this.childList || this.childList.length == 0) {
            history.back();
            return;
        }

        if (!this.divListChild) this.divListChild = this.headerContainer.select(".list-child");

        this.CreateListChild(this.divListChild, this.childList, (d) => {
            this.childList = this.childList.filter(o => o.IdChild != d.IdChild);
            this.RefreshChildHeader();
        });
    }


    protected CreateListChild(container: Selection<HTMLDivElement, any, any, any>, listData: IChildEv[], callback?: (d: IChildEv) => void) {
        const bubblesChild = container.selectAll<HTMLDivElement, any>(".bubbles-child").data(listData);

        bubblesChild.exit().remove();
        bubblesChild.enter().append("div")
            .classed("bubbles-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                _AddBubbleChild(elemnt);
            })
            .merge(bubblesChild)
            .each((datum, i, divs) => {
                const elemnt = select(divs[i])
                    .classed("selected", datum.__Selected == null ? true : datum.__Selected);

                const bubble = <D3BubleChild>elemnt.select("div");
                bubble.PhotoUrl(datum.urlPhoto)
                    .Name(datum.Nombre)
                    .Genre(datum.Sexo);

                _SnoozeAnimation(bubble, datum.EstadoEvt == EStatusSleepChild.DORMIDO);
            }).on("click", (_, d) => {
                if (callback) callback(d)
            })
    }

    protected ToggleSelectValChilds(select: boolean, ...ids: number[]) {
        if (this.IS_DESTROYED) return;

        this.childList.forEach(d => {
            if (ids.includes(d.IdChild)) {
                d.__Selected = select;
            }
        })
        if (this["__headerRefreshTimeout"]) {
            clearTimeout(this["__headerRefreshTimeout"]);
        }
        this["__headerRefreshTimeout"] = setTimeout(() => {
            this["__headerRefreshTimeout"] = null;
            this.RefreshChildHeader();
        }, 10);
    }

    protected GetSelectedChilds(): IChild[] {
        return this.childList.filter(d => d.__Selected);
    }

    protected AddSaveBtn() {
        this.btnSave = this.footerContainer.append("button")
            .attr("class", "button-circle btn_save")
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_done))
                    .style("height", "100%")
                    .style("width", "100%");
            })
        this.btnSave.call(() => this.ToggleEnableSaveBtn(true));
    }

    protected ToggleEnableSaveBtn(enable: boolean) {
        if (!this.btnSave)
            return;
        this.btnSave
            .on("click", enable ? () => this.OnSaveClick() : null)
            .style("pointer-events", enable ? null : "none")
            .style("opacity", enable ? null : 0.6)
    }

    protected EventComunError(result: number, title: string) {
        const evErrors = [-10, -11, -12, -13];
        if (evErrors.includes(result)) {
            ShowToast(title, _HttpMsg("evento/comun", result), "error");
        } else {
            ShowToast(title, _L("general.error") + ", " + _L("general.user_reintent").toLowerCase(), 'error');
        }
    }

    protected OnSaveClick(): void { }

    protected GetDateExtempByChild(child: IChild): Date {
        let dtEvent = new Date();

        if (this.IS_EXTEMPORAL && child.Salidas && child.Salidas.length > 0) {
            const lastDt = child.Salidas[child.Salidas.length - 1];
            if (lastDt && child.Salidas.length == child.Entradas.length)//Evualuar el ultimo, podria estar dentro
                dtEvent = new Date(lastDt);
        }

        return dtEvent;
    }

    protected AddCommonGralEvent(opts: ICommonAddGralEvent) {
        const ids = this.childList.map(o => o.IdChild);
        const dtEvnt = new Date();
        const idComentario = _GetIdComment(opts.evtID, opts.comment.trim());

        LOADING.Show();

        _AddGeneralEvent(ids, dtEvnt, dtEvnt, opts.evtID, opts.comment, opts.identifier, this.IS_EXTEMPORAL, idComentario, (result) => {
            const title = opts.titleToast;

            if (result == 1) {
                ShowToast(title, _L("event.record_success", title.toLowerCase()), 'success');
                _HistoryBack();
            } else
                this.EventComunError(result, title);

            LOADING.Dismiss();
        }, opts.eventName, opts.category);
    }

    private SetListChildEvt_Dates(listData: IChildEv[]) {
        const dtsEnter: string[] = [];
        const dtsDeparture: string[] = [];

        this.childList = listData.map(d => {
            const dd: IChildEv = d as any;
            dd.__Selected = true;

            if (d.Entradas && d.Entradas.length > 0)
                dtsEnter.push(max(d.Entradas));

            if (d.Salidas && d.Salidas.length > 0)
                dtsDeparture.push(min(d.Salidas));

            return dd;
        });

        if (dtsEnter.length > 0)
            this.CHILD_MIN_DATE = max(dtsEnter);
        if (dtsDeparture.length > 0)
            this.CHILD_MAX_DATE = min(dtsDeparture);
    }

    public OnDestroy(): void {
        super.OnDestroy();

        this.IS_DESTROYED = true;
    }

}