/**
 * En base a imagen original valor de @param orientation:
 * * 1 = rotar 0°;
 * * 2 = espejo de 1;
 * * 8 = rotar 90°;
 * * 7 = espejo de 8;
 * * 3 = rotar 180°;
 * * 4 = espejo de 9;
 * * 6 = rotar 270°;
 * * 5 = espejo de 6;
 * */
export enum EImageOrientation {
    ROTAR = 1,
    ESPEJO_1 = 2,
    ROTAR_180 = 3,
    ESPEJO_9 = 4,
    ESPEJO_6 = 5,
    ROTAR_270 = 6,
    ESPEJO_8 = 7,
    ROTAR_90 = 8
}

export function _SetImageOrientation(url: string, orientation: EImageOrientation, callback: (b64: string) => void) {
    let aux_image = new Image();
    aux_image.crossOrigin = "Anonymous";
    aux_image.onload = () => {

        let width = aux_image.width;
        let height = aux_image.height;
        let cv_img = document.createElement('canvas');
        let ctx = cv_img.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (EImageOrientation.ESPEJO_9 < orientation && orientation <= EImageOrientation.ROTAR_90) {
            cv_img.width = height;
            cv_img.height = width;
        } else {
            cv_img.width = width;
            cv_img.height = height;
        }

        // transform context before drawing image
        switch (orientation) {
            case EImageOrientation.ESPEJO_1: ctx?.transform(-1, 0, 0, 1, width, 0); break;
            case EImageOrientation.ROTAR_180: ctx?.transform(-1, 0, 0, -1, width, height); break;
            case EImageOrientation.ESPEJO_9: ctx?.transform(1, 0, 0, -1, 0, height); break;
            case EImageOrientation.ESPEJO_6: ctx?.transform(0, 1, 1, 0, 0, 0); break;
            case EImageOrientation.ROTAR_270: ctx?.transform(0, 1, -1, 0, height, 0); break;
            case EImageOrientation.ESPEJO_8: ctx?.transform(0, -1, -1, 0, height, width); break;
            case EImageOrientation.ROTAR_90: ctx?.transform(0, -1, 1, 0, 0, width); break;
            default: break;
        }

        ctx?.drawImage(aux_image, 0, 0, width, height);
        callback(cv_img.toDataURL());
    }

    aux_image.src = url;
}