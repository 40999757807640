import { Selection, select } from "d3";
import { _LoadConfig } from "../../data/services/Configuration";
import { ShowToast } from "../components/Toast";
import { LOADING } from "../components/Loading";
import { _SaveUserData } from "../../utils/Device";
import { _SetInfoUser } from "../../data/services/UserInfo";
import { _LoadComments } from "../../data/services/Event";
import _Label, { _HttpMsg } from "../../utils/Labels";

export class Base {

    protected mainContainer: Selection<HTMLDivElement, any, any, any>;

    constructor(
        className?: string, removeAll: boolean = false
    ) {
        className = className || "";
        const MAIN_CONT = select<HTMLBodyElement, undefined>("body")//.select("#MAIN")
        if (removeAll) {
            MAIN_CONT.selectAll(":scope>*:not(.notifications)").remove(); // FIXME cada ventana debería remover sus propios elementos
            this.mainContainer = MAIN_CONT.append("div");//Seleccionar todos los elementos y eliminarlos
        } else {
            this.mainContainer = MAIN_CONT.select("." + className.split(' ')[0]);
            if (this.mainContainer.size() == 0) {
                MAIN_CONT.selectAll(":scope>*:not(.notifications)").remove(); // FIXME cada ventana debería remover sus propios elementos
                this.mainContainer = MAIN_CONT.append("div");
            }
        }

        this.mainContainer.attr("class", className);

        this.InitLoadData();

    }

    public OnInit(): void {

    }

    protected EvaluateConfig(idNivel: number, callback: (valid: boolean) => void) {
        _LoadConfig(idNivel, (status, result) => {
            let valid: boolean = true;

            if (status < 1 || result.length == 0) {
                ShowToast(_Label("general.config"), _HttpMsg("escolaridad/ObtenerConfiguracion", status < 1 ? status : -60));
                LOADING.Dismiss();

                valid = false;
            }

            if (callback) callback(valid);
        }, true);
    }

    private InitLoadData() {
        _LoadComments();
    }

    public OnDestroy(): void { }

}